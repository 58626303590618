/** @format */

const documentObjectBuy = {
  title: 'Договор на покупку объекта',
  routeName: 'objectBuy_view',
  accessName: 'objectBuy',
  accDocName: 'AccountingDocBuildingBuyModel',
  operations: { 1: [1, 2], 2: [10] },
  operationsCash: { 16: [17], 34: [37, 42], 36: [17] },
  api: '/accounting/doc/building_buy',

  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', dirFilter: { id: [16, 34, 36] }, style: 'white-space: normal;', width: 160 },
    { name: 'object_id', title: 'Назначение', type: 'select', dir: 'object', sortable: true, width: 150, style: 'white-space: normal;' },
    { name: 'seller_id', title: 'Поставщик', type: 'select', dir: 'vendor', width: 130 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', filter: { type: 'select', name: 'createdby_id', dir: 'user' }, sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 100 },
    { name: 'value', title: 'Сумма общая', type: 'number', sortable: true, width: 80 },
    // { name: 'balance', title: 'Долг', type: 'balance_', account: '60.3', value: 1, sortable: false, width: 80 },
    { name: 'balance', title: 'Долг', type: 'balanceApi', account: '60.3', subconto2: '(d)=>{return d.id || -1}', sortable: false, width: 80 },
    { name: 'investor_id', title: 'Инвестор', type: 'select', dir: 'investor', hidden: true },
  ],

  form: [
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', hiddenCreate: true, card: 1 },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', dirFilter: { id: [16, 36] }, validator: ['req'], card: 1 },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', validator: ['req'], card: 1, size: 6, autocomplete: true },
    { name: 'seller_id', title: 'Поставщик', type: 'select', dir: 'vendor', validator: ['req'], card: 1, size: 6, autocomplete: true },
    {
      name: 'investor_id',
      title: 'Инвестор',
      type: 'selectapi',
      api: 'mechti/investors/select',
      validator: ['reqF'],
      validateFunc: '(d)=>{return !(d?.first_payment_type === 0 && !d?.investor_id)}',
      card: 1,
    },
    { name: 'credit_org_id', title: 'Кредитная организация', type: 'selectapi', api: 'accounting/dir/credit_org/select', validator: ['req'], card: -1 },
    { name: 'value', title: 'Стоимость покупки (общая)', type: 'number', validator: ['req'], card: 2 },
    { name: 'value_first', title: 'Размер первого взноса', type: 'number', validator: ['req'], showNull: true, card: 2 },
    { name: 'comments', title: 'Примечание', type: 'text', hiddenCreate: true, card: 2 },
    { name: 'doc_all', title: 'Документы', type: 'filesdata', hiddenCreate: true },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
    {
      name: 'first_payment_type',
      title: 'Оплата первого взноса',
      type: 'select',
      options: [
        { value: 0, text: 'Инвестор' },
        { value: 1, text: 'Компания' },
      ],
      validator: ['req'],
      card: 2,
      size: 6,
      default: 0,
      showNull: true,
    },
  ],
  config: {
    default: {
      fields: 'operation_type',
      fieldsRO: '',
      fieldsForm: 'operation_type',
    },
    34: {
      note: 'Покупка недвижимости в кредит - old',
      fields: 'date_doc,operation_type,object_id,seller_id,investor_id,credit_org_id,value,comments,doc_all,status,filesRemove,filesAdd,value_first,first_payment_type',
      fieldsRO: '',
      fieldsForm: 'date_doc,operation_type,object_id#6,seller_id#6,investor_id,credit_org_id,value#6,value_first#6,first_payment_type,comments,doc_all',
    },
    36: {
      note: 'Покупка недвижимости в кредит ',
      fields: 'date_doc,operation_type,object_id,seller_id,value,comments,doc_all,status,filesRemove,filesAdd,value_first,first_payment_type,investor_id',
      fieldsRO: '',
      fieldsForm: 'date_doc,operation_type,object_id#6,seller_id#6,value#6,value_first#6,first_payment_type#6,investor_id#6,comments,doc_all',
    },
    16: {
      note: 'Покупка недвижимости',
      fields: 'date_doc,operation_type,object_id,seller_id,value,comments,doc_all,status,filesRemove,filesAdd,first_payment_type',
      fieldsRO: '',
      fieldsForm: 'date_doc,operation_type,object_id#6,seller_id#6,value,comments,doc_all',
    },
  },
};
export default documentObjectBuy;
