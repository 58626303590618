<template>
  <static-fullscreen-card @sendHeight="setContentHeight">
    <template v-slot:title> ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}] </template>
    <template v-slot:actions>
      <v-tooltip bottom v-if="hintStatus">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
        </template>
        <span v-html="hintStatus"></span>
      </v-tooltip>
      <a-btn-status
        v-if="!showOnly && getAccess('documents.expenses.status', { users: [data.createdby_id] })"
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled="!canStatus"
      />
      <a-btn-edit v-if="!showOnly && accessEdit" @click="showHeadEdit = true" :disabled="canEdit" />
      <a-btn-delete
        v-if="
          !showOnly &&
          id &&
          data.status !== 2 &&
          !data.data_table[1].length &&
          !data.data_table[0].length &&
          getAccess('documents.expenses.delete', { users: [data.createdby_id] })
        "
        @click="removeDialogShow = true"
      />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <v-row style="height: 100%">
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true }" @click="onClick($event)" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view
            :value="Object.assign({}, { total_expenses, object_expenses }, data)"
            :model="[
              ...modelRight,
              { name: 'total_expenses', title: 'Расходов для распределения', type: 'number', color: 'red' },
              { name: 'object_expenses', title: 'Расходов распределено', type: 'number' },
            ]"
            :config="{ dense: true }"
            :style1="{ height: blockHeight + 'px' }"
          />
          <a-btn-add
            v-if="
              data.status !== 2 &&
              getAccess('documents.expenses.edit', {
                users: [data.createdby_id],
              })
            "
            @click="setExpenses()"
            :title="'Распределить'"
            :disabled="total_expenses == 0"
            :icon="'fas fa-layer-group'"
          />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2" style="flex: 1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item>
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableObjects"
                  :dataTable="data.data_table[0]"
                  :useQuery="false"
                  :model="modelObjects"
                  :searchable="false"
                  :footerHide="true"
                  :defaults="{
                    filters: {},
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'documentExpensesObject',
                  }"
                  @click="onClickRow($event)"
                >
                  <template v-slot:item.actions="{ row }">
                    <div class="d-flex" v-if="accessEdit">
                      <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delRow('object', row.id)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-slot:top>
                    <v-fab-transition v-if="accessEdit">
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="onShowList('object')">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-data2>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableExpenses"
                  :dataTable="data.data_table[1]"
                  :useQuery="false"
                  :model="modelExpenses"
                  :searchable="false"
                  :footerHide="true"
                  :defaults="{
                    filters: {},
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'documentExpensesDetail',
                  }"
                  @click="onClickRow($event, 'expenses')"
                >
                  <template v-slot:item.actions="{ row }">
                    <div class="d-flex" v-if="accessEdit">
                      <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delRow('expenses', row.id)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-slot:top>
                    <v-fab-transition v-if="accessEdit">
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="onShowList('expenses')">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-data2>
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <comment-view :id="id || 0" :targetName="'expensesDistribution'" :needUpdate="needUpdateComments" />
            </v-tab-item>
            <v-tab-item>
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <s-document-acc-record :id="id" :name="accDocName" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <a-list-custom ref="list" @save="onSelectFromList($event)" />
      <s-document-head v-model="showHeadEdit" v-if="showHeadEdit" :api="url" :m="m" :config="m.config.default" :title="m.title" :id="id" />
      <s-quickOpenDoc ref="quickOpenDoc" />

      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <change-value-dialog ref="changeValueDialog" />
    <a-loader v-if="loading" />

    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </static-fullscreen-card>
</template>

<script>
//import { statusTask } from "@/config/modules/libs";

import { autoHeightBlock, getAccess, popupMenu, removeEl, saveEl, genModel } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, saveEl, genModel],
  components: {},
  props: {
    idShow: Number,
    showOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      fieldsRO: [],

      accDocName: "AccountingDocAdministrativeExpenses",
      needUpdateComments: 0,
      suEditShow: false,
      suEditConfig: null,
      showHeadEdit: false,
      showList: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      loading: false,
      loaded: false,
      title: "",
      data: { data_table: [[], []] },
      modelObjects: this.$store.getters["config/get"].models.documentExpenses.listObjects,
      modelExpenses: this.$store.getters["config/get"].models.documentExpenses.listExpenses,
      m: this.$store.getters["config/get"].models.documentExpenses,
      statuses: this.$store.getters["config/get"].models.documentExpenses.statuses,
      url: "/accounting/doc/administrative_expenses",
      api: "/accounting/doc/administrative_expenses",
      apiStatus: "/accounting/doc/administrative_expenses",
      tab: 0,
      tabs: [
        { title: "Объекты", show: true },
        { title: "Расходы", show: true },
        { title: "Комментарии", show: true },
        {
          title: "Проводки",
          show: this.getAccess("documents.expenses.accounting"),
        },
      ],
      blockHeight: 256,
      debit: null,
      credit: null,
      statusCur: null,
      total_expenses: 0,
      object_expenses: 0,
      hintStatus: null,
    };
  },
  created() {
    this.loading = true;
    this.$root.title = "Распределение расходов периода";
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
    canStatus() {
      this.hintStatus = "";

      if (!(this.data.data_table && this.data.data_table[0].length)) this.hintStatus += "Не заполнены объекты<br/>";
      if (!(this.data.data_table && this.data.data_table[1].length)) this.hintStatus += "Не заполнены расходы<br/>";
      if (this.total_expenses.toFixed(2) !== this.object_expenses.toFixed(2) && this.data.status == 1) {
        console.log(this.total_expenses, this.object_expenses, this.data.status);
        this.hintStatus += "Не совпадают распределенные расходы<br/>";
      }
      return !this.hintStatus;

      if (
        (this.data.status == 1 && this.data.data_table && (!this.data.data_table[0].length || !this.data.data_table[1].length)) ||
        (this.total_expenses != this.object_expenses && this.data.status == 1)
      ) {
        return false;
      }
      return true;
      return !(this.data.data_table && this.data.data_table.length == 0) && !this.loading;
    },
    canEdit() {
      if (!this.data.vendor_id) {
        return false;
      }

      return this.loading || this.data.data_table.length !== 0 || false;
    },
    accessEdit() {
      let res =
        this.data.status !== 2 &&
        this.getAccess("documents.expenses.edit", {
          users: [this.data.createdby_id],
        });
      return res;
    },

    model: {
      get() {
        let type = this.data.type || 1;
        //    let model = JSON.parse(JSON.stringify(this.m.form));
        let model = this.getModelList(this.m, "form", true);

        this.modelReady = true;

        return model;
      },
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter(s => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find(el => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  watch: {
    showHeadEdit(v) {
      if (!v) {
        this.fitchData();
      }
    },
    "data.data_table"(v) {
      console.log("watch data_table[1]");
      let e = 0;
      let o = 0;
      v[1].forEach(el => {
        e += el.amount;
      });
      v[0].forEach(el => {
        o += el.amount;
      });
      this.total_expenses = e;
      this.object_expenses = o;
    },
  },
  methods: {
    async setExpenses() {
      let d = [...this.data.data_table[0]];
      let count = d.length;
      console.log("set expenses", count);
      if (!count) return;
      let expenses = this.total_expenses;
      d.forEach((o, idx, array) => {
        let val = (this.total_expenses / count).toFixed(2);
        if (idx == count - 1) val = expenses.toFixed(4);
        console.log(val);
        o.amount = val;
        expenses -= val;
      });
      let data_table = [];
      data_table[0] = d;
      let response = await this.save(this.api, { id: this.id, data_table });
      if (response && response.data.data) {
        this.data.data_table = response.data.data.data_table;
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Статус не изменен",
        });
      }
    },
    onShowList(type) {
      if (type == "object") {
        let list = this.data.data_table[0].map(o => {
          return o.object_id;
        });
        console.log("list", list);
        let params = {
          type,
          title: "Выбор объектов для распределения",
          api: "/mechti/objects",
          multiple: true,
          model: [
            { name: "id", title: "#", type: "id", sortable: true, width: 50 },
            {
              name: "is_investor",
              title: "Тип участия",
              type: "select",
              options: [
                { value: 1, text: "Инвестор" },
                { value: 0, text: "Клиент" },
              ],
              width: 90,
            },
            { name: "name", title: "Название", type: "string", style: "white-space: normal;", sortable: true, width: 200 },
            { name: "city", title: "Город", type: "string", filter: { name: "city", type: "select", options: "city" }, sortable: true, width: 150 },
            { name: "address", title: "Адрес", type: "string", style: "white-space: normal;", class: "text-max-line-2", sortable: true, width: 300 },
            { name: "status", title: "Статус", type: "select", options: "status_object", sortable: true, width: 100 },
            {
              name: "object_type",
              title: "Работы на объекте",
              type: "selectbit",
              options: [
                { value: 1, text: "Дизайн" },
                { value: 2, text: "Ремонт" },
                { value: 3, text: "Мебель" },
              ],
              width: 220,
            },
          ],
          //filters: { status: [10, 90], is_investor: 1, city: this.data.city || "" },
          filters: { status: [10, 90], object_type: { condition: `&${2}=`, value: 2 }, city: this.data.city || "" },

          where: list.length ? { id: { condition: "not in", value: list } } : {},
        };
        //, id: { condition: "in", value: [-1, ...list] }
        this.$refs.list.init(params);
        return;
      }
      if (type == "expenses") {
        let list = this.data.data_table[1]
          .map(o => {
            return o.account_id;
          })
          .join(",");
        let sql = {
          sql: `
select * from (
SELECT id, credit_account 'account',
  date_doc 'date',
  getDocName(base_name,base_id) as 'namedoc',
  operation_id,
	getSubcontoValue(debit_account, 'subconto1', debit_subconto_value_1) 'name1',
	getSubcontoValue(debit_account, 'subconto2', debit_subconto_value_2) 'name2',
	getSubcontoValue(debit_account, 'subconto3', debit_subconto_value_3) 'name3',
	value,debit_account,debit_subconto_value_1,debit_subconto_value_2,debit_subconto_value_3,base_name,base_id

from accounting_record
WHERE debit_account LIKE '26.%' and id not in (${list || 0})
AND date_doc BETWEEN  '${this.data.period_start}' AND DATE_ADD('${this.data.period_end}' ,INTERVAL 1 DAY)
) as tab`,
          order: "date asc",
        };
        let params = {
          type,
          title: "Выбор расходов для распределения",
          multiple: true,
          sql,
          model: [
            { name: "id", title: "id", type: "number", width: 30, sortable: true, hidden: true },
            { name: "account", title: "счет", type: "string", width: 50, sortable: true, hidden: true },
            { name: "date", title: "Дата", type: "datetime", width: 120, sortable: true },
            { name: "namedoc", title: "Документ", type: "string", width: 200, sortable: true, style: "white-space: normal;" },
            { name: "value", title: "Сумма", type: "number", width: 70, sortable: true },
            { name: "name1", title: "", type: "string", width: 200, sortable: true },
            { name: "name2", title: "", type: "string", width: 200, sortable: true },
            { name: "name3", title: "", type: "string", width: 200, sortable: true },
          ],
        };
        this.$refs.list.init(params);
      }
    },
    async onSelectFromList({ type, rows }) {
      if (!type) return;
      let data_table = [];
      if (type == "object") {
        let data = [];
        rows.forEach(r => {
          data.push({
            object_id: r.id,
          });
        });
        data_table[0] = [...this.data.data_table[0], ...data];
      }
      if (type == "expenses") {
        let data = [];
        rows.forEach(r => {
          data.push({
            account_id: r.id,
            base_name: r.base_name,
            base_id: r.base_id,
            account: r.debit_account,
            subconto_value_1: r.debit_subconto_value_1,
            subconto_value_2: r.debit_subconto_value_2,
            subconto_value_3: r.debit_subconto_value_3,
            amount: r.value,
          });
        });
        data_table[1] = [...this.data.data_table[1], ...data];
      }
      if (data_table.length) {
        const data = { id: this.id, data_table };
        let response = await this.$axios.post(this.api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные сохранены",
          });
          this.data.data_table = response.data.data.data_table;
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка",
          });
        }
      }
    },
    onClick(d) {
      if (d.field?.isMenu) {
        if (d.field.name == "object_code") {
          let name = d.row.type == 1 ? "object" : d.row.type == 2 ? "office" : "storage";

          this.showPopupMenu(name, { id: d.row.object_code }, d.event);
        } else this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },

    onClickRow(e, type = "") {
      console.log("onClickRow", e);
      if (e.field?.isMenu) {
        if (["base_doc_name", "name_doc"].includes(e.field.name)) {
          console.log("onClickRow111");

          let menuItems = [
            {
              name: e.field.name,
              title: "Открыть документ",
              action: async () => {
                this.$refs.quickOpenDoc.openDocument(e.row.base_name, e.row.base_id, e.row);
              },
            },
          ];
          this.showPopupMenu(e.field.name, e.row, e.event, { menuItems });
        } else this.showPopupMenu(name, e.row, e.event, { task: { id: e.row[e.field.name] } });
      } else if (e.field.name == "actions") {
      } else {
        this.idEdit = e.row.id;
        if (type == "service") {
          this.showDialogServiceRowEdit = true;
          return;
        }
        this.fieldsRO =
          this.data.operation_type == 1 //по чек-листу
            ? ["name", "amount", "measure", "price_plan"]
            : [];
        this.showDialogRowEdit = true;
      }
    },

    async delRow(type, id) {
      const post = async (api, data) => {
        let response = await this.$axios.post(api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные удалены",
          });
          this.data.data_table = response.data.data.data_table;
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка удаления ",
          });
        }
      };
      if (type == "object") {
        const api = this.url;
        const data_table = [];
        data_table[0] = this.data.data_table[0].filter(el => el.id !== id);
        const data = { id: this.id, data_table };
        await post(api, data);
      }
      if (type == "expenses") {
        const api = this.url;
        const data_table = [];
        data_table[1] = this.data.data_table[1].filter(el => el.id !== id);
        const data = { id: this.id, data_table };
        await post(api, data);
      }
    },

    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close", data);
      else this.$router.push({ name: "expensesDistribution" });
    },
    afterFetchData(r) {
      if (this.id) {
      }
      this.loaded = true;
    },

    async changeStatus(status) {
      let ok = false;
      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите ${status.value !== 2 ? "распровести" : "провести"} заказ?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      if (status.value !== 2) {
        let resp = await this.$axios.post("accounting/records/report-sql", {
          q: `select id from accounting_doc_order_withdrawal where parent_id=${this.id} and parent_name="${this.accDocName}"`,
        });
        if (resp.data.status == "ok") {
          if (resp.data.data.length > 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Не возможно сменить статус. Есть связанный документ оплаты",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не возможно сменить статус. Произошла ошибка проверки",
          });
          return;
        }
        resp = await this.$axios.post("accounting/records/report-sql", {
          q: `
select ogt.id from accounting_doc_order_goods_table ogt
left join accounting_doc_goods_income_table git on git.accounting_doc_order_goods_table_id = ogt.id
where ogt.parent_id=${this.id} and git.id is not null`,
        });
        if (resp.data.status == "ok") {
          if (resp.data.data.length > 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Не возможно сменить статус. Есть связанный документ приемки",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не возможно сменить статус. Произошла ошибка проверки",
          });
          return;
        }
      }
      this.loading = true;
      let data = { id: this.id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
  },
};
</script>
