<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? title : "Новый элемент '" + title + "'" }}
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, readonly }"
              @validate="
                doWatch($event);
                validate($event);
              "
              @fieldData="setFieldData($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save :disabled="readonly" @click="_submit()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    dataTable: Array,
    value: Boolean,
    id: Number,
    m: Object,
    configForm: { type: Object, default: () => {} },
    opt: { type: String, default: "" },
  },
  data() {
    return {
      model: [],
      loaded: false,
      fieldData: {},
      row: {},
      rows: [],
    };
  },
  computed: {
    config() {
      if (this.configForm?.head) return this.configForm.head;
      const type = this.configForm?.type || "default";
      return JSON.parse(JSON.stringify(this.m?.config?.[type] || {}));
    },

    title() {
      return this.configForm?.title || this.m?.title || "";
    },

    editObject___del() {
      return false;
    },
    canDelete() {
      return this.opt.includes("delete");
    },
    readonly() {
      return this.opt.includes("readonly");
    },
    removeTitle() {
      return this.m?.removeTitle || "Вы действительно хотите удалить это?";
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
  },
  watch: {
    id(v) {
      if (v || v === 0) this.row = this.dataTable.find((el) => el.id == v);
    },
    value() {
      if (this.value) {
        if (this.id) this.row = this.dataTable.find((el) => el.id == this.id);
      }
    },
  },
  methods: {
    afterFetchData() {
      let t = 0;
      if (this.m?.config?.watch) {
        t = this.data?.[this.m.config.watch] || 0;
      }
      this.getModel(t);
      this.loading = false;
      this.loaded = true;
    },
    resetForm() {},
    async fetchData() {
      this.loading = true;
      this.rows = JSON.parse(JSON.stringify(this.dataTable || []));
      if (this.id || this.id === 0) {
        this.row = this.rows[this.id];
        for (const name in this.row) {
          this.$set(this.data, name, this.row[name]);
        }
      }
      this.afterFetchData();
    },
    beforeSave() {
      //if (this.data.status === null) this.data.status = 0;
    },
    post() {
      let rows = [];

      this.rows.forEach((r, i) => {
        if (i === this.id) {
          rows.push(this.data);
        } else {
          rows.push(r);
        }
      });
      if (!(this.data?.["_id"] >= 0)) {
        rows.push(this.data);
      }
      console.log("save", rows);
      this.$emit("save", rows);

      this.$emit("input");
    },

    _submit() {
      if (this.readonly) return;
      this.submit();
    },
    doWatch(e) {
      //console.log({ e, wathc: this.m?.config?.watch, value: this.data?.[e] });
      if (e == this.m?.config?.watch) this.getModel(this.data?.[e]);
    },
    getModel(t) {
      //console.log("get model", t, this.m?.config[t]);
      let config = Object.assign({}, this.config);
      if (t || t === 0) config = JSON.parse(JSON.stringify(this.m?.config?.[t] || this.config));
      this.watchModel = config?.watch || null;
      let model = this.calcModelConfig(config);
      if (true)
        model.forEach((el) => {
          if (el.hiddenCreate && !this.data?.id) {
            el.hidden = true;
          }
        });
      this.fillFormFromModel(model);
      this.model = model;
    },
  },
};
</script>
