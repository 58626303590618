/** @format */

const works = {
  tableParamName: 'objectsWork',

  department: [
    { value: 'Дизайнер', text: 'Дизайнер' },
    { value: 'Стройка', text: 'Стройка' },
    { value: 'Снабжение', text: 'Снабжение' },
    { value: 'Мебель', text: 'Мебель' },
    { value: 'Комплектация', text: 'Комплектация' },
  ],
  list: [
    { name: 'checked', type: 'checkbox', width: 35, isDisableFunc: `((r) => {return r?.status == -1;})` },
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'department', title: 'Отдел', type: 'select', sourceList: 'department', sortable: true, width: 100 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 250, style: 'white-space: normal;' },
    { name: 'work_days', title: 'Дней', type: 'number', sortable: true, width: 80, style: 'text-align: center;' },
    { name: 'work_value', title: 'Вес', type: 'number', sortable: true, width: 80, style: 'text-align: center;' },
    { name: 'work_cost', title: 'Стоимость', type: 'number', sortable: true, width: 100 },
    { name: 'date_start', title: 'Дата начала', type: 'date', sortable: true, width: 150 },
    { name: 'date_end', title: 'Дата конца', type: 'date', sortable: true, width: 150 },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { type: 'select', name: 'createdby_id', dir: 'user' },
      style: 'white-space: normal;',
      sortable: true,
      width: 120,
    },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_work', sortable: true, width: 150 },
    { name: 'actions', title: 'Действия', width: 40 },
  ],
  list_role10: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'department', title: 'Отдел', type: 'select', sourceList: 'department', sortable: true, width: 100 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 250, style: 'white-space: normal;' },
    { name: 'work_days', title: 'Дней', type: 'number', sortable: true, width: 80, style: 'text-align: center;' },
    { name: 'work_value', title: 'Вес', type: 'number', sortable: true, width: 80, style: 'text-align: center;' },
    { name: 'date_start', title: 'Дата начала', type: 'date', sortable: true, width: 150 },
    { name: 'date_end', title: 'Дата конца', type: 'date', sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_work', sortable: true, width: 150 },
  ],
  form: [
    { name: 'department', title: 'Отдел', type: 'select', sourceList: 'department', validator: ['req'] },
    { name: 'service_id', title: 'Наименование', type: 'selectwork', validator: ['req'] },
    { name: 'work_days', title: 'Дней', type: 'number', validator: ['req'] },
    { name: 'work_value', title: '"Вес" работы ', type: 'number', validator: ['req'] },
    { name: 'work_cost', title: 'Стоимость', type: 'number', validator: ['req'] },
    { name: 'date_start', type: 'datestring', title: 'Дата начала', hiddenType1: 'date', validator: ['req'], size: 6 },
    { name: 'date_end', type: 'datestring', title: 'Дата конца', hiddenType1: 'date', validator: ['req'], size: 6 },
    { name: 'status', title: 'Статус', default: -1 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', hiddenCreate: true },
    { name: 'data.description', title: 'Описаник к работе', type: 'text', opts: 'auto-grow' },
  ],
  config: {
    default: {
      fields: 'department,service_id,name,work_days,work_value,work_cost,date_start,date_end,status,createdby_id,data.description',
      fieldsRO: 'createdby_id',
      fieldsForm: 'createdby_id,department,service_id,work_days#4,work_value#4,work_cost#4,date_start#6,date_end#6,data.description',
    },
  },
};

export default works;
