/** @format */

const cashIn = {
  title: 'Приходный кассовый ордер',
  accessName: 'cashIn',
  api: '/accounting/doc/order_receipt',
  apiStatus: '/accounting/doc/order_receipt',
  accDocName: 'AccountingDocOrderReceiptModel',
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', style: 'white-space: normal;', sortable: true },
    { name: 'value', title: 'Сумма', type: 'number', sortable: true },
    { name: 'cashbox_id', title: 'Касса назначения', type: 'select', dir: 'cashbox' },
    { name: 'employee_id', title: 'Подотчет', type: 'select', dir: 'employee' },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { name: 'createdby_id', type: 'selectapi', api: '/manager/users/select' },
      sortable: true,
      width: 150,
    },
    {
      filter: { type: 'selectapi', name: 'object_id', api: '/mechti/objects/select' },
      name: 'object_id',
      dir: 'object',
      sortable: true,
      title: 'Объект',
      type: 'select',
      style: 'white-space: normal;',
      width: 150,
      isMenu: true,
    },
    { name: 'count_id', title: 'Показано', hidden: true },
    { name: 'sum_value', title: 'Сумма', hidden: true },
  ],

  form: [
    { name: 'date_doc', title: 'Дата документа', type: 'datetime' },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', validator: ['req'] },
    { name: 'operation_name', title: 'Основание', type: 'string' },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', validator: ['req'] },
    { name: 'cashbox_id1', title: 'Касса', type: 'select', dir: 'cashbox', validator: ['req'] },
    { name: 'cashbox_id', title: 'Касса', type: 'selectCashBox', dir: 'cashbox', validator: ['req'] },
    { name: 'investor_id', title: 'Инвестор', type: 'selectapi', api: 'mechti/investors/select', validator: ['req'] },
    { name: 'client_id', title: 'Клиент', type: 'selectapi', api: 'mechti/clients/select', validator: ['req'] },
    { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', validator: ['req'] },
    { name: 'employee_id', title: 'Сотрудник', type: 'select', dir: 'employee', validator: ['req'] },
    { name: 'service_id', title: 'Услуга', type: 'select', dir: 'service', validator: ['req'] },
    { name: 'value', title: 'Сумма', type: 'number', validator: ['req'] },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'files1', title: 'Файлы', type: 'files' },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
    { name: 'parent_name', title: 'parent_name', type: 'string' },
    {
      name: 'parent_id',
      title: 'Документ для оплаты',
      type: 'selectapi',
      itemField: { value: 'id', text: 'code_doc' },
      apiVar: {
        key: 'parent_name',
        apiName: [{ value: 'AccountingDocBuildingSellModel', name: 'accounting/doc/building_sell', preText: 'Договор продажи №' }],
      },
    },
    { name: 'files', title: 'Файлы', type: 'filesdata', category: 1 },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
  ],
  config: [
    {
      id: -1,
      template: 'cashRaw',
      fields: 'operation_type,cashbox_id,employee_id,object_id,investor_id,value,status,files,filesAdd,filesRemove',
    },
    {
      id: 8,
      note: 'Возврат подотчетных средств',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,cashbox_id,employee_id,value,status,files,filesAdd,filesRemove,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,employee_id,cashbox_id#6,value#6,operation_name,files',
    },
    {
      id: 9,
      note: 'Взнос средств инвестора',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,investor_id,object_id,cashbox_id,value,status,files,filesAdd,filesRemove,comments,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,investor_id,object_id,cashbox_id#6,value#6,operation_name,comments,files',
    },
    {
      id: 19,
      note: 'Прием денег по документу продажи',
      template: 'cashCashBox',
      balanceCheck: true,
      fields: 'date_doc,parent_id,operation_type,client_id,object_id,cashbox_id,value,status,files,filesAdd,filesRemove,comments,operation_name',
      fieldsRO: 'operation_type,parent_id,client_id,object_id',
      fieldsForm: 'date_doc,parent_id#6,client_id#6,object_id,cashbox_id+value#6,balance#6,operation_name,comments,files',
      document: {
        field: 'parent_id',
        model: 'documentObjectSell',
        api: '/accounting/doc/building_sell',
        accDocName: 'AccountingDocBuildingSellModel',
        docFields: 'id,createdon,object_id,client_id,createdby_id',
        fieldsConfig: {
          client_id: { type: 'field', value: 'client_id' },
          object_id: { type: 'field', value: 'object_id' },
          parent_name: { type: 'value', value: 'AccountingDocBuildingSellModel' },
        },
      },
    },
    {
      id: 26,
      note: 'Прием средств за аренду',
      template: 'cashParent',
      fieldsReplace: {
        parent_id: {
          name: 'parent_id',
          title: 'Основание для приема',
          type: 'selectapi',
          itemField: { value: 'id', text: 'name' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'RentModel', name: 'mechti/rent', preText: 'Аренда:' }] },
        },
        client_id: { name: 'client_id', title: 'Клиент', type: 'selectapi', api: 'mechti/clients/select', optionsFilter: { client_type: 'bit#5' }, validator: ['req'] },
      },
      fields: 'date_doc,operation_type,parent_id,cashbox_id,client_id,value,status,note,files,filesAdd,filesRemove,operation_name',
      fieldsRO: 'operation_type,parent_id,client_id',
      fieldsForm: 'date_doc,parent_id#6,client_id#6,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'rent',
        api: 'mechti/rent',
        accDocName: 'RentModel',
        docFields: 'id,name,renter_client_id',
        fieldsConfig: {
          object_rent_id: { type: 'field', value: 'id' },
          client_id: { type: 'field', value: 'renter_client_id' },
          value: { type: 'field', value: 'amount_renter' },
        },
      },
    },
    {
      id: 27,
      disabled: true,
      note: 'Прием средств от клиента за объект (ремонт)',
      template: 'cashParent',
      fieldsReplace: {
        parent_id: {
          name: 'parent_id',
          title: 'Основание для приема',
          type: 'selectapi',
          itemField: { value: 'id', text: 'name' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'ObjectModel', name: 'mechti/objects', preText: 'Объект:' }] },
          validator: ['req'],
        },
        client_id: { name: 'client_id', title: 'Клиент', type: 'selectapi', api: 'mechti/clients/select', optionsFilter: { client_type: 'bit#3' }, validator: ['req'] },
        service_id: { name: 'service_id', title: 'Услуга по договору', type: 'select', dir: 'service', dirFilter: { category_id: 44 } },
      },
      fields: 'date_doc,operation_type,parent_id,cashbox_id,client_id,value,status,note,files,filesAdd,filesRemove,service_id,operation_name',
      fieldsRO: 'operation_type,parent_id,client_id',
      fieldsForm: 'date_doc,parent_id#6,client_id#6,service_id,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'objects',
        api: 'mechti/objects',
        filter: { is_investor: 0 },
        accDocName: 'ObjectModel',
        docFields: 'id,name,client_id',
        fieldsConfig: {
          client_id: { type: 'field', value: 'client_id' },
          object_id: { type: 'field', value: 'id' },
        },
      },
    },
    {
      id: 28,
      disabled: true,
      note: 'Прием средств от клиента: дизайн',
      template: 'cashParent',
      fieldsReplace: {
        parent_id: {
          name: 'parent_id',
          title: 'Основание для приема',
          type: 'selectapi',
          itemField: { value: 'id', text: 'name' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'ObjectModel', name: 'mechti/objects', preText: 'Объект (Дизайн):' }] },
          validator: ['req'],
        },
        client_id: { name: 'client_id', title: 'Клиент', type: 'selectapi', api: 'mechti/clients/select', optionsFilter: { client_type: 'bit#2' }, validator: ['req'] },
      },
      fields: 'date_doc,operation_type,parent_id,cashbox_id,client_id,value,status,note,files,filesAdd,filesRemove,operation_name',
      fieldsRO: 'operation_type,parent_id,client_id',
      fieldsForm: 'date_doc,parent_id#6,client_id#6,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'objects',
        api: 'mechti/objects',
        filter: { is_investor: 0, object_type: { condition: `&${1 << (1 - 1)}=`, value: 1 << (1 - 1) } },
        accDocName: 'ObjectModel',
        docFields: 'id,name,client_id',
        fieldsConfig: {
          client_id: { type: 'field', value: 'client_id' },
          object_id: { type: 'field', value: 'id' },
        },
      },
    },
    {
      id: 29,
      disabled: true,
      note: 'Прием средств от клиента: мебель',
      template: 'cashParent',
      fieldsReplace: {
        parent_id: {
          name: 'parent_id',
          title: 'Основание для приема',
          type: 'selectapi',
          itemField: { value: 'id', text: 'name' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'ObjectModel', name: 'mechti/objects', preText: 'Объект (Мебель):' }] },
          validator: ['req'],
        },
        client_id: { name: 'client_id', title: 'Клиент', type: 'selectapi', api: 'mechti/clients/select', optionsFilter: { client_type: 'bit#4' }, validator: ['req'] },
      },
      fields: 'date_doc,operation_type,parent_id,cashbox_id,client_id,value,status,note,files,filesAdd,filesRemove,operation_name',
      fieldsRO: 'operation_type,parent_id,client_id',
      fieldsForm: 'date_doc,parent_id#6,client_id#6,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'objects',
        api: 'mechti/objects',
        filter: { is_investor: 0, object_type: { condition: `&${1 << (3 - 1)}=`, value: 1 << (3 - 1) } },
        accDocName: 'ObjectModel',
        docFields: 'id,name,client_id',
        fieldsConfig: {
          client_id: { type: 'field', value: 'client_id' },
          object_id: { type: 'field', value: 'id' },
        },
      },
    },
    {
      id: 100,
      note: 'Прием средств от клиента за объект',
      template: 'cashParent',
      fieldsReplace: {
        parent_id: {
          name: 'parent_id',
          title: 'Основание для приема',
          type: 'selectapi',
          itemField: { value: 'id', text: 'name' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'ObjectModel', name: 'mechti/objects', preText: 'Объект:' }] },
          validator: ['req'],
        },
        client_id: { name: 'client_id', title: 'Клиент', type: 'selectapi', api: 'mechti/clients/select', validator: ['req'] },
        service_id: { name: 'service_id', title: 'Услуга по договору', type: 'select', dir: 'service', dirFilter: { category_id: 44 } },
      }, 
      fields: 'date_doc,operation_type,parent_id,cashbox_id,client_id,value,status,note,files,filesAdd,filesRemove,service_id,operation_name',
      fieldsRO: 'operation_type,parent_id,client_id',
      fieldsForm: 'date_doc,parent_id#6,client_id#6,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'objects',
        api: 'mechti/objects',
        filter: { is_investor: 0 },
        accDocName: 'ObjectModel',
        docFields: 'id,name,client_id',
        fieldsConfig: {
          client_id: { type: 'field', value: 'client_id' },
          object_id: { type: 'field', value: 'id' },
        },
      },
    },
    {
      id: 32,
      note: 'Взнос средств учредителем',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,cashbox_id,employee_id,value,status,files,filesAdd,filesRemove,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,employee_id,cashbox_id#6,value#6,operation_name,files',
    },
    {
      ee: 'd ',
      id: 56,
      note: 'приход прочее',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,cashbox_id,value,status,files,comment,filesAdd,filesRemoves,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,cashbox_id#6,value#6,operation_name,comments,files',
    },
    {
      id: 57,
      note: 'Оказание брокерских услуг',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,cashbox_id,value,status,files,filesAdd,filesRemoves,comments,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,cashbox_id#6,value#6,operation_name,comments,files',
    },
    {
      id: 70,
      note: 'Возврат средств от поставщика',
      template: 'cashParent',
      balanceCheck: !true,
      fieldsReplace: {
        value: {
          name: 'value',
          title: 'Сумма',
          type: 'number',
          validator: ['req', 'api'],
          validateApi: {
            validator: 'max',
            api: 'report_sql', 
            name: 'getBalance2',
            params: { acc: '60.5', doc_id: '(d)=>{return d.parent_id}', doc_name: '(d)=>{return d.parent_name}' },
            func: '(cur,v)=>{return cur>v ? `Значение не должно быть больше ${v}` :""}',
          },
        },
        balance: {
          name: 'balance',
          title: 'Баланс',
          type: 'balanceView',
          paramSQL: { account: `'60.5'`, doc_name: '(d)=>{return d.parent_name}', doc_id: '(d)=>{return d.parent_id}' },
        },
        balance_vendor: {
          name: 'balanceVendor',
          title: 'Баланс поставщика',
          type: 'balanceApi',
          validator: ['min#0', 'ignoreChange'],
          account: '60.5',
          value: 1,
          subconto1: '((d) => {return d.vendor_id;})',
        },
        parent_title: { name: 'parent_title', title: 'Заказ товаров', type: 'string' },
        parent_id: {
          name: 'parent_id',
          title: 'Заказ товаров',
          type: 'selectapi',
          validator: ['req'],
          api: {
            type: 'post',
            api: '/report_sql',
            data: { name: 'getDocName', params: { name: 'AccountingDocOrderGoodModel', id: '(function(d) {return d.parent_id;})' } },
          },
        },
      },
      fields: 'date_doc,operation_type,parent_title,parent_id,object_id,object_code,vendor_id,value,status,note,files,cashbox_id,balanceVendor,operation_name',
      fieldsStatusDel: { 2: 'balanceVendor' },
      fieldsRO: 'operation_type,parent_title,object_id,vendor_id,object_code',
      fieldsForm: 'date_doc,parent_id,vendor_id#6,balanceVendor#6,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'order_good_id',
        model: 'documentOrderGoods',
        api: '/accounting/doc/order_good',
        accDocName: 'AccountingDocOrderGoodModel',
        docFields: 'code_doc,createdon,type,object_code,vendor_id,createdby_id',
        fieldsConfig: {
          client_id: { type: 'field', value: 'client_id' },
          object_id: { type: 'field', value: 'object_code' },
          vendor_id: { type: 'field', value: 'vendor_id' },
        },
      },
    },
  ],
};
export default cashIn;
