<template>
  <div>
    <dialog-edit2 :value="value || isShow" @input="_close()" :maxWidth="'600px'">
      <template v-slot:title>
        {{ readonly ? "Просмотр" : "Редактирование" }}
      </template>
      <a-loader v-if="!loaded" />
      <a-form-model v-else v-model="data" :model="model_" :errors="errors" :config="{ dense: true, readonly }" @validate="_validate($event)" />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <v-btn @click="_close()">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getAccess, getForm, submitForm, genModel, dataUtils } from "@/components/mixings";
export default {
  mixins: [getForm, submitForm, getAccess, genModel, dataUtils],
  components: {},
  props: {
    value: { type: Boolean, default: false },
    values: { type: Object, default: () => {} },
    model: Array,
  },
  data() {
    return {
      isShow: false,
      loaded: false,
      model_: [],
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  computed: {
    readonly() {
      return false;
    },
  },
  watch: {
    value(v) {
      if (v) this.fetchData(this.values, this.model);
      else {
        console.log("reset value");
        this.resetForm();
      }
    },
    isShow(v) {
      if (v) {
      } else {
        console.log("reset show");
        this.resetForm();
      }
    },
  },
  created() {
    if (this.value) this.fetchData(this.values, this.model);
  },
  methods: {
    resetForm() {
      this.data = {};
      this.errors = {};
      this.validators = {};
    },
    _validate(e) {
      return this.validate(e);
    },
    show(opts = {}) {
      console.log("show", opts); 
      this.fetchData(opts.data, opts.model);
      // Once we set our config, we tell the popup modal to open
      this.isShow = true;
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    getObjectPath__(o, path) {
      const p = path.split(".");
      let tmp;
      for (let i = 0; i < p.length; i++) {
        const el = p[i];
        if (i === 0) {
          tmp = JSON.parse(JSON.stringify(o || {}));
        }
        tmp = tmp?.[el];
      }
      return tmp;
    },

    fetchData(data, model) {
      this.model_ = model;
      let n = model[0].name;
      let f = model[0].editField;
      let d = this.getObjectPath(data, f);
      if (!this.data[n]) this.$set(this.data, n, null);
      this.data[n] = d;
      this.$set(this.errors, n, null);
      this.fillFormValidators([model[0]]);
      this.loaded = true;
    },
    async submit() {
      if (await this.validateAll(this.data)) {
        this.loaded = false;
        let res = { name: this.model_[0].editField, value: this.data[this.model_[0].name] };
        if (this.isShow) {
          this.resolvePromise(res);
          this.isShow = false;
        } else {
          this.$emit("commit", res);
          this.$emit("input");
        }
      }
    },
    _close() {
      if (this.isShow) {
        this.isShow = false;
        this.loaded = false;
        this.resolvePromise(false);
      } else this.$emit("input");
    },
  },
};
</script>
