<template>
  <v-card class="ma-0" :maxHeight="height" :minHeight="minHeight">
    <v-card-title class="secondary white--text py-0"> Задачи по чек-листу ({{ data.length }}) </v-card-title>
    <v-divider />
    <v-card-text class="mt-0 pa-1 pt-0">
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <div v-if="data.length > 0">
        <v-simple-table fixed-header dense :height="height - 36" ref="tableGoods">
          <template v-slot:default>
            <thead class="main-table-header">
              <tr>
                <th class="text-left px-1"></th>
                <th class="text-left px-1">Заголовок</th>
                <th class="text-left px-1">Объект</th>
                <th class="text-left px-1">Дэдлайн</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(el, i) in dataSort.slice(0, itemsPerPage)" :key="i" @click="$emit('click', el)" style="cursor: pointer">
                <td class="text-left px-1">
                  <a-view-table-chip
                    :value="el.is_important"
                    :model="{
                      hideText: true,
                      showText: el.id,
                      options: $store.getters['config/get'].models.tasks.optionsIsImportant,
                    }"
                  />
                </td>
                <td class="text-left px-1">
                  <v-chip x-small v-if="el.status == 200" color="error" class="px-1"> new </v-chip>
                  <a-view-table-taskname :value="el.name" :values="el" />
                </td>
                <td class="text-left px-1">
                  <a-view-table-string :value="el.object_name" />
                </td>

                <td class="text-left px-1">
                  <a-view-table-date :value="el.date_end" />
                </td>
              </tr>
            </tbody> </template
        ></v-simple-table>
      </div>
      <div v-else>Список пуст</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    height: { type: Number, default: 0 },
    rows: Array,
  },
  data() {
    return {
      itemsPerPage: 50,
      showTaskDialog: false,
      contentHeight: 10,
      idEdit: 0,
      loading: false,
      data: [],
    };
  },
  created() {
    this.fitchData();
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, true);
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll, true);
  },
  computed: {
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
    dataSort() {
      return this.data.sort((a, b) => {
        return (b.status == 200) - (a.status == 200) || (a.date_start > b.date_start) - (a.date_start < b.date_start);
      });
    },
    filters() {
      const f = this.$store.getters["config/get"].config.taskToday;
      let res = Object.assign(f.goods.filters, {
        date_end: {
          condition: ">=",
          value: new Date().date,
        },
      });
      return res;
    },
  },
  methods: {
    onScroll(e) {
      let el = this.$refs.tableGoods;
      let offset = 200;
      //  console.log("scroll", el.$el, e.target);
      if (el) {
        el = el && el.$el.querySelector(".v-data-table__wrapper");
        if (e.target == el) {
          if (el.scrollHeight - el.offsetHeight <= el.scrollTop + offset) {
            this.itemsPerPage += this.itemsPerPage < this.data.length - 50 ? 50 : this.data.length - this.itemsPerPage;

            //  this.$emit("scrollEnd", e);
          }
        }
      }
    },
    getName(el) {
      return (el.status == 200 ? "[Новая]" : "") + el.name;
    },
    async fitchData() {
      this.loading = true;
      if (this.rows) {
        this.data = this.rows;
      } else {
        const filters = this.filters;
        this.data = (
          await this.$axios.get("/mechti/tasks-goods", {
            params: {
              sort: { key: "date_start", order: "ASC" },
              filters,
            },
          })
        ).data.data;
      }
      this.loading = false;
    },
  },
};
</script>
