<template>
  <div>
    <v-card-text v-if="getType() == 1" class="pt-1 px-0">
      <v-card-subtitle class="py-1 text-left"> {{ getText(prop) }}<v-icon v-if="!readonly" @click="$emit('onClick', prop.id)">mdi-pencil</v-icon> </v-card-subtitle>
      <v-textarea outlined auto-grow readonly dense hide-details="auto" rows="1" :value="JSON.parse(prop.data)"> </v-textarea>
    </v-card-text>
    <v-card-text v-else-if="getType() == 2" class="pt-1">
      <v-card-subtitle class="py-1 text-left"> {{ getText(prop) }} <v-icon v-if="!readonly" @click="$emit('onClick', prop.id)">mdi-pencil</v-icon> </v-card-subtitle>
      <a-view-images
        :value="JSON.parse(prop.data)"
        @focus="focused_id = prop.id"
        @blur="focused_id = null"
        :append-icon="focused_id == prop.id ? 'mdi-pencil' : ''"
        @click:append="$emit('onClick', prop.id)"
      />
    </v-card-text>
    <v-card-text v-else-if="getType() == -1" class="pt-1 px-0">
      <v-card-subtitle class="py-1 text-left"> {{ getText(prop) }}<v-icon v-if="!readonly" @click="$emit('onClickAdd', prop)">mdi-plus</v-icon> </v-card-subtitle>
    </v-card-text>
    <v-card-text v-else-if="getType() == 3" class="pt-1">
      <v-card-subtitle class="py-1 text-left"> {{ getText(prop) }} <v-icon v-if="!readonly" @click="$emit('onClick', prop.id)">mdi-pencil</v-icon> </v-card-subtitle>
      <div>
        <v-row>
          <v-simple-table style="width: 100%">
            <tbody>
              <tr v-for="(el, key) in JSON.parse(prop.data)" :key="key">
                <td class="text-left">{{ el.text }}</td>
                <td class="text-left">{{ el.value }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-row>
      </div>
    </v-card-text>
    <v-card-text v-else-if="getType() == 5" class="pt-1 px-0">
      <v-card-subtitle class="py-1 text-left"> {{ getText(prop) }}<v-icon v-if="!readonly" @click="$emit('onClick', prop.id)">mdi-pencil</v-icon> </v-card-subtitle>

      <v-text-field outlined auto-grow readonly dense hide-details="auto" type="number" :value="JSON.parse(prop.data)" />
    </v-card-text>
    <v-card-text v-else-if="getType() == 6" class="pt-1 px-0">
      <v-card-subtitle class="py-1 text-left"> {{ getText(prop) }}<v-icon v-if="!readonly" @click="$emit('onClick', prop.id)">mdi-pencil</v-icon> </v-card-subtitle>
      <a-input-adsInfo :value="JSON.parse(prop.data)" :config="{ model: { model: 'adsInfo' } }" opt="readonly" />
    </v-card-text>
    <v-card-text v-else-if="getType() == 7" class="pt-1 px-0">
      <v-card-subtitle class="py-1 text-left">
        {{ getText(prop) }}
        <v-icon v-if="!readonly" @click="$emit('onClick', prop.id)">mdi-pencil</v-icon>
      </v-card-subtitle>
      <a-input-adsInfo v-if="true" :value="JSON.parse(prop.data)" :config="{ model: { model: 'siteInfo' } }" opt="readonly" />
      <a-input-base-object v-else :model="{ model: JSON.parse(prop.prop_data).value }" :readonly="true" :value="JSON.parse(prop.data) || {}" opt="readonly" />
    </v-card-text>
    <v-card-text v-else class="pt-1" @click="$emit('onClick', prop.id)"> Не известное свойство{{ prop }} </v-card-text>
    <v-divider />
  </div>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],
  props: {
    prop: Object,
    readonly: { type: Boolean, default: true },
  },
  data() {
    return {
      title: "",
      focused_id: null,
      loading: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    savePropData(e) {
      this.prop.data = e;
    },
    getType() {
      let prop = this.prop;
      let type = prop.type;
      if (!prop.id) return -1;
      if (type == 4) {
        type = JSON.parse(prop.options).find(o => o.value == prop.value).type;
      }

      return type;
    },
    getText(prop) {
      let options = JSON.parse(prop.options);
      let value;
      if (options) {
        value = options.find(x => x.value == prop.value) || {};
      }
      return value?.text ? value.text : prop.value;
    },
  },
};
</script>
