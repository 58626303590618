<template>
  <div class="py-1 pb-3">
    <v-expansion-panels accordion v-if="model.accordion" class="pa-0">
      <v-expansion-panel class="pa-0">
        <v-expansion-panel-header dense>
          <template v-slot:default="{ open }">
            <v-card-title class="py-0">
              {{ model.title }}
              <v-spacer></v-spacer>
              <div v-if="open" class="text-right">
                <v-btn v-if="!readonly" class="ma-0" @click.stop="createNew()" color="primary"> Добавить </v-btn>
              </div>
            </v-card-title>
          </template></v-expansion-panel-header
        >
        <v-expansion-panel-content class="table_content" style="padding: 0">
          <portal-target :name="`table-content-` + model.name" style="padding: 0"
        /></v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
      <portal-target :name="`table-content-` + model.name" />
    </div>
    <portal :to="`table-content-` + model.name">
      <v-card>
        <v-card-title class="py-2">
          {{ model.title }}
          <v-spacer></v-spacer>
          <div class="text-right pa-1">
            <v-btn v-if="!readonly" class="ma-1" @click="add()" color="primary"> Добавить </v-btn>
          </div>
        </v-card-title>
        <v-card-text :style="`height: ${detailHeight}px; overflow: auto`">
          <a-table-f-data2
            ref="tableData"
            :dataTable="data"
            :useQuery="false"
            :model="m_table.list"
            :searchable="false"
            :defaults="{
              filters: {},
              sort: { key: 'id', order: 'DESC' },
            }"
            @click="onClickRow($event)"
          >
            <template v-slot:top> </template>
            <template v-slot:item.actions="{ row }">
              <div class="d-flex">
                <v-btn x-small fab class="ma-0 ml-0" color="red" title="Отменить" @click.stop="remove(row)">
                  <v-icon small>fas fa-times</v-icon>
                </v-btn>
              </div>
            </template>
          </a-table-f-data2>
        </v-card-text>
      </v-card>
    </portal>
    <s-edit-form-data
      v-model="showEditDialog"
      v-if="showEditDialog"
      :dataTable="value"
      :m="configH.m"
      :configForm="{ head: configH.head, title: configH.title, api: configH.api }"
      :id="configH.id"
      :opt="configH.opt"
      @save="editRows($event)"
    />
  </div>
</template>

<script>
import { genModel } from "@/components/mixings";
export default {
  mixins: [genModel],
  props: {
    value: Array,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },

    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      configH: null,
    };
  },
  computed: {
    data: {
      get() {
        return this.value || [];
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    detailHeight() {
      return 300;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    m_table() {
      let model;
      if (this.model.modelConfig) {
        model = JSON.parse(JSON.stringify(this.$store.getters["config/get"].models));
        const path = this.model.modelConfig.split(".");
        for (let i = 0; i < path.length; i++) {
          const el = path[i];
          model = JSON.parse(JSON.stringify(model?.[el] || {}));
        }
      } else {
        if (typeof this.model.model === "string") {
          console.log(this.$store.getters["config/get"].models);
          model = this.getObjectPath(this.$store.getters["config/get"].models, this.model.model);
        } else {
          model = {};
          if (this.model?.model?.standart) {
            console.log("standart");
            model = Object.assign({}, this.model?.model || {});
            return model;
          } else {
            model.list = [...(this.model?.model?.form || [])];
            model.form = [...(this.model?.model?.form || [])];
            model.title = this.model?.model?.title;
            const fields = model.form
              .map(m => {
                return m.name;
              })
              .join(",");
            model.config = {
              default: {
                fields: fields,
                fieldsRO: "",
                fieldsForm: this.model?.model?.fieldsForm || fields,
              },
            };
          }
        }
      }
      if (!model.form.find(m => m.name === "actions")) model.form.push({ name: "actions", width: 40 });
      if (!!model?.list) model.list = [...model.form];
      return model;
    },
  },
  methods: {
    add(id = null) {
      const config = {};
      this.configH = null;
      config.head = this.m_table.config.default || {};
      config.id = id;
      config.api = null;
      config.opt = "delete";
      config.m = this.m_table;
      this.configH = config;
      this.showEditDialog = true;
    },
    remove(r) {
      let id = r["_id"];
      let key = this.data.findIndex(d => d["_id"] === id);
      let e = this.data;
      e.splice(key, 1);
      this.data = e;
    },
    onClickRow(e) {
      if (e.field.name == "actions") {
      } else {
        this.add(e.key);
      }
    },
    async editRows(r) {
      console.log("edit rows", r, this.data);
      if (!r) r = this.data;
      r.forEach((rr, idx) => {
        rr["_id"] = idx;
      });
      this.data = [...r];
    },
  },
};
</script>
