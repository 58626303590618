<template>
  <div>
    <Widget
      ref="widget"
      :height="height"
      :model="model"
      :defaults="{ paramName: 'dashBoard.newWork', sort: { key: 'id', order: 'DESC' }, filters: hideViewed ? { status: -1 } : {} }"
      :api="{ name: 'widget_getNewWork', params: { role: $root.profile.role, user_id: $root.profile.id } }"
      @click="onClick($event)"
      :isLoading="loading"
      @loading="loading = $event"
    >
      <template v-slot:title>
        График работ
        <v-icon small @click="hideViewed = !hideViewed">{{ hideViewed ? "fas fa-eye-slash" : "fas fa-eye" }}</v-icon>
      </template>
    </Widget>
    <s-quickOpenDoc ref="quickOpenDoc" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </div>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {
    Widget: () => import("./widgets/apiTable"),
  },
  props: {
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      contentHeight: 10,
      hideViewed: true,
      loading: false,
      data: [],
      showConfig: false,
      timeID: null,
      array_object: [],
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    model() {
      return this.$store.getters["config/get"].models.dashBoard.list_newWork;
    },
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
  },
  watch: {},
  methods: {
    onClick(e) {
      console.log("onclick", e);
      if (e.field?.isMenu) {
        console.log("onclick menu", e);
        if (["document"].includes(e.field.name)) {
          let menuItems = [
            {
              name: e.field.name,
              title: "Открыть документ",
              action: async () => {
                this.$refs.quickOpenDoc.openDocument(e.row.base_name, parseInt(e.row.doc_id), e.row);
              },
            },
          ];
          this.showPopupMenu(e.field.name, e.row, e.event, { menuItems });
        } else if (["object_name"].includes(e.field.name)) {
          this.showPopupMenu("object_work", e.row, e.event);
        } else this.showPopupMenu(e.field.name, e.row, e.event, e);
        return;
      }
      // this.$refs.quickOpenDoc.openDocument("AccountingDocActWork", parseInt(e.row.id), e.row);
    },

    async fitchData() {
      this.loading = true;
      let filters = null;

      this.afterFitch();
    },
    afterFitch() {
      this.loading = false;
    },
  },
};
</script>
