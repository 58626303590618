/** @format */

export default {
  data() {
    return {
      documentData: [],
    };
  },
  computed: {
    model_del() {
      let model = [...this.$store.getters['config/get'].models.objectPlanning.list];
      model.find((m) => m.name == 'percent_finish').formula = (d) => {
        if (!d.progress) return '???';
        let res = '';
        let total = 0;
        let done = 0;
        d.progress.forEach((p) => {
          let per = p.per || 0;
          res = res + (res ? ' ' : '') + p.name.substr(0, 1) + per.toFixed(0);
          total += 100;
          done += p.per || 0;
        });
        //        console.log(d.progress, res, done, total);
        res = ((100 * done) / (total || 1)).toFixed(2) + `% (${res})`;
        return res;
      };
      return model;
    },
  },
  methods: {
    async getDocumentPayments(data) {
      let res = [];
      let resp = await this.$axios.post('/report_sql', { name: 'planning_getDocumentPayments', params: { id: data.id } });
      if (resp.data.status == 'ok') {
        res = resp.data.data;
      }
      this.documentData = res;
      return res;
    },
    async getObjectProgress(id = 0) {
      let res = [];
      let where = !id ? '' : 'and c.object_id=' + id;
      let q = `  
SELECT *, done*100/total  as per  fROM (
SELECT c.object_id ,'Покупки' as name, COUNT(1) AS total,SUM(case when b.status IN (203,204,298,299) then 1 ELSE 0 END) AS done 
from construction_goods c LEFT JOIN buying b ON b.id=c.task_id 
WHERE c.object_id IS NOT null  ${where}
GROUP BY c.object_id
UNION 
SELECT  c.object_id, c.department AS name , COUNT(1) AS total,SUM(case when c.status IN (102,103,104) then 1 ELSE 0 END) AS done  
from construction_schedule c  LEFT JOIN tasks b ON b.id=c.task_id
where c.department <> 'Снабжение' and c.department <> 'Мебель' ${where}
GROUP BY c.department, c.object_id
) AS t
`;
      //      let resp = await this.$axios.post('/accounting/records/report--sql', { q });
      let resp = await this.$axios.post('/report_sql', { name: 'planning_getObjectProgress', params: { id: id || -1 } });
      if (resp.data.status == 'ok') {
        res = resp.data.data;
      }
      return res;
    },
    async getObjectBalance(data) {
      let res = [];
      let accIds = [];
      let resp = await this.$axios.post('/report_sql', { name: 'getAccRecordsForPlanning', params: { id: data.id } });
      if (resp.data.status == 'ok') {
        accIds = resp.data.data.map((m) => {
          return m.id;
        });
      }
      accIds.push(-1);
      let params = {
        filters: {
          OR: [
            { debit_account: { condition: 'LIKE', value: '08%' }, debit_subconto_value_1: data.id },
            { credit_account: { condition: 'LIKE', value: '08%' }, credit_subconto_value_1: data.id },
            { credit_account: { condition: 'LIKE', value: '62.2' }, credit_subconto_value_1: data.id },
            { credit_account: { condition: 'LIKE', value: '76.1' }, credit_subconto_value_2: data.id },
            { debit_account: { condition: 'LIKE', value: '76.1' }, debit_subconto_value_2: data.id },
            { debit_account: { condition: 'LIKE', value: '67.1.1' }, debit_subconto_value_3: data.id },
          ],
        },
        pager: {},
      };
      params.filters = { id: accIds };
      resp = await this.$axios.get('accounting/records', { params });
      if (resp.data.status == 'ok') {
        res = resp.data.data;
      }
      return res;
    },
    calcBalance(data, balance, documents) {
      const type = (el, d) => {
        return [...(el.type || []), ...d];
      };
      let calc = {};
      let calc2 = {};
      calc['type_20'] = 0; //остаток средст инвестора
      calc['type_90'] = 0; //личные вложения
      calc['type_-1'] = 0; //незавершенные платежи
      let depts = [];
      for (let i = 0; i < balance.length; i++) {
        const el = balance[i];
        if (el.type) {
          /*      Object.defineProperty(this.balanceCalc, el.type, {
            enumerable: true,
            configurable: true,
            writable: true,
            value: el.value,
          });
          // this.balanceCalc[el.type] = (this.balanceCalc[el.type] || 0) + el.value;
      */ continue;
        }
        el.type = null;
        /*
100 - первоначальный взнос - не зависимо от того кто его сделал
22 - первоначальный взнос - сделанный компанией
24 - первоначальный взнос - сделанный инвестором
20 - деньги наличка остаток
90 - деньги добавленные компанией
70 - все затраты инвестора или клиента
1 - стоимость реализации
2 - наличные вложения инвестора (без первоначального взноса)
3 - расходы организационные
4 - расходы административные
21 - Взнос инвестора, ипотека
23 - сумма выплаченных процентов по ипотеке
*/
        if ([11].includes(el.operation_id)) {
          if ([1, 15].includes(parseInt(el.debit_subconto_value_3))) {
            //затраты на оформление
            el.type = type(el, [3]);
            calc['type_20'] -= el.value;
          } else if ([49].includes(parseInt(el.debit_subconto_value_2))) {
            //затраты на оформление
            el.type = type(el, [3]);
            calc['type_20'] -= el.value;
          } else if ([24].includes(parseInt(el.debit_subconto_value_3))) {
            //покупка объекта (считаю что перв взнос из кассы компании) ---не кредит???
            el.type = type(el, [22, 100]);
          } else if ([21].includes(parseInt(el.debit_subconto_value_2))) {
            //в заказе услуг разнесенные административные расходв
            el.type = type(el, [4]);
            calc['type_20'] -= el.value;
          } else {
            el.type = type(el, [1]);
            calc['type_20'] -= el.value;
          }
        } else if ([1, 2, 46, 24].includes(el.operation_id)) {
          //затраты на реализацию
          el.type = type(el, [1]);
          calc['type_20'] -= el.value;
        } else if ([54].includes(el.operation_id)) {
          //распределение расходов
          el.type = type(el, [4]);
          calc['type_20'] -= el.value;
        } else if ([25].includes(el.operation_id)) {
          //снятие с резерва
          el.type = type(el, [1]);
          calc['type_20'] += el.value; //возвращаем средства в казну
          el.value = -el.value;
          //calc['type_1'] -= el.value; //минусуем стоимость реализации
        }

        //Долги по документам
        if ([1, 2, 11].includes(el.operation_id)) {
          let dept =
            this.documentData.find((d) => {
              return d.base_name == el.base_name && d.id == el.base_id;
            })?.value_dept || 0;
          if (dept) {
            if (!depts.includes(el.base_name + '#' + el.base_id)) {
              depts.push(el.base_name + '#' + el.base_id);
              calc['type_1'] -= dept; //минусуем стоимость реализации
              calc['type_20'] += dept; //возвращаем средства в казну
              calc['type_-1'] += dept; //возвращаем средства в казну
            }
            el.type = type(el, [-1]);
          }
        }

        if ([49, 48, 51].includes(el.operation_id)) {
          if (balance.find((b) => b.operation_id == 48)) {
            //Первоначальный взнос инвестор
            el.type = type(el, [24, 100, 70]);
          } else {
            //Первоначальный взнос компанией
            el.type = type(el, [22, 100]);
          }
        }
        if ([39, 43].includes(el.operation_id)) el.type = type(el, [23, 70]);
        if ([39].includes(el.operation_id)) calc['type_20'] -= el.value;

        if ([40].includes(el.operation_id)) el.type = type(el, [0]);

        //Возврат клиенту и инвестору
        if ([71, 72].includes(el.operation_id)) {
          el.type = type(el, [2, 70]);
          el.value = -el.value;
          calc['type_20'] += el.value;
        }

        //Общая себестоимость
        if ([1, 2, 11, 46, 36, 38, 49, 39, 43].includes(el.operation_id)) el.type = type(el, [9]);
        //Взнос инвестора наличка
        if ([44].includes(el.operation_id)) el.type = type(el, [70, 744]);
        if ([9, 27, 28, 29, 100, -48, 62].includes(el.operation_id)) el.type = type(el, [2, 70]);
        if ([9, 27, 28, 29, 100, 62].includes(el.operation_id)) calc['type_20'] += el.value;
        //Взнос инвестора ипотека
        if ([35, 38].includes(el.operation_id)) el.type = type(el, [21]);
        if ([38].includes(el.operation_id)) el.type = type(el, [70]);
        if (el.type) {
          el.type.forEach((t) => {
            if (!['-1', -1].includes(t)) {
              let val = calc?.['type_' + t] || 0;
              val = parseFloat(val.toFixed(2)) + el.value;
              calc['type_' + t] = val;
            }
          });
        } else {
          //группирую все неучтенные расходы по операции
          let val = calc2?.[el.operation_id] || 0;
          val = parseFloat(val.toFixed(2)) + el.value;
          calc2[el.operation_id] = val;
        }
      }
      calc.type_90 = calc.type_20 < 0 ? -calc.type_20 : 0;
      calc.type_20 = calc.type_20 > 0 ? calc.type_20 : 0;

      return { calc, calc2 };
    },
  },
};
