<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" :maxWidth="'100hv'">
      <template v-slot:title> Выбор товара</template>
      <v-card-text style="height: 80vh; overflow: auto" class="pa-0">
        <a-table-f-sql
          v-if="false"
          ref="table"
          :query="sql"
          :model="model"
          :useQuery="false"
          :defaults="{ paramName: 'goods.goodsReserve', sort: {} }"
          @click="clickRow"
        />
        <a-table-f-api-post 
          ref="table"
          :apiPost="{ api: '/report_sql/filters', params: paramsApi }"
          :limit="0"
          :model="model"
          :useQuery="false"
          :defaults="{ paramName: 'goods.goodsReserve', sort: { key: `name`, order: `ASC` } }"
          @click="clickRow"
        />
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="getAccess('documents.goodsReserve.create')" @click="add()" :title="'Вставить выбранное'" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
  </div>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess],
  props: {
    value: Boolean,
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: { type: String, default: "" },
  },
  data() {
    return {
      url: "/accounting/doc/goods_reserve",
      modelDetail: this.$store.getters["config/get"].models.documentGoodsReserve.formDetail,
      dataTable: [],
      sql: {},
    };
  },
  computed: {
    paramsApi() {
      let object_id = this.doc.operation_type == 24 ? -999 : this.doc.object_id;
      return { name: "getGoodsReserv_ListGoods", params: { object_id } };
    },
    title() {},
    model() {
      let model = JSON.parse(JSON.stringify(this.$store.getters["config/get"].models.documentGoodsReserve.listDetail));

      // goods.name, goods.category_id, goods.measure, good_id,store_id,object_id,for_store_id,SUM(amount) AS amount, SUM(VALUE) AS VALUE
      model = [
        { name: "checked", type: "checkbox", width: 35, hidden: !this.getAccess("documents.goodsReserve.create") },
        { name: "id", title: "#", type: "id", width: 50 },
        { name: "name", title: "Название", type: "string", width: 300, style: "white-space: normal;" },
        { name: "category_id", title: "Категория", type: "select", dir: "category", style: "white-space: normal;", sortable: true, width: 160 },
        { name: "amount", title: "Кол-во", type: "number", width: 50 },
        { name: "measure", title: "ед.изм.", type: "string", width: 30 },
        { name: "value", title: "Стоимость", type: "number", width: 100, sortable: true },
        { name: "object_id", title: "для объекта", type: "select", dir: "object", style: "white-space: normal;", sortable: true, width: 160 },
        { name: "buying_id", title: "Покупка", type: "string", style: "white-space: normal;", sortable: true, width: 80 },
        { name: "doc_given", title: "занят в..", type: "string", width: 300, style: "white-space: normal;" },
      ];
      return model;
    },
  },
  created() {
    this.getData();
  },
  watch: {
    value() {
      if (this.value) {
      }
    },
  },
  methods: {
    async getData() {
      let order = this.order_id ? " and og.id=" + this.order_id : "";
      let storage = this.doc?.store_from_id || 0;
      let object_id = this.doc.operation_type == 24 ? -999 : this.doc.object_id;

      let sql = `
SELECT goods.id, goods.name, goods.category_id, goods.measure, STORAGE.*,
  given.doc_given,
  if(given.id is null ,0,1) as check_disable
FROM (
SELECT good_id,store_id,object_id,SUM(amount) AS amount, SUM(VALUE) AS value
FROM accum_reserve
WHERE  ifnull(object_id,-999) = ${object_id}
GROUP BY good_id,store_id,object_id having SUM(amount)<>0 or sum(value)<>0
) AS STORAGE
LEFT JOIN accounting_dir_goods goods ON goods.id=STORAGE.good_id
LEFT JOIN (
  select doct.good_id,doct.store_id, doct.object_id, doc.id , CONCAT('Движение №',code_doc) AS doc_given  
	from accounting_doc_goods_moving_table doct inner join accounting_doc_goods_moving doc on doc.id=doct.parent_id 
  where doc.status=1 
  union all
  select doct.good_id,doct.store_id, doct.object_id, doc.id , CONCAT('Резервирование №',code_doc) AS doc_given  
	from accounting_doc_goods_reserve_table doct inner join accounting_doc_goods_reserve doc on doc.id=doct.parent_id 
  where doc.status=1   
) as given ON given.good_id=STORAGE.good_id AND given.store_id=STORAGE.store_id AND ifnull(given.object_id,-10)=ifnull(STORAGE.object_id,-10)

`;
      this.sql = { sql, order: "name" };
      return;
    },
    clickRow(d) {
      this.$refs.table.$refs.base.clickCheckbox(d.event, d.row);
      this.$refs.table.$refs.base.$forceUpdate();
    },
    add() {
      let rows = [];

      for (let row of this.$refs.table.data.filter((el) => {
        return el?.checked;
      })) {
        rows.push({
          amount: row.amount,
          measure: row.measure,
          value: row.value,
          good_id: row.good_id,
          store_id: row.store_id,
          buying_id: row.buying_id,
          object_id: row.object_id,
        });
      }
      if (rows.length == 0) return;
      console.log("dddd", rows);
      this.$emit("save", rows);
      this.$emit("input");
    },
  },
};
</script>
