<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <a-btn-refresh @click="$refs.table.updateData()" />
      <a-btn-add :icon="'far fa-plus-square'" :title="'Создать документ'" v-if="getAccess('documents.cashIn.create')" @click="createNew()" />
    </template>
    <portal to="v-main">
      <select-dialog v-model="showSelectDialog" v-if="showSelectDialog" :operations="operations" @select="onSelectOperation($event)" />
      <CashDialog ref="cashDialog" @updateData="$refs.table.updateData()" />
    </portal>

    <a-table-f-api
      ref="table"
      :api="url"
      :model="getModelList(m)"
      :useQuery="false"
      :defaults="defaults"
      @click="onClickRow($event)"
      @rowContextMenu="onRowContextMenu($event)"
      @update-data="getBalance($event.filters)"
    >
      <template v-slot:footer v-if="false"> 
        <div class="f-table-footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px" v-if="$refs.table">Показано: {{ $refs.table.pager.count }}, На сумму: {{ (total_price || 0).toLocaleString() }}</div>
          </div>
        </div>
      </template>
    </a-table-f-api>
    <context-menu ref="rowContextMenu" :items="contextMenuItems" @clickData="onContextMenuClick($event)" />
  </static-fullscreen-card>
</template>

<script>
import { getAccess, genModel, keyDetect } from "@/components/mixings";
import libsCash from "./libsCash";

export default {
  mixins: [getAccess, genModel, libsCash, keyDetect],
  components: {
    CashDialog: () => import("./dialogs/cashDialog"),
    selectDialog: () => import("./dialogs/cashOperationSelectDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      showSelectDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.cashIn,
      operation: null,
      url: "accounting/doc/order_receipt",
      defaults: {
        sort: { key: "date_doc", order: "DESC" },
        paramName: "documentCashIn",
      },
      balance: 0,
      contextData: null, 
    };
  },
  created() {
    this.$root.title = "Приходный ордер";
  },
  computed: {
    operations() {
      let res = [];
      this.m.config.forEach((el) => {
        if (el.id > 0) res.push(el.id);
      });
      return res;
    },
    permit() {
      return this.getAccess("menu.documentCashIn");
    },
    contextMenuItems() {
      let res = [];
      if (this.$root.profile.role == 1000) res = [{ name: "reStatus2", title: `Перепровести` }];
      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.cashType = null;
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    async onContextMenuClick(e) {
      if (e.item.name == "reStatus2") {
        await this.$axios.post(this.url, { id: e.data.id, status: 1 });
        await this.$axios.post(this.url, { id: e.data.id, status: 2 });
        this.$refs.table.updateRowData(e.data.id);
      }
    },
    onRowContextMenu(e) {
      console.log(e.row.status);
      if (e.row.status == 2 && this.$root.profile.role == 1000) {
      } else return;
      this.contextData = e;
      this.$refs.rowContextMenu.showMenu(e.event, e.row);
    },
    async getBalance(filter = {}) {
      const response = await this.$axios.get(`${this.url}/balance`, {
        params: { filters: filter },
      });
      this.balance = response.data.data;
    },
    async onSelectOperation(e) {
      if (!e.value) return;
      this.$refs.cashDialog.openDialog(e.id, 0, {}, { operation_name: e.text });
      return;
      let getConfig = await this.onConfigDocIn(e.id);
      if (!getConfig) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Документ не настроен",
        });
        return;
      }
      this.showEditDialogFun(0);
    },
    async onClickRow(d) {
      this.$refs.cashDialog.openDialog(d.row.operation_type, d.row.id);
      return;
      let getConfig = await this.onConfigDocIn(this.keys?.ctrl ? -1 : d.row.operation_type);
      if (!getConfig) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Документ не настроен",
        });
        return;
      }
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showSelectDialog = true;
    },
  },
};
</script>
