<template>
  <static-fullscreen-card>
    <template v-slot:header></template>

    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn></template
    >
    <template v-slot:title>
      <v-row class="ma-1">
        <v-btn-toggle v-model="activeTab" mandatory borderless dense @change="onChangeTab" class="ml-1">
          <v-btn v-for="item in tabs" :value="item.value" :key="`tab-${item.value}`" class="mx-1">
            <span class="hidden-md-and-down">{{ item.title }}</span>
            <v-icon :right="$vuetify.breakpoint.mdAndUp"> {{ item.icon }} </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </template>
    <a-table-f-api
      v-if="url"
      ref="table"
      :api="url"
      :model="model"
      :useQuery="false"
      :defaults="defaults"
      :selectedRows="selectedRows"
      @onEditField="onEditField($event)"
      @click="
        counter += 1;
        onDetectClick($event);
      "
    >
    </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0,
      idEdit: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.records,

      url: "",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Записи разговоров";
    this.onChangeTab();
  },
  computed: {
    tabs() {
      return this.m.tabs;
    },
    model() {
      let model = this.m?.[this.tabs.find((t) => t.value == this.activeTab).model] || [];
      console.log(model);
      return model;
    },
  },
  methods: {
    onChangeTab() {
      this.url = this.tabs.find((t) => t.value == this.activeTab).url;
      if (this.$refs.table) {
        this.$refs.table.refresh();
      }
    },
  },
};
</script>
