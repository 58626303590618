/** @format */

import dayjs from 'dayjs';
export default {
  calendar: {
    hour: {
      display: false,
    },
  },
  maxHeight1: 400,
  allVisibleTasksHeight: 400,
  title: {
    label: 'Your project title as html (link or whatever...)',
    //html: false,
  },
  times: {
    timeScale: 60 * 1000,
    timeZoom: 21, //*
  },
  scope: {
    //*
    before: 1,
    after: 15,
  },
  chart: {
    text: {
      offset: 4, //*
      xPadding: 20, //*
      display: true, //*
    },
  },
  taskList: {
    columns: [
      { id: 1, label: 'ID', value: 'id', width: 40 },
      { id: 2, label: 'Описание задачи', value: 'label', width: 200, expander: true },
      //{ id: 3, label: 'Назначена для', value: 'user', width: 130, html: true },
      { id: 3, label: 'Дата начала', value: (task) => dayjs(task.start).format('DD.MM.YYYY'), width: 78 },
      { id: 4, label: 'Тип', value: 'department', width: 68 },
      // { id: 5, label: '%', value: 'progress', width: 35, style: { 'task-list-header-label': { 'text-align': 'center', width: '100%' }, 'task-list-item-value-container': { 'text-align': 'center' } } },
    ],
  },
  locale: {
    name: 'ru', // name String
    weekdays: 'Воскресенье,Понедельник,Вторник,Среда,Четверг,Пятница,Суббота'.split(','), // weekdays Array
    weekdaysShort: 'ПН,ВТ,СР,ЧТ,ПТ,СБ,ВС'.split(','), // OPTIONAL, short weekdays Array, use first three letters if not provided
    months: 'Январь_Фераль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),

    //  weekdaysMin: 'Pn_Wt_Śr_Cz_Pt_So_Ni'.split('_'), // OPTIONAL, min weekdays Array, use first two letters if not provided
    //    months: 'Styczeń_Luty_Marzec_Kwiecień_Maj_Czerwiec_Lipiec_Sierpień_Wrzesień_Październik_Listopad_Grudzień'.split('_'), // months Array
    //   monthsShort: 'Sty_Lut_Mar_Kwi_Maj_Cze_Lip_Sie_Wrz_Paź_Lis_Gru'.split('_'), // OPTIONAL, short months Array, use first three letters if not provided
    //ordinal: (n) => `${n}`, // ordinal Function (number) => return number + output
    /*   relativeTime: {
      // relative time format strings, keep %s %d as the same
      future: 'za %s', // e.g. in 2 hours, %s been replaced with 2hours
      past: '%s temu',
      s: 'kilka sekund',
      m: 'minutę',
      mm: '%d minut',
      h: 'godzinę',
      hh: '%d godzin', // e.g. 2 hours, %d been replaced with 2
      d: 'dzień',
      dd: '%d dni',
      M: 'miesiąc',
      MM: '%d miesięcy',
      y: 'rok',
      yy: '%d lat',
    }, */
  },
};
