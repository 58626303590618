/** @format */

export default {
  data() {
    return {};
  },
  computed: {
    typeGroups() {
      let res;
      res = (this.$root.dirs?.leadsGroup || []).filter((g) => {
        return (
          (g?.status == 1 || this.$root.role == 11000) &&
          ((g.data?.access || []).length == 0 ||
            (g.data?.access && g.data.access.find((a) => a.type == 'user' && a.value.includes(this.$root.profile.id))) ||
            (g.data?.access && g.data.access.find((a) => a.type == 'role' && a.value.includes(this.$root.profile.role))))
        );
      });
      if (this.typeGroup < 0) {
        this.typeGroup = res?.[0]?.value === undefined ? -1 : res?.[0]?.value;
      }
      return res;
    },
  },
  methods: {
    async getStatuses(filters, group = null) {
      let statuses = [];

      let s = this.getFromConfig('config.statuses.leads') || [];
      s.forEach((el) => {
        if (el.type === 0 || el.type === undefined) statuses.push(el);
      });
      let params = { filters };
      let resp = await this.$axios.get('mechti/leads_status', { params });

      if (resp.data.status == 'ok') {
        for (let i = 0; i < resp.data.data.length; i++) {
          const el = resp.data.data[i];
          el['value'] = el.id;
          el['text'] = el.name;
          el['type'] = el.leads_status_group_id;
          el['action'] = el.action_text;
          el['next'] = el.data?.next || [];
          el['mask'] = el.data?.mask || '';
          if ((group && el.leads_status_group_id == group) || !group) statuses.push(el);
        }
      }
      return [...statuses];
    },
    async getTasks(filters = {}) {
      let res = [];
      let params = { filters };
      let resp = await this.$axios.get('mechti/leads_task_template', { params });
      if (resp.data.status == 'ok') {
        for (let i = 0; i < resp.data.data.length; i++) {
          const el = resp.data.data[i];
          el['value'] = el.id;
          el['text'] = el.name;
          el['action_text'] = el?.data?.action_text || el.name;

          res.push(el);
        }
      }
      return [...res];
    },
    async getTasksForLead(lead_id) {
      let res = [];
      let params = { filters };
      let resp = await this.$axios.post('/report_sql', { name: 'leads_getTaskList', params: { lead_id } });
      if (resp.data.status == 'ok') {
        for (let i = 0; i < resp.data.data.length; i++) {
          const el = resp.data.data[i];
          el['value'] = el.id;
          el['text'] = el.name;
          el['action_text'] = el?.data?.action_text || el.name;

          res.push(el);
        }
      }
      return [...res];
    },
  },
};
