/** @format */

//import education from './modules/education';

export default {
  appName: 'gk-mechti',
  version: 88,
  baseUrl: 'https://api.gk-mechti.ru/api',
  siteUrl: 'https://gk-mechti.ru',

  //baseUrl: 'https://81.163.31.148/api',

  websocetUrl: 'wss://api.gk-mechti.ru/data',
  videoUrl: 'https://api.gk-mechti.ru/api/file/download/',
  imageUrl: 'https://api.gk-mechti.ru/api/image/download/',
  fileUrl: 'https://api.gk-mechti.ru/api/file/download/',
  //  recordUrl: 'https://tel.gk-mechti.ru/',
  mainMenu: [
    { sys: 'index', auth: 1, name: 'Главная', icon: 'fas fa-border-all', route: { name: 'index' } },
    {
      sys: '',
      name: 'Анализ',
      icon: 'mdi-briefcase-variant-outline',
      accessName: 'subAnalytics',
      sublist: [
        { sys: '', auth: 10001, name: 'Покупки', route: { name: 'analysisBuying' } },
        { sys: '', auth: 10001, name: 'Работы', route: { name: 'analysisWork' } },
      ],
    },
    {
      sys: '',
      name: 'Кабинет',
      icon: 'mdi-briefcase-variant-outline',
      accessName: 'subCabinet',
      sublist: [
        { sys: '', auth: 10 + 1000, name: 'Статистика работ', route: { name: 'workStatistics' } },
        { sys: '', auth: 10 + 1000, name: 'Мои данные', route: { name: 'myData' } },
      ],
    },
    {
      sys: '',
      name: 'Обучение',
      icon: 'mdi-school-outline',
      accessName: 'subEducation',
      auth: 1000,
      sublist: [
        { auth: 1000, name: 'Админка', route: { name: 'education-admin' } },
        { auth: 1000, name: 'Мой курс', route: { name: 'education-person' } },
      ],
    },
    { sys: 'myPartner', auth: 100 + 1000, name: 'Мои партнеры', icon: 'fas fa-user-tie', route: { name: 'myPartner' } },
    { sys: 'myClient', auth: 100 + 1000, name: 'Мои клиенты', icon: 'fas fa-user-check', route: { name: 'myClient' } },
    { sys: '', auth: 1000 + 1, name: 'Комментарии', icon: 'fas fa-comments', route: { name: 'comments' } },
    { sys: '', auth: 1 + 1000, name: 'Задачи', icon: 'fas fa-tasks', route: { name: 'tasks' }, badge: 'tasks' },
    { sys: '', auth: 1 + 1000, name: 'Покупки', icon: 'fas fa-cart-plus', route: { name: 'tasks-goods' }, badge: 'tasksgoods' },
    {
      sys: '',
      auth: 1 + 1000,
      name: 'Заявки',
      icon: 'fas fa-search-dollar',
      route: { name: 'payOrder' },
      badge: { payorder: { new: 'green', plan: '#c69e5f', late: 'red' } },
    },
    { sys: '', auth: 1000111, name: 'Покупки OLD', icon: 'fas fa-tasks', route: { name: 'buying' }, badge: 'tasksgoods' },
    { sys: '', auth: 1000 + 1, name: 'Аренда', icon: 'fas fa-handshake', route: { name: 'rent' } },
    { sys: '', auth: 60 + 1000, name: 'Лиды', icon: 'fas fa-filter', route: { name: 'leads' } },
    {
      sys: '',
      auth: 1000,
      name: 'Объекты',
      icon: 'fas fa-bars',
      accessName: 'subObjects',
      sublist: [
        { sys: 'objects', auth: 10 + 1000, name: 'Объекты', icon: 'far fa-building', route: { name: 'objects' } },
        { sys: '', auth: 10 + 1000, name: 'Завершенные', icon: 'mdi-office-building-marker-outline', route: { name: 'objectsCompleted' } },
        { sys: '', auth: 10 + 1000, name: 'Предложения', icon: 'fas fa-search-location', route: { name: 'considers' } },
        { sys: '', auth: 10001, name: 'Рекламные', icon: 'fas fa-lightbulb', route: { name: 'objectsLamp' } },
        { sys: '', auth: 10001, name: 'Агентские', icon: 'fas fa-user-secret', route: { name: 'objectsAgent' } },
        { sys: '', auth: 10001, name: 'Партнерские', icon: 'fas fa-user-secret', route: { name: 'objectsPartner' } },
        { sys: '', auth: 10 + 1000, name: 'На продаже...', icon: 'fas fa-hand-holding-usd', route: { name: 'sales' } },
        { sys: '', auth: 1000, name: 'На сайте', icon: 'fas fa-globe', route: { name: 'sitePublication' } },
        { sys: '', auth: 1000, name: 'Проекты', icon: 'fab fa-adn', route: { name: 'projects' } },
        { sys: '', auth: 1000, name: 'Закрытие', icon: 'mdi-home-lock', route: { name: 'objectClose' } },
      ],
    },
    { sys: '', auth: 1000 + 1, name: 'Сметы', icon: 'fas fa-file-invoice', route: { name: 'estimate' } },
    { sys: '', auth: 1000, name: 'Договоры', icon: 'fas fa-file-signature', route: { name: 'contracts' } },
    { sys: '', auth: 20 + 1000, name: 'Инвесторы', icon: 'fas fa-users-cog', route: { name: 'investors' } },
    { sys: '', auth: 20 + 1000, name: 'Клиенты', icon: 'fas fa-user-friends', route: { name: 'clients' } },
    { sys: '', auth: 1000111, name: 'Import', icon: 'fas fa-chart-line', route: { name: 'import' } },
    { sys: '', auth: 30 + 1000, name: 'Финансы', icon: 'fas fa-chart-line', route: { name: 'finance' }, badge: 'finance' },

    {
      sys: '',
      auth: 10001,
      name: 'Склад',
      icon: 'fas fa-warehouse',
      accessName: 'subStorage',
      sublist: [
        { sys: '', auth: +1000, route: { name: 'storageGoods' }, name: 'Товары', icon1: 'fas fa-warehouse' },
        { sys: '', auth: +1000, route: { name: 'goodsIncome' }, name: 'Приёмка', icon1: 'fas fa-warehouse' },
        { sys: '', auth: +1000, route: { name: 'storageAccum' }, name: 'Остатки', icon1: 'fas fa-warehouse' },
        { sys: '', auth: +1000, route: { name: 'goodsReserve' }, name: 'Резервирование', icon: 'fas fa-unlock' },
        { sys: '', auth: +1000, route: { name: 'goodsMoving' }, name: 'Движение', icon: 'fas fa-people-carry' },
      ],
    },
    {
      sys: '',
      auth: 10001,
      name: 'Документы',
      icon: 'fab fa-dochub',
      accessName: 'subDocuments',
      sublist: [
        { sys: '', auth: +1000, route: { name: 'ordersGoods' }, name: 'Заказ товаров', icon1: 'fas fa-book' },
        { sys: '', auth: +1000, route: { name: 'ordersService' }, name: 'Заказ услуг', icon1: 'fas fa-book' },
        { sys: '', auth: +1000, route: { name: 'ordersCashOut' }, name: 'Расходный ордер', icon: 'mdi-cash-minus' },
        { sys: '', auth: +1000, route: { name: 'ordersCashIn' }, name: 'Приходный ордер', icon: 'mdi-cash-plus' },
        { sys: '', auth: +1000, route: { name: 'objectBuy' }, name: 'Договор покупки', icon: 'mdi-office-building-plus-outline' },
        { sys: '', auth: +1000, route: { name: 'objectSell' }, name: 'Договор продажи', icon: 'mdi-office-building-minus-outline' },
        { sys: '', auth: +1000, route: { name: 'loanGet' }, name: 'Взятие займа', icon: 'fas fa-contract' },
        { sys: '', auth: +1000, route: { name: 'actWork' }, name: 'Акты (Подрядчик)', icon: 'fas fa-file-signature' },
        { sys: '', auth: 10001, route: { name: 'actWorkClient' }, name: 'Акты (Клиент)', icon: 'fas fa-file-signature' },
      ],
    },
    {
      sys: '',
      auth: 10001,
      name: 'Бухгалтерия',
      icon: 'fas fa-calculator',
      accessName: 'subAccounting',
      sublist: [
        { sys: '', auth: +1000, route: { name: 'ordersCashBook' }, name: 'Кассовая книга', icon: 'fas fa-cash-register' },
        { sys: '', auth: 1000, route: { name: 'accRecords' }, name: 'Проводки', icon: 'fas fa-balance-scale-left' },
        { sys: '', auth: +1000, route: { name: 'accCashBox' }, name: 'Кассы', icon1: 'fas fa-book' },
        { sys: '', auth: +10001, route: { name: 'cashRemainder' }, name: 'Остатки по кассе ', icon1: 'fas fa-book' },
        { sys: '', auth: 1000, route: { name: 'accReportAccount' }, name: 'Отчеты по счету', icon1: 'fas fa-book' },
        { sys: '', auth: +10001, route: { name: 'expensesDistribution' }, name: 'Расходы периода', icon: 'fas fa-layer-group' },
        { sys: '', auth: +10001, route: { name: 'futurePayments' }, name: 'Предстоящие платежи', icon: 'fas fa-layer-group' },
      ],
    },

    { sys: '', auth: 10001, name: 'Проекты', icon: 'fab fa-adn', route: { name: 'projects_' } },
    { sys: '', auth: 1000, name: 'Разговоры', icon: 'fas fa-phone-volume', route: { name: 'records' } },
    { sys: '', auth: 1000, name: 'Инфо', icon: 'far fa-question-circle', route: { name: 'info' } },
    { sys: '', auth: 10001, name: 'База знаний', icon: 'far fa-question-circle', route: { name: 'wiki' } },
    {
      sys: '',
      auth: 10001,
      name: 'Настройки',
      icon: 'fas fa-cog',
      accessName: 'subSettings',
      sublist: [
        { sys: '', auth: 1000, route: { name: 'dirs' }, name: 'Справочники', icon: 'fas fa-book' },
        { sys: '', auth: 1000, route: { name: 'users' }, name: 'Пользователи', icon: 'fas fa-user-cog' },
        { sys: '', auth: 1000, route: { name: 'invite' }, name: 'Приглашения', icon: 'mdi-account-plus' },
        { sys: '', auth: 1000, route: { name: 'settings' }, name: 'Настройки', icon: 'fas fa-cog' },
        { sys: '', auth: 1000, route: { name: 'uploadsGroup' }, name: 'Группы доступа', icon: ' mdi-file-cog' },
        { sys: '', auth: 1000, route: { name: 'uploads-admin' }, name: 'Загрузки', icon: ' mdi-file-eye' },
        { sys: '', auth: 1000, route: { name: 'events' }, name: 'Уведомления', icon: 'fas fa-bell' },
        { sys: '', auth: 1000, route: { name: 'apiConfig' }, name: 'Настройка API', icon: 'far fa-cog' },
        { sys: '', auth: 1000, route: { name: 'editConfig' }, name: ' Конфигуратор', icon: 'fas fa-cog' },
        { sys: '', auth: 1000, route: { name: 'info-admin' }, name: 'Инфо', icon: 'far fa-question-circle' },
        { sys: '', auth: 1000, route: { name: 'logs-admin' }, name: 'Логи БД', icon: ' mdi-file-eye' },
      ],
    },
  ],
  meta: {
    enum: {
      propsType: [
        { value: 1, text: 'text' },
        { value: 2, text: 'images' },
        { value: 3, text: 'options' },
        { value: 4, text: 'mix' },
        { value: 5, text: 'number' },
        { value: 6, text: 'adsInfo' },
        { value: 7, text: 'model' },
      ],
      status_active: [
        { value: 1, text: 'Активно' },
        { value: 2, text: 'Не активно' },
      ],
      status_document: [
        { value: 1, text: 'Не проведен' },
        { value: 2, text: 'Проведен' },
      ],
      yesNo: [
        { value: 0, text: 'Нет' },
        { value: 1, text: 'Да' },
      ],
      status_fin: [
        { value: 0, text: 'Новое', next: [1], action: '', field: 'status_fin' },
        { value: 1, text: 'На продаже', next: [-1, 2], action: 'Выставить на продажу', field: 'status_fin' },
        { value: -1, text: 'Снято с продажи', next: [1], action: 'Снять с продажи', field: 'status_fin', trigger: { fields: { status_site: 0 } } },
        { value: 2, text: 'Продано', next: [2], action: 'Продать', field: 'status_fin', func: 'statusSale' },
      ],
      status_site: [
        { value: 0, text: 'Не публикуется', next: [1], action: 'Отменить публикацию', field: 'status_site' },
        { value: 1, text: 'Публикуется', next: [0], action: 'Отправить на публикацию', field: 'status_site', formula: '((d)=>{return d>0;})' },
        { value: null, text: 'Публикация не настроена', next: [1], action: '', field: 'status_site' },
      ],
      status_object: [
        { value: 0, text: 'Новое', next: [1, 10, 2], action: '', field: 'status' },
        { value: 1, text: 'Рассмотрение', next: [-1, 10, 2], action: 'На рассмотрение', field: 'status' },
        { value: -1, text: 'Отменено предлодение', next: [1], action: 'Отклонить предложение', field: 'status' },
        { value: 10, text: 'В работе', next: [-2, 90, 11], action: 'Принять в работу', field: 'status', func: '' },
        { value: 11, text: 'На паузе', next: [10], action: 'Поставить на паузу', field: 'status', func: '' },
        { value: -2, text: 'Отменена работа', next: [10, 1], action: 'Отменить работу', field: 'status' },
        { value: 90, text: 'Завершен', next: [10], action: 'Завершено', field: 'status' },
        //{ value: 2, text: 'Фонарик', next: [-3], action: 'Перевести в "фонарик"', field: 'status' },
        //{ value: -3, text: 'Отменен фонарик', next: [2], action: 'Убрать "фонарик"', field: 'status', trigger: { fields: { status_site: 0 } } },
      ],
      status_lamp_object: [
        { value: 1, text: 'Агент', next: [-1], action: 'Восстановить', field: 'status' },
        { value: -1, text: 'Отменен "агент" ', next: [1], action: 'Убрать', field: 'status', trigger: { fields: { status_site: 0 } } },
        { value: 2, text: 'Фонарик', next: [-2], action: 'Перевести в "фонарик"', field: 'status' },
        { value: -2, text: 'Отменен фонарик', next: [2], action: 'Убрать "фонарик"', field: 'status', trigger: { fields: { status_site: 0 } } },
      ],
      status_work: [
        { value: -2, text: 'Отменено', next: [], action: 'Отменить работу', field: 'status' },
        { value: -1, text: 'Черновик', next: [], action: '', field: 'status' },
        { value: 100, text: 'Новая работа', next: [101, -2], action: '', field: 'status' },
        { value: 101, text: 'В работе', next: [-2, 103], action: 'Принять работу', field: 'status' },
        { value: 103, text: 'в акте (черновик)', next: [], action: 'Создать акт', field: 'status', func: 'statusCreateActWork' },
        { value: 104, text: 'в акте (отправлен)', next: [], action: '', field: 'status' },
        { value: 102, text: 'Выполнена', next: [], action: '', field: 'status' },
        { value: 199, text: 'Отменено', next: [], action: 'Отменить работу', field: 'status' },
      ],
      accOperations: [
        { value: 1, text: 'Заказ товаров на объект (чек-лист)' },
        { value: 2, text: 'Заказ товаров на объект (свободный)' },
        { value: 11, text: 'Заказ услуг на объект' },
        { value: 37, text: 'Выплаты по кредиту компанией' },
      ],
    },
  },
};
