<template>
  <a-table-f-base
    ref="base"
    :model="model"
    :data="data"
    :loading="loading"
    :sort="sort"
    :pager="pager"
    :filters="filters"
    :searchable="searchable"
    :selectedRows="selectedRows"
    :paramName="defaults.paramName || ''"
    :folder_id="defaults.folder_id"
    :headRows="headRows"
    :footer="{ nnnn: footerText }"
    @dblclick="onDblClickRow($event)"
    @click="onClickRow($event)"
    @sort="onSort($event)"
    @change-filter="onInputFilter($event)"
    @scrollEnd="onScrollEnd($event)"
    @checkedAll="setCheckAll($event)"
    @onEditField="$emit('onEditField', $event)"
    @rowContextMenu="$emit('rowContextMenu', $event)"
  >
    <template v-slot:top>
      <slot name="top" />
    </template>
    <template v-for="el in model" v-slot:[getSlotName(el)]="{ item, row, field }">
      <slot :name="getSlotName(el)" :item="item" :row="row" :field="field" />
    </template>
    <template no-data>
      <slot name="no-data" />
    </template>
    <template v-slot:footer>
      <slot name="footer">
        <div class="f-table-footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px">
              <v-icon v-if="defaults.paramName" style="margin: 0px 6px" x-small @click.stop="$refs.base.onContextMenuClick({ name: 'settings' })">fas fa-cog</v-icon>
              <v-icon style="margin: 0px 6px" x-small @click.stop="refresh()">fas fa-redo-alt</v-icon>
              {{ footerText }}
            </div>
          </div>
        </div>
      </slot>
    </template>
  </a-table-f-base>
</template>

<script>
import { getIndex } from "../../../mixings";
import lodash from "lodash";

export default {
  mixins: [getIndex],
  props: {
    limit: { type: Number, default: 50 },
    api: String,
    model: [Object, Array],
    useQuery: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    selectedRows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    defaults: {
      type: Object,
      default: () => {
        return {};
      },
    },
    headRows: { type: Number, default: 1 },
    setFilter: Function,
  },
  data() {
    return {
      urlPrefix: "q",
      pager: {},
    };
  },
  computed: {
    footerText() {
      let res = "";
      if (this.pager)
        if (typeof this.pager.count == "object") {
          for (const key in this.pager.count) {
            if (key.includes("count_") || key.includes("sum_")) {
              const name = this.model.find(m => m.name === key)?.title || key;
              if (name) {
                let val = this.pager.count[key];
                if (typeof val === "number") val = val.toLocaleString();
                res += (res ? "; " : "") + `${name}: ${val}`;
              }
            }
          }
        } else {
          res = `Показано: ${this.pager.count || 0}`;
        }
      return res;
    },
  },
  watch: {
    "defaults.filters"(newV, oldV) {
      if (!lodash.isEqual(newV, oldV)) {
        this.getFilters();
        this.onChangeFilter();
      }
    },
    $route() {
      this.updateData();
    },
    api() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    getSlotName(model) {
      return `item.` + model.name;
    },
    setCheckAll(v) {
      for (const el of this.data) {
        if (this.model?.check?.isDisableFunc) {
          let res = this.model.check.isDisableFunc(el);
          el.check_disable = res ? 0 : 1;
        }
        if (el?.check_disable) el.checked = 0;
        else el.checked = v;
      }
    },
    onClickRow(event) {
      if (event.field.type === "folder" && event.row.isparent) {
        this.filters.parent_id = event.row.id;
        this.onChangeFilter();
      }
      if (event.field.type === "checkbox") {
        return;
      }
      if (event.event.button == 0) this.$emit("click", event);
    },
    onDblClickRow(event) {
      if (event.event.button == 0) this.$emit("dblclick", event);
    },
    getFilters() {
      if (this.defaults.sessionFilters) {
        this.filters = this.defaults.sessionFilters;
      } else if (this.defaults.filters) {
        this.filters = Object.assign({}, this.defaults.filters);
      } else this.filters = {};
    },
    init() {
      this.data = [];
      this.getFilters();
      if (this.defaults.sort) this.sort = this.defaults.sort;
      else this.sort = [{ key: "id", order: "ASC" }];
      //      if (this.limit !== 0)

      this.reloadData();
    },
    reloadData() {
      this.updateData();
    },
    refresh() {
      this.data = [];

      this.updateData();
    },
    updateData() {
      this.pager = {};
      this.pager.limit = this.limit;
      if (this.useQuery && this.$route.query[this.urlPrefix]) {
        let params = JSON.parse(this.$route.query[this.urlPrefix]);
        if (params.sort) {
          this.sort = params.sort;
        }
        if (params.filters) {
          this.filters = params.filters;
        }
      }
      this.pager.page = 0;
      this.$emit("update-data", this);
      this.fetchData();
    },

    onSort: function (el) {
      this.pager.page = 0;
      if (Array.isArray(this.sort)) {
        let idx = this.sort.findIndex(el => !el?.default);
        let def = this.sort.filter(el => el?.default === true);
        let order = "ASC";
        let key = el;
        let sort = {};
        if (idx == -1) {
        } else {
          if (this.sort[idx].key == el) {
            if (this.sort[idx].order == "ASC") order = "DESC";
            if (this.sort[idx].order == "DESC") key = null;
          }
        }
        sort = { key, order };
        this.sort = key ? [sort, ...def] : [...def];
      } else {
        if (this.sort.key == el) {
          if (this.sort.order == "ASC") {
            this.sort.order = "DESC";
          } else {
            this.sort.order = "ASC";
          }
        } else {
          this.sort.key = el;
          this.sort.order = "ASC";
        }
      }
      if (this.useQuery) {
        this.updateUrlParams();
      } else {
        this.updateData();
      }
    },
    updateUrlParams: function () {
      var params = {
        sort: this.sort,
        filters: this.filters,
      };
      this.$router.push({
        query: { [this.urlPrefix]: JSON.stringify(params) },
      });
    },
    onInputFilter: lodash.debounce(function () {
      this.onChangeFilter();
    }, 500),
    onChangeFilter: function () {
      this.pager.page = 0;
      this.pager.count = 0;
      this.pager.limit = this.limit;
      if (this.useQuery) {
        this.updateUrlParams();
      } else {
        this.updateData();
      }
      this.$emit("change-filter", this.filters);
    },
    onScrollEnd(e) {
      //const count = this.pager.count?.count_id || this.pager.count;
      // let pages = parseInt(count / this.pager.limit);
      if (!this.loading && this.maxPage() > this.pager.page) {
        this.pager.page++;
        this.fetchData(true);
      }
    },
    maxPage: function () {
      const count = this.pager.count?.count_id || this.pager.count;
      let res = this.pager.limit == 0 ? 0 : Math.ceil(count / this.pager.limit);
      return res;
    },
    async afterFetchData(response) {},
    async beforeFetchData(response) {
      if (this.setFilter) {
        let f = await this.setFilter(this.filters);
        this.filters = f;
      }
    },
  },
};
</script>
