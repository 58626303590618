/** @format
 * 
 * Арендатор
 - Арендодатель
 - Мебель
 - Дизайн
 - Объект
 - Покупатель на объект
 */

const clients = {
  types: [
    { value: 1, text: 'Объект (покупка)' },
    { value: 2, text: 'Объект (дизайн)' },
    { value: 3, text: 'Объект (ремонт)' },
    { value: 4, text: 'Мебель' },
    { value: 5, text: 'Арендатор' },
    { value: 6, text: 'Арендодатель' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'ФИО', type: 'string', sortable: true, width: 500 },
    { name: 'client_type', title: 'Тип клиента', type: 'selectbit', sourceList: 'types', style: 'white-space: normal;', width: 500 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 200 },
    { name: 'user_id', title: '', type: 'number', sortable: true, width: 80 },
  ],
  form: [
    { name: 'name', title: 'ФИО', type: 'fio', validator: ['req'], class1: 'flex sm4 px-1', class: 'px-2 pb-3' },
    { name: 'passport', title: 'Паспортная информация', type: 'string' },
    { name: 'phone1', title: 'Телефон', type: 'phone', class1: 'flex sm6 px-1' },
    { name: 'phone2', title: 'Телефон 2', type: 'phone', class1: 'flex sm6 px-1' },
    { name: 'whatsapp', title: 'WhatsApp', type: 'string', class1: 'flex sm6 px-1' },
    { name: 'email', title: 'Email', type: 'string', validator: ['email'], class1: 'flex sm6 px-1' },
    { name: 'notes', title: 'Заметки', type: 'text' },
    { name: 'photo', title: 'Фото', type: 'images' },
    { name: 'docs', title: 'Файлы', type: 'filesdata', category: 1, hiddenCreate: true },
    { name: 'client_type', title: 'тип клиента', type: 'selectbit', sourceList: 'types' },
    { name: 'filesAdd', default: [], hidden: true },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 1, validator: ['req'] },
  ],
  config: {
    form: {
      title: 'Клиент',
      fields: 'name,client_type,passport,phone1,phone2,whatsapp,email,notes,photo,docs,filesAdd,status',
      fieldsRO: '',
      fieldsForm: 'name,client_type,phone1#6,phone2#6,whatsapp#6,email#6,passport,notes,photo,docs,status',
    },
  },
};

export default clients;
