<template>
  <div>
    <Widget
      ref="widget"
      :height="height"
      :model="model"
      :defaults="{ paramName: 'dashBoard.actWork', sort: { key: 'id', order: 'DESC' } }"
      :api="{ name: 'widget_getActWork', params: { role: $root.profile.role, user_id: $root.profile.id } }"
      @click="onClick($event)"
      :isLoading="loading"
      @loading="loading = $event"
      @rowContextMenu="onRowContextMenu($event)"
    >
      <template v-slot:title>
        Акты выполненных работ
        <v-icon small @click="hideViewed = !hideViewed">{{ hideViewed ? "fas fa-eye-slash" : "fas fa-eye" }}</v-icon>
      </template>
    </Widget>

    <s-quickOpenDoc ref="quickOpenDoc" @onClose="itemShowClose" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </div>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {
    Widget: () => import("./widgets/apiTable"),
  },
  props: {
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      idShow: 0,
      contentHeight: 10,
      hideViewed: true,
      loading: false,
      data: [],
      showConfig: false,
      timeID: null,
      array_object: [],
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    model() {
      return [
        { name: "id", title: "#", sortable: true, width: 40 },
        { name: "createdon", title: "Дата", type: "datetime", sortable: true, width: 80, style: "white-space: normal;" },
        {
          name: "status",
          title: "Статус",
          type: "select",
          options: "status_document",
          sortable: true,
          width: 90,
          style: "white-space: normal;",
        },
        { name: "object_id", title: "Объект", width: 150, isMenu: true, style: "white-space: normal;", hidden: true },
        { name: "object_name", title: "Объект", type: "string", width: 120, isMenu: true, style: "white-space: normal;" },
        { name: "vendor_id", title: "Поставщик", type: "select", dir: "vendor", width: 130, style: "white-space: normal;" },
        { name: "work_name", title: "Работа", type: "string", width: 200, style: "white-space: normal;" },
      ];
    },
    sql() {
      let group = "";
      let order = "createdon desc";
      let where = ``;
      let sql = `
select * from (
SELECT o.name as object_name, cr.id, cr.object_id, cr.createdon,cr.work_name, cr.status,cr.vendor_id
FROM accounting_doc_act_work cr
	LEFT JOIN objects o ON o.id=cr.object_id
where o.id in (${this.array_object.join(",")}) and cr.deleted=0
) as t
`;
      let res = { sql, order, group, where };
      return res;
    },
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
  },
  watch: {},
  methods: {
    onClick(e) {
      if (e.field?.isMenu) {
        if (["document"].includes(e.field.name)) {
          let menuItems = [
            {
              name: e.field.name,
              title: "Открыть документ",
              action: async () => {
                this.$refs.quickOpenDoc.openDocument(e.row.base_name, parseInt(e.row.doc_id), e.row);
              },
            },
          ];
          this.showPopupMenu(e.field.name, e.row, e.event, { menuItems });
        } else this.showPopupMenu(e.field.name, e.row, e.event, e);
        return;
      }
      this.idShow = e.row.id;
      this.$refs.quickOpenDoc.openDocument("AccountingDocActWork", parseInt(e.row.id), e.row);
    },
    itemShowClose() {
      this.loading = true;
      let id = this.idShow;
      this.idShow = 0;
      if (id) this.$refs.widget.updateRowData(id);
      this.loading = !true;
    },

    async fitchData() {
      this.loading = true;
      this.afterFitch();
      return;
      let array_object = [];
      let filters = null;
      let resp;
      //resp = await this.$axios.get("/mechti/objects");
      resp = await this.$axios.post("/mechti/objects/report", {
        select: "objects.id as id",
        leftJoin: "objects_access ON objects_access.object_id = objects.id",
        where: [90, 1000, 85].includes(this.$root.profile.role) ? {} : { "objects_access.user_id": this.$root.profile.id },
        group: "objects.id",
      }); //console.log("work", resp);

      if (resp.data.data.length > 0) {
        resp.data.data.forEach((el) => {
          array_object.push(el.id);
        });
        this.array_object = array_object;
      } else {
        this.array_object = [];
        this.afterFitch();
        return;
      }
      this.afterFitch();
    },
    afterFitch() {
      this.loading = false;
    },
  },
};
</script>
