<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header> </template>
      <template v-slot:actions>
        <a-btn-refresh @click="$refs.table.updateData()" />
        <a-btn-add :icon="'far fa-plus-square'" :title="'Создать документ'" v-if="getAccess('documents.actWork.create') && !true" @click="createNew()" />
      </template>

      <a-table-f-api
        ref="table"
        :api="url"
        :model="getModelList(m)"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
        @click1="onClickRow($event)"
      >
      </a-table-f-api>
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow || showEditDialog" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, genModel, doubleClickDetect, keyDetect } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect],
  components: {
    editDialog: () => import("./dialogs/actWorkDialog"),
    ViewItem: () => import("./views/actWorkView"),
    // ViewItem: () => import("./views/objectBuyView"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      idItemShow: 0,
      title: "",
      m: this.$store.getters["config/get"].models.documentActWork,

      url: "/accounting/doc/act_work",
      defaults: {
        sort: { key: "date_doc", order: "DESC" },
        filters: this.getAccess("documents.actWork.viewAll") ? {} : { createdby_id: this.$root.profile.id },
        paramName: "documentActWork",
      },
      infoData: null,
      infoModel: null,
    };
  },
  created() {
    this.$root.title = "Акты выполненых работ";
  },
  computed: {
    permit() {
      return this.getAccess("menu.documentActWork");
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.showEditDialog = false;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },

    onSingleClick(d) {},
    onDoubleClick(d) {
      this.idItemShow = d.row.id;
      return;
      this.onClickRow(d);
    },
    onClickRow(d) {
      //  this.$router.push({ name: "objectBuy_view", params: { id: d.row.id } });

      this.showEditDialogFun(d.row.id);
    },

    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
