<template>
  <static-fullscreen-card>
    <template v-slot:title> ({{ data.id }}) {{ data.name }}</template>

    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="makeTasks()" :loading="isMakeTasks" :disabled="isMakeTasks || !needMakeTask" v-if="getAccess('object.taskMake')">
        <btn-title icon="fas fa-tasks">Создать задачи</btn-title>
      </v-btn>
      <a-btn-refresh @click="$refs.table.updateData()" />
      <a-btn-add @click="createNew()" v-if="getAccess('object.goodsEdit', { access: data.object_access })" />
    </template>

    <a-table-f-api
      v-if="!loading"
      ref="table"
      :api="url"
      :model="model"
      :useQuery="false"
      :defaults="{
        sort: { key: 'id', order: 'DESC' },
        filters: { object_id: id },
        paramName: 'goods.',
      }"
      :limit="0"
      @click="onClickRow($event)"
      @loadData="onLoadData($event)"
    >
    </a-table-f-api>
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <edit-dialog v-model="showEditDialog" :id="idEdit" :object_="data" />
  </static-fullscreen-card>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {
    editDialog: () => import("@/views/dialogs/goodsDialog"),
  },
  data() {
    return {
      loading: false,
      idEdit: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.goods,
      model1: this.$store.getters["config/get"].models.goods.list,
      data: {},
      categoryList: null,
      isMakeTasks: false,
      needMakeTask: false,
      url: "/mechti/construction-goods",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: { object_id: this.id },
      },
      balance: {
        debit: 0,
        credit: 0,
      },
      taskUserConfig: this.$store.getters["config/get"].models.taskUserConfigGoods,

      objectWork: {},
    };
  },
  created() {
    this.$root.title = "Чек-лист";
    this.fitchData();
  },
  computed: {
    model() {
      let model = this.getModelList(this.m);
      if (this.$root.profile.role > 20)
        model.push({
          name: "doc_id",
          title: "Документ",
          type: "selectapi",
          itemField: { value: "id", text: "code_doc" },
          api: "accounting/doc/order_good",
          routeName: "ordersGoods_view",
          preText: "Заказ товаров №",
          isMenu: true,
        });
      return model;
    },
    editObject() {
      //console.log(this.$root.profile);

      return this.getAccess("object.edit", {
        //id: this.id,
        access: this.data.object_access.filter(a => a.user_role == 55),
        //data: this.data,
      });
    },
    id() {
      return Number(this.$route.params.id);
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        if (this.idEdit) {
          this.$refs.table.updateRowData(this.idEdit);
          this.idEdit = 0;
          //  this.$refs.table.$refs.base.$forceUpdate();
        } else this.$refs.table.updateData();
      }
    },
  },
  methods: {
    async fitchData() {
      this.loading = true;
      try {
        const d = await this.$axios.get("/mechti/objects" + "/" + this.id);
        if (d.data && d.data.data) {
          this.data = d.data.data;
        }
        this.loading = false;
      } catch (error) {}
    },
    onClickRow(d) {
      if (this.$root.profile.role <= 20) return;
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event, d);
        return;
      }
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },

    async addTasks(task) {
      return new Promise(async (resolve, reject) => {
        let response = await this.$axios.post("/mechti/buying", task);
        let status = response.data.status == "ok";
        if (status) {
          let task_id = response.data.data.id;
          resolve(task_id);
        } else {
          reject(false);
        }
      });
    },
    onLoadData(e) {
      this.needMakeTask =
        e.filter(el => {
          return el.status == -1;
        }).length > 0;
    },
    getUserList(type, cat = null) {
      const myError = message => {
        return { message, type: 1 };
      };

      if (type === undefined || type === null) type = 0;

      //let taskUser = this.taskUserConfig.find((el) => {        return el.isFurniture == type;      }).user_id;
      let taskUser = this.categoryList.find(c => c.id == cat).manager_id;
      let watchers = this.taskUserConfig.find(el => {
        return el.isFurniture == type;
      }).watchers;
      let taskWatchers = [];
      try {
        if (watchers?.inRole?.length) {
          for (const role of watchers.inRole) {
            let users = this.data.object_access.filter(a => {
              return a.user_role == role /* && a.user_id !== user_id */;
            });
            if (!users.length) throw myError("Не определен сотрудник с ролью '" + (this.$root.roles.find(r => r.value == role)?.text || "error") + "' на объекте.");

            taskWatchers = [...taskWatchers, ...users];
          }
        }
      } catch (error) {
        console.log(error);
        this.$root.$emit("show-info", {
          text: +error.type ? error.message : "Возникла ошибка....",
          type: "error",
        });
        return false;
      }
      return { user: taskUser, watchers: taskWatchers };
    },
    async getCategoryList() {
      this.categoryList = null;
      let d = await this.$axios.get("accounting/dir/service_category", {
        params: {
          filters: {
            type: 2,
            OR: [
              { field: "status", condition: "<>", value: 2 },
              { field: "status", condition: "IS", value: null },
            ],
          },
        },
      });
      if (d.data.status == "ok") {
        this.categoryList = d.data.data;
        let res = d.data.data
          .filter(c => {
            return c?.data?.buying_rules && c.data.buying_rules.find(r => r.type == 2);
          })
          .map(c => {
            return { id: c.id, rule: c.data.buying_rules.find(r => r.type == 2) };
          });
        let cat = {};
        res.forEach(c => {
          cat[c.id] = c.rule;
        });
        return cat;
      }
      return false;
    },
    setError(e) {
      this.$root.$emit("show-info", {
        text: e,
        type: "error",
      });
    },
    async getObjectWork(object_id, service_id) {
      if (!this.objectWork?.[object_id]) {
        try {
          let resp = await this.$axios.post("/report_sql", { name: "getWorksOnObject", params: { service_id, object_id } });
          if (resp.data.data) {
            return resp.data.data[0];
          }
        } catch (error) {
          this.$root.$emit("show-info", { type: "error", text: "Произошла ошибка. Получение работ на объекте" });
          console.error(error);
        }
      }
      return false;
    },
    async makeTasks() {
      let categories = await this.getCategoryList();
      let services = this.$root.dirs.service;
      if (!this.categoryList || !categories) {
        this.setError("Ошибка получения списка категорий!");
        return false;
      }
      const myError = message => {
        return { message, type: 1 };
      };

      let ok = false;
      let rows = [];
      for (let row of this.$refs.table.data.filter(el => {
        return el?.checked;
      })) {
        rows.push(row.id);
      }
      if (!rows.length) {
        this.setError("Ничего не выбранно!");
        return false;
      }
      ok = await this.$refs.confirmDialog.show({
        title: "Создание задач",
        message: [`Вы действительно хотите создать задачи ?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;

      let task_id = 0;
      const d = await this.$axios.get("/mechti/construction-goods", {
        params: { filters: { status: "-1", object_id: this.id } },
      });
      try {
        if (d.data && d.data.data) {
          let newR = d.data.data.filter(el => {
            return rows.includes(el.id);
          }); //.filter(el=>{if () });
          if (!newR.length) {
            this.setError("Не выбранно ни одной подходящей записи");
            return false;
          }
          this.isMakeTasks = true;
          for (let el of newR) {
            task_id = null;
            console.log("create task for ", el);
            if (!el.category_id) {
              throw myError(`Для  "${el.name}"  не указана категория товара`);
            }
            const rule = categories[el.category_id];
            if (!rule) {
              throw myError(`Для  "${el.name}"  не настроены сроки покупки`);
            }
            const work = await this.getObjectWork(el.object_id, rule.service_id);
            if (!work) throw myError(`Для  "${el.name}"  ошибка с связанной работой`);
            if (!work.date_start) throw myError(`Для  "${el.name}" нет связанной работы ${work?.name || "????"}`);

            let date_buy = new Date(work.date_start).addDays(-rule.value);
            let taskUsers = await this.getUserList(el.data.isFurniture, el.category_id);
            if (!taskUsers?.user || !taskUsers) {
              console.log("user undefined");
              this.isMakeTasks = false;
              throw myError(`Для  "${el.name}"  не определен сотрудник снабжения`);
            }
            let data = {
              parent_name: "construction-goods",
              parent_id: el.id,
              type: "construction-goods",
              name: el.name,
              date_start: date_buy.addDays(-1).date,
              date_end: date_buy.addDays(1).date,
              status: 200,
              is_important: 2,
              user_id: taskUsers.user,
              watchers: taskUsers.watchers,
              object_id: el.object_id,
              params: {
                parent: "construction-goods",
                parent_id: el.id,
              },
            };
            //console.log("post data", data);
            let dataGoods = { id: el.id, status: -3 };
            if (true) {
              let response = await this.$axios.post("/mechti/construction-goods", dataGoods);
              if (response.data.status == "ok") {
                response = null;
                response = await this.$axios.post("/mechti/buying", data);
                let status = response.data.status == "ok";
                task_id = response.data.data.id;
                if (status) {
                  dataGoods = { id: el.id, task_id, status: 0 };
                  response = null;
                  response = await this.$axios.post("/mechti/construction-goods", dataGoods);
                  if (response.data.status == "ok") {
                  } else {
                    throw myError("Ошибка привязки товара с задачей");
                  }
                } else {
                  throw myError("Ошибка создания задачи");
                }
              } else {
                throw myError("Ошибка подготовки задачи");
              }
            }
          }
          this.$root.$emit("show-info", {
            text: "Задачи созданы.",
          });
        }
      } catch (error) {
        console.log(error);
        this.setError(+error.type ? error.message : "Возникла ошибка....");

        if (task_id) {
          await this.$axios({
            method: "delete",
            url: "/mechti/buying" + "/" + task_id,
          });
        }
      }
      this.$refs.table.updateData();
      this.isMakeTasks = false;
    },
  },
};
</script>
