<template>
  <div v-if="info.length > 0" class="mr-3">
    <v-menu bottom offset-y transition="scale-transition" origin="center center">
      <template v-slot:activator="{ on, attrs }">
        <v-icon large v-bind="attrs" v-on="on">mdi-school-outline</v-icon>
      </template>
      <v-list>
        <v-list-item v-for="el in info" :key="el.id" @click="onClickInfo(el.id)">
          <v-list-item-title> {{ el.name }} </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <portal to="v-main">
      <InfoView v-if="showEditDialog" v-model="showEditDialog" :id="idInfo" old />
    </portal>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  components: {
    InfoView: () => import("@/views/dialogs/infoViewDialog"),
  },
  props: {
    name: String,
  },
  data() {
    return {
      info: [],
      idInfo: 0,
      showEditDialog: false,
    };
  },
  destoyed() {},
  watch: {
    name(v) {
      if (v) {
        this.getInfo();
      }
    },
  },
  created() {
    this.getInfo();
  },
  methods: {
    onClickInfo(id) {
      this.idInfo = id;
      this.showEditDialog = true;
    },
    async getInfo() {
      const name2 = _.camelCase(this.name);
      let resp;
      resp = await this.$axios.post("/report_sql", {
        name: "getInformationMenuList",
        params: { role: this.$root.profile.role, name1: `"${this.name}"`, name2: `"${name2}"` },
      });

      if (resp.data.status == "ok") {
        this.info = resp.data.data || [];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
