<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" maxWidth="1300px">
      <template v-slot:title>
        {{ data.name ? data.name : "Новый" }}
        <small v-if="id"> ({{ id }})</small>
      </template>

      <v-row class="mt-3" id="dddddd">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true }"
              @validate="validate($event)"
              @fieldData="getLink($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn v-if="id" @click="showTests = true">Вопросы</v-btn>

        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <test-dialog v-if="id" v-model="showTests" :id="id" @save="saveTest($event)" :data="data" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, genModel } from "@/components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, genModel],
  components: {
    testDialog: () => import("./infoTestDialog"),
  },
  props: {
    value: Boolean,
    id: Number,
  },
  data() {
    return {
      mo1del: this.$store.getters["config/get"].models.info.form,
      model1: [],
      modelForm: null,
      m: this.$store.getters["config/get"].models.info,
      api: "/mechti/information",
      removeDialogShow: false,
      showTests: false,
    };
  },
  computed: {
    config() {
      return this.m.config.form;
    },
    model() {
      return this.calcModelConfig(this.config);
    },
  },
  created() {
    this.updateData(this.id);
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    validate1(e) {
      console.log("validate", e);
      this.validate(e);
    },
    getLink(e) {
      console.log("getLink", e);
      const text = `<div class="video-container" style="width: 100%;"><video controls playsinline style="width: 100%; height: auto; display: block; margin: 0 auto" crossorigin="anonymous"><source src="${this.$root.config.baseUrl}/file/download/${e.value.file}" type="video/mp4" crossorigin="anonymous" /></video></div>`;
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$root.$emit("show-info", {
            text: " Скопировано в буфер обмена!",
          });
        })
        .catch((error) => {
          console.error("Не удалось скопировать: ", error);
        });
    },
    afterFetchData1() {
      this.model1 = this.calcModelConfig(this.config);
      console.log(this.model, this.modelForm);
    },
    saveTest(e) {
      let data = { id: this.id, test_data: e };
      this.post(data, this.api);
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      // if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
