<template>
  <static-fullscreen-card>
    <template v-slot:title>
      <v-row class="ma-1">
        <v-btn-toggle v-model="period" mandatory borderless dense @change="onChangePeriod" class="ml-1">
          <v-btn value="1W">
            <span class1="hidden-md-and-down">Неделя</span>
          </v-btn>
          <v-btn value="1M" class="mx-1">
            <span class1="hidden-md-and-down">Месяц</span>
          </v-btn>
          <v-btn value="select" @click="showConfig = true">
            <span class1="hidden-md-and-down">Диапазон</span>
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </template>
    <template v-slot:actions>
      <a-btn-refresh @click="$refs.table.updateData()" />
    </template>
    <portal to="v-main">
      <s-quick-config-dialog v-if="showConfig" v-model="showConfig" :values="{ ds: dateStart, de: dateEnd }" :model="modelConfig" @apply="onSetPeriod($event)" />
    </portal>
    <a-table-f-api-post
      ref="table"
      :apiPost="{ api: '/report_sql/filters', params: paramsApi }"
      :limit="50"
      :model="getModelList(m)"
      :useQuery="false"
      :defaults="defaults"
      :selectedRows="selectedRows"
      @click="
        counter += 1;
        onDetectClick($event);
      "
      @loadData="onLoadData"
    >
      <template v-slot:footer>
        <div class="f-table-footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px" v-if="$refs.table">Показано: {{ $refs.table.pager.count }}, На сумму: {{ (total_price || 0).toLocaleString() }}</div>
          </div>
        </div>
      </template>
    </a-table-f-api-post>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, genModel, doubleClickDetect, keyDetect, popupMenu } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect, popupMenu],
  components: {},
  data() {
    return {
      total_price: null,
      showConfig: false,
      modelConfig: [
        { name: "datePeriod", title: "Период", type: "daterange", dates: ["ds", "de"], calculated: true },
        { name: "ds", title: "Период", type: "date", hidden: true },
        { name: "de", title: "Период", type: "date", hidden: true },
      ],
      editField: null,
      dateStart: new Date().date,
      dateEnd: new Date().addDays(7).date,
      period: "1W",
      m: this.$store.getters["config/get"].models.futurePyament,

      // url: "/mechti/invite_registration",
    };
  },
  created() {
    this.$root.title = this.title;
  },
  computed: {
    title() {
      let t = this.m.title + ` с ${this.dateStart} по ${this.dateEnd}`;
      this.$root.title = t;
      return t;
    },
    paramsApi() {
      return { name: "getFuturePaymentList", params: { ds: this.dateStart, de: this.dateEnd } };
    },
    defaults() {
      return {
        sort: { key: "date", order: "ASC" },
        filters: {},
        pager: { page: 0, limit: 50 },
        paramName: "futurePayments",
      };
    },
    configHead() {
      return this.m.config.default || {};
    },
  },
  watch: {},
  methods: {
    onLoadData(e) {
      this.total_price = e.reduce((total, curr) => total + parseFloat(curr.value), 0).toFixed(2);
    },
    setTitle() {
      this.$root.title = this.title;
    },
    onSetPeriod(e) {
      console.log(e);
      this.dateStart = new Date(e.ds).date;
      this.dateEnd = new Date(e.de).date;
      this.setTitle();
    },
    async onChangePeriod(e) {
      if (e == "1W") {
        this.dateStart = new Date().date;
        this.dateEnd = new Date().addDays(7).date;
      }
      if (e == "1M") {
        this.dateStart = new Date().date;
        this.dateEnd = new Date().addDays(30).date;
      }
      if (e == "select") {
        return;
        console.log(res);
        this.paramsApi.params.ds = new Date().date;
        this.paramsApi.params.de = new Date().addDays(30).date;
      }
      this.setTitle();

      this.$refs.table.updateData();
    },

    onSingleClick(d) {
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    onDoubleClick(d) {
      this.onClickRow(d);
    },
    onClickRow(d) {},
  },
};
</script>
