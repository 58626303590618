<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')" :maxWidth="dialogWidth">
      <template v-slot:title>
        {{ data.id ? data.name : "Новая запись в справочнике [" + dir.text + "]" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row v-if="modelForm">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="pb-0">
          <a-form-model
            v-model="data"
            :model="getFormModel([el.name], model)"
            :readonly="!canEdit"
            :config="defaults"
            :errors="errors"
            @validate="
              validate($event);
              doWatch($event);
            "
            @fieldData="setFieldData($event)"
          />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :disabled="!(canEdit || !id)"></a-btn-save>
        <a-btn-delete v-if="id" @click="removeDialogShow = true" :disabled="!canDelete"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-if="canDelete" v-model="removeDialogShow" @remove="remove(api, id)" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, keyDetect, getAccess, genModel } from "@/components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, keyDetect, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    defaults: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    dir: {
      type: Object,
      default: () => {},
    },
    api: String,
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.dirs,
      removeDialogShow: false,
      modelForm: null,
      watchField: null,
      watchValue: null,
      needCalcModel: 1,
      afterSaveAction: null,
      suEditShow: false,
      model: [],
      id0: true,
    };
  },
  computed: {
    dialogWidth() {
      return (this.dir?.dialogWidth || 800) + "px";
    },
    model_() {
      console.log("calc model", this.needCalcModel);
      if (!this.needCalcModel) return {};
      const name = this.dir?.name || null;
      if (!name) return {};
      let m = JSON.parse(JSON.stringify(this.m[name]));
      this.afterSaveAction = m?.afterSave || null;
      let model = this.getModelList(m, "form", true);
      let fieldsForm = [];
      let modelForm = [];
      model.forEach(el => {
        if (!this.id && el?.hiddenCreate) {
          el.hidden = true;
        }
        fieldsForm.push(el.name);
      });
      this.watchField = null;
      if (m.config) {
        const conf = m.config || [];
        const f = conf.configField;
        let config;
        let def = conf.default;
        if (f) {
          this.watchField = f;
          config = (conf?.config || []).find(el => {
            if (Array.isArray(el.value)) return el.value.includes(this.data[f]);
            return el.value == this.data[f];
          });
          if (config === undefined) config = def;
        } else {
          config = def;
        }
        const fields = config?.fields ? config.fields.split(",") : [];
        fieldsForm = config?.fieldsForm ? config.fieldsForm.split(",") : fieldsForm;
        if (fields.length) {
          this.fillFormValidators(model.filter(el => fields.includes(el.name)));

          // model = model.filter((el) => fields.includes(el.name));
        }
      }
      fieldsForm.forEach(field => {
        let s = field.split("#");
        modelForm.push({ name: s[0], size: s[1] || 12 });
      });
      this.modelForm = modelForm;
      return model;
    },
    canEdit() {
      let res =
        this.getAccess("dirs." + this.dir.name + ".edit") ||
        (this.getAccess("dirs." + this.dir.name + ".create") && (this.data.createdby_id == this.$root.profile.id || !this.id));
      if (!res) {
        if ((new Date() - new Date(this.data.createdon)) / 1000 / 60 < 15) res = true;
      }
      return res;
    },
    canDelete() {
      let res = this.getAccess("dirs." + this.dir.name + ".delete");
      return res;
    },
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        if (!this.watchField) return;
        if (newVal[this.watchField] !== oldVal[this.watchField]) {
          this.getModel();
          this.needCalcModel++;
          return;
        }
        if (newVal[this.watchField] !== this.watchValue) {
          this.getModel();
          this.watchValue = newVal[this.watchField];
          this.needCalcModel++;
        }
      },
      deep: true,
    },
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  created() {
    this.updateData(this.id);
    if (!this.id) {
      ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  methods: {
    doWatch(e) {
      if (e == this.m[this.dir.name]?.config?.watch) this.getModel(this.data?.[e]);
      //console.log("doWatch", e, this.config, this.model);
    },

    getModel(t) {
      // if (!this.needCalcModel) return {};
      const name = this.dir?.name || null;
      if (!name) return {};
      let m = JSON.parse(JSON.stringify(this.m[name]));
      this.modelForm = null;
      this.model = [];
      this.afterSaveAction = m?.afterSave || null;
      let model = this.getModelList(m, "form", true);
      let fieldsForm = [];
      let modelForm = [];
      model.forEach(el => {
        if (!this.id && el?.hiddenCreate) {
          el.hidden = true;
        }
        fieldsForm.push(el.name);
      });
      this.watchModel = null;
      this.watchField = null;
      if (m.config) {
        const conf = m.config || [];
        const f = conf.configField;
        let config;
        let def = conf.default;
        if (f) {
          this.watchField = f;
          config = (conf?.config || []).find(el => {
            if (Array.isArray(el.value)) return el.value.includes(this.data[f]);
            return el.value == this.data[f];
          });
          if (config === undefined) config = def;
        } else {
          config = def;
        }
        this.watchModel = config?.watch || null;
        const fields = config?.fields ? config.fields.split(",") : [];
        fieldsForm = config?.fieldsForm ? config.fieldsForm.split(",") : fieldsForm;
        if (fields.length) {
          this.fillFormValidators(model.filter(el => fields.includes(el.name)));

          // model = model.filter((el) => fields.includes(el.name));
        }
      }
      fieldsForm.forEach(field => {
        let s = field.split("#");
        modelForm.push({ name: s[0], size: s[1] || 12 });
      });
      this.fillFormFromModel(model);
      this.model = [...model];
      this.modelForm = [...modelForm];
    },
    async submit() {
      if (await this.validateAll(this.data)) {
        //console.log('save', this.data);
        await this.post(this.data, this.api);
        return true;
      } else if (this.keys?.ctrl && this.$root.profile.role === 1000) {
        await this.post(this.data, this.api);
        return true;
      }

      return false;
    },
    afterFetchData() {
      this.needCalcModel++;
      this.getModel();
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$store.dispatch("dirs/fitchDirs", this.dir.name);
      this.$emit("input");
    },
    afterSave(data, status) {
      if (this.afterSaveAction === "none") return;
      this.$store.dispatch("dirs/fitchDirs", this.dir.name);
      if (status) this.$emit("input");
    },
  },
};
</script>
