<template>
  <div class="kanban" :style="`height: ${height}px`">
    <confirm-dialog ref="confirmDialog"></confirm-dialog>

    <template v-if="!loading">
      <div v-for="(column, index) in kanbanColumns" :key="index" :style="columnStyle(column)" class="kanban__column">
        <div class="kanban__header mb-0" :class="{ drop__allow: column.canDrop && dragging, drop__deny: !column.canDrop && dragging }">
          <div class="kanban__description" @click="sortColumn(column.id)">
            <span v-if="$slots[`icon-${column.id}`]" class="kanban__icon">
              <slot :name="`icon-${column.id}`" />
            </span>
            <h3 class="kanban__title">{{ column.name }}</h3>
            <template>
              <span v-if="sortOrder[column.id] == 0"> <i class="fas fa-sort"></i></span>
              <span v-else class="arrow"> <i class="fas" :class="sortOrder[column.id] == 1 ? 'fa-sort-down' : 'fa-sort-up'"></i></span>
            </template>
          </div>
          <a-input-table-search-string v-if="false" :value="columnFilter[column.id]" @change="onInputFilter($event, column)" style="width: 100%" />
          <p v-if="!innerColCount && column && column.columnItems" class="kanban__quantity mb-0">
            {{ columnFilter[column.id] ? `${getColumn(column).length} из` : "" }} {{ column.columnItems.length }} {{ countText }}
          </p>
        </div>
        <div class="kanban__content" :style="contentStyle">
          <p v-if="innerColCount" :class="['kanban__quantity', 'kanban__quantity--inner-count']">{{ column.columnItems.length }} {{ countText }}</p>
          <Container
            :data-index="index"
            group-name1="kanban"
            :get-child-payload="event => getDraggedItem(event, index)"
            :should-accept-drop="(src, payload) => validation1(src, payload, index, column)"
            @drop="onDrop(index, $event, column)"
            @drag-start="onDragStart"
            @drop-not-allowed="dropNotAllowed"
            @drag-enter="onDropEnter(column)"
          >
            <Draggable v-for="(item, indice) in getColumn(column)" :key="`${indice}-${item.id}`"> <slot name="card" :item="item" /> </Draggable>

            <div class="kanban__move-icon">
              <span>{{ dropText }}</span>
            </div>
          </Container>
        </div>
      </div>
    </template>
    <div v-else class="kanban__skeleton-container">
      <slot name="skeleton" />
      <div v-if="!$slots.skeleton" class="kanban__skeleton">
        <div
          v-for="(i, index) in colSkeleton"
          :key="i"
          class="kanban__skeleton-column"
          :animation-data="index"
          :style="`min-width: ${colMinWidth || 300}px; max-width: ${colMaxWidth || 330}px`"
        >
          <slot name="skeletonCard" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-dndrop";
export default {
  name: "Kanban",
  components: { Container, Draggable },
  props: {
    height: { type: Number, default: 400 },
    loading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    colWidth: {
      type: [Number, String],
      default: () => "",
    },
    colMinWidth: {
      type: [Number, String],
      default: () => "",
    },
    colMaxWidth: {
      type: [Number, String],
      default: () => "",
    },
    dropText: {
      type: String,
      default: () => "Drop here",
    },
    dropIcon: {
      type: String,
      default: () => "copy",
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    innerColCount: {
      type: Boolean,
      default: false,
    },
    colBgColor: {
      type: String,
      default: () => "",
    },
    countText: {
      type: String,
      default: () => "items",
    },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      sourceContainerIndex: null,
      kanbanColumns: [],
      columnFilter: {},
      items_: [],
      sortOrder: {},
      dragging: false,
      payload: false,
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
    };
  },
  computed: {
    colSkeleton() {
      const screenSize = window.innerWidth - 300;
      const quantity = screenSize / (this.colMinWidth || 180);
      return Math.ceil(quantity);
    },
    contentStyle() {
      return `background-color: ${this.colBgColor}`;
    },
  },
  watch: {
    columns(v) {
      //console.log("cccccccccccc", v);
      // this.kanbanColumns = JSON.parse(JSON.stringify(v));
      this.kanbanColumns = v;
      this.kanbanColumns.forEach((c, i) => {
        if (this.sortOrder?.[c.id] === undefined) {
          this.$set(this.sortOrder, c.id, 1);
        }
        c.idx = i;

        // if (this.columnFilter?.[c.id] === undefined) this.$set(this.columnFilter, c.id, "");
        c["canDrop"] = false;
      });
      this.items_ = v;
      //  console.log("iiiiiiii", this.items_);
    },
  },
  methods: {
    onInputFilter(e, c) {
      this.columnFilter[c.id] = e;
    },
    getSortOrder(i) {
      return this.sortOrder[i];
    },
    sortColumn(i) {
      let sort = this.sortOrder?.[i] || 0;
      if (sort === 0) {
        sort = 1;
      } else if (sort === 1) {
        sort = -1;
      } else {
        sort = 0;
      }
      this.sortOrder[i] = sort;
    },
    getColumn(c) {
      //let items = (this.items_[c.id]?.columnItems || []).filter(
      let items = [...(this.kanbanColumns[c.idx]?.columnItems || [])].filter(
        el =>
          !this.columnFilter[c.id] ||
          el.name.toLowerCase().includes(this.columnFilter[c.id].toLowerCase()) ||
          el.id == this.columnFilter[c.id] ||
          (el?.phone1 || "").includes(this.columnFilter[c.id]) ||
          (el?.source || "").toLowerCase() == this.columnFilter[c.id].toLowerCase(),
      );
      let field = "id";
      let sort = this.sortOrder?.[c.id] || 0;
      let res = items;
      if (!false)
        if (sort === -1) {
          // Сортировка по возрастанию id
          res = items.sort((a, b) => a[field] - b[field]);
        } else if (sort === 1) {
          res = items.sort((a, b) => b[field] - a[field]);
        } else {
          res = items;
        }
      return res;
    },
    onDragStart(index) {
      const { isSource, payload, willAcceptDrop } = index;
      if (isSource) this.payload = payload;
      //  this.$emit("drag");
      this.dragging = true;
    },
    onDropEnter(column) {
      // this.$emit("selected-column", column);
    },
    columnStyle(column) {
      return `width: ${this.colWidth}px;
          min-width: ${this.colMinWidth}px;
          max-width: ${this.colMaxWidth}px;
          border-color: ${column.color};
          `;
    },

    validation1(src, payload, i, c) {
      if (this.readonly) return false;
      let s = this.kanbanColumns.find(k => k.status == payload.status) || {};
      try {
        //this.kanbanColumns.find(k => k.id == c.id)["canDrop"] = payload.next.includes(c.id);
        //if (c.status == s.id) console.log("validation", { src, payload, i, c });
        this.kanbanColumns.find(k => k.id == s.id)["canDrop"] = (s?.next || []).includes(s.id);
        this.kanbanColumns.find(k => k.id == c.id)["canDrop"] = (s?.next || []).includes(c.id);
        //console.log(c.id, (s?.next || []).includes(c.id));
      } catch (error) {}
      return (s?.next || []).includes(c.id);
      return payload.next.includes(c.id);
      const isSource = index === this.sourceContainerIndex;

      return isSource || validator(src, payload, index);
    },

    async onDrop(index, dropResult, column) {
      //console.log("onDrop", index, dropResult, column);
      //this.kanbanColumns[index].columnItems = this.dropAction(this.kanbanColumns[index].columnItems, dropResult, column);
      this.kanbanColumns[index].columnItems = this.dropAction(this.getColumn(this.kanbanColumns[index]), dropResult, column);
      this.dragging = !true;
      this.payload = null;
    },
    dropAction(arr, dragResult, column) {
      const { removedIndex, addedIndex, payload } = dragResult;
      const { status } = column;
      if (removedIndex === null && addedIndex === null) return arr;
      if (removedIndex !== null && addedIndex !== null) return arr;
      // console.log("dropAction", dragResult, column, arr);
      const items = [...arr];
      let itemToAdd = payload;
      if (removedIndex !== null) {
        //const idx = items.findIndex(a => a.id == this.payload.id);
        //itemToAdd = items.splice(removedIndex, 1)[0];
        ///items.splice(removedIndex, 1)[0];
        items.splice(removedIndex, 1)[0];
        //console.log("remove", removedIndex, payload.id);
        //console.log("remove", items[removedIndex].id);
      }
      if (addedIndex !== null) {
        items.splice(addedIndex, 0, itemToAdd);
        //console.log("add", addedIndex);
        //console.log("add", payload.id);

        //console.log(payload.status, status);
        if (payload.status !== status)
          this.$emit("status-change", {
            item: payload,
            status: status,
          });
      }
      return items;
    },
    getDraggedItem(item, indice, prop) {
      //  console.log("getDraggedItem");
      //this.sourceContainerIndex = indice;
      return this.getColumn(this.kanbanColumns[indice])[item];
      return this.kanbanColumns[indice].columnItems[item];
    },
    dropNotAllowed({ payload, container }) {
      this.dragging = !true;
      //     console.log("drop not allowed", payload);
    },
  },
};
</script>
<style scoped>
.kanban {
  display: flex;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  user-select: none;
}
.kanban__move-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 0.9rem;
  color: #b0b0b0;
  user-select: none;
}
.kanban__move-icon span {
  margin-left: 10px;
}
.kanban__move-icon svg {
  width: 20px;
  height: 20px;
}
.kanban__drop-preview {
  position: relative;
  margin: 10px;
  background-color: #edf1f3;
  border: 2px dashed rgba(0, 0, 0, 0.059);
  border-radius: 4px;
}
.kanban__drop-preview::before {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #b0b0b0;
  transform: translate(-50%, -50%);
}
.kanban__drop-preview::after {
  position: absolute;
  top: 50%;
  left: 27%;
  width: 20px;
  height: 20px;
  content: "";
  transform: translate(-50%, -50%);
}
.kanban__description {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.kanban__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf5f7;
  padding: 0.5rem;
  border-radius: 8px;
}
.kanban__title {
  margin: 0 0 0 5px;
  font-size: 1rem;
  font-weight: bold;
  color: #555;
}
.kanban__quantity {
  margin-top: 0;
  font-size: small;
  color: #b0b0b0;
  text-align: right;
}
.kanban__quantity--inner-count {
  margin: 1rem 1rem 0 1rem;
  padding-top: 0.5rem;
}
.kanban__column {
  flex: 1;
  height: fit-content;
  padding: 6px;
  border-radius: 6px;
  transition: height 0.5s ease;
  border: 1px solid #e0e0e0;
}
.kanban__column:not(:first-of-type) {
  margin-left: 15px;
}
.kanban__column--no-border {
  border: none;
}
.kanban__header {
  margin-bottom: 15px;
  background-color: #fff;
}
.kanban__content {
  position: relative;
}
.kanban__skeleton {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-right: 15px;
  background-color: #f8f8f8;
  border-radius: 4px;
  overflow: auto;
}
.kanban__skeleton-container {
  width: 100%;
}
.kanban__skeleton-column {
  flex: 1;
  height: calc(100% - 30px);
  padding: 10px;
  margin-left: 15px;
  background-color: #e9e9e9;
  border-radius: 6px;
}
.kanban__skeleton-card {
  position: relative;
  height: 100px;
  padding: 15px;
  margin-top: 16px;
  background-color: #f5f5f5;
  border-radius: 6px;
}
.kanban__skeleton-card--one {
  position: absolute;
  top: 8px;
}
.kanban__skeleton-card--two {
  position: absolute;
  top: 8px;
  right: 10px;
}
.kanban__skeleton-card--three {
  position: absolute;
  top: 30px;
}
.kanban__skeleton-card--four {
  position: absolute;
  bottom: 2rem;
}
.kanban__skeleton-card--five {
  position: absolute;
  left: 1rem;
  bottom: 0.5rem;
}
.item,
.draggable-item,
.draggable-item-horizontal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 4px;
  cursor: pointer;
  user-select: none;
  background-color: white;
  border: 1px solid transparent;
  border-left: 5px solid #e0e0e0;
  border-radius: 4px;
  transition: border-color 0.2s linear;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
}
.item .card {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.item .card small {
  color: #838383;
}
.item.open {
  border-left: 5px solid #f3c1c1;
}
.item.executing {
  border-left: 5px solid #c4ebaf;
}
.item.finished {
  border-left: 5px solid #b3d5e9;
}
.item.paralised {
  border-left: 5px solid #dc3545;
}
.item.review {
  border-left: 5px solid #e0e0e0;
}
.badge {
  margin: 1rem 0;
  cursor: pointer;
}
.label {
  color: #838383;
}
.kanban-action {
  display: flex;
  justify-content: space-between;
}
.kanban-button {
  max-width: 130px;
  pointer-events: none;
  border: none;
  color: #f7f7f7;
  padding: 1px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
.kanban-button.open {
  background-color: #f3c1c1;
}
.kanban-button.executing {
  background-color: #c4ebaf;
}
.kanban-button.finished {
  background-color: #b3d5e9;
}
.kanban-button.paralised {
  background-color: #dc3545;
}
.kanban-button.review {
  background-color: #e0e0e0;
}

.kanban {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.kanban__column {
  display: flex;
  flex-direction: column;
  height: 100%; /* Колонка занимает всю высоту */
}

.kanban__header {
  position: sticky; /* Фиксируем заголовок */
  top: 0; /* Закрепляем его в верхней части колонки */
  background: #fff; /* Задаём фон для визуальной четкости */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Тень для отделения */
}
.kanban__header.drop__allow {
  background: hsl(157, 90%, 48%); /* Задаём фон для визуальной четкости */
}
.kanban__header.drop__deny {
  background: hsl(17, 94%, 69%); /* Задаём фон для визуальной четкости */
}
.kanban__content {
  flex: 1; /* Контент растягивается на оставшуюся высоту */
  scrollbar-width: thin; /* Для Firefox */
  overflow-y: auto; /* Добавляем прокрутку, если контента больше, чем доступная высота */
  scrollbar-color: #888 #f0f0f0; /* Цвет полосы и фона */
}

/* Для WebKit-браузеров (Chrome, Edge, Safari) */
.kanban__content::-webkit-scrollbar {
  width: 6px; /* Ширина полосы прокрутки */
}
.kanban__content::-webkit-scrollbar-track {
  background: #f0f0f0; /* Цвет фона трека */
}

.kanban__content::-webkit-scrollbar-thumb {
  background-color: #888; /* Цвет бегунка */
  border-radius: 10px; /* Закругленные края */
  border: 1px solid #ddd; /* Обводка для бегунка (опционально) */
}
/* end of kanban  */
</style>
