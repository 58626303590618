<template>
  <div>
    <v-simple-table :dense="config.dense || false" small>
      <tbody>
        <tr v-for="(el, i) in modelC" :key="i" v-if="el.type && el.hidden !== false">
          <td class="text-left" :style="`font-weight: 500; height: ${config.size || 38}px`">{{ el.title }}:</td>
          <td class="text-left px-0" :style="`height: ${config.size || 38}px`" @click="$emit('click', { event: $event, field: el, row: data })">
            <component
              v-if="el.type"
              :is="'a-view-' + el.type"
              :model="el"
              :values="data"
              :value="el.value || getValue(el)"
              @onEdit="$emit('onEdit', { field: el.name, value: $event })"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    model: Array,
    config: {
      type: Object,
      default: () => {
        return { dense: false, hideDetails: false, hideNull: false };
      },
    },
  },
  data() {
    return {
      mode: "view",
      m: [],
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
    },
    modelC() {
      let m = [];
      if (this.model) {
        m = JSON.parse(JSON.stringify(this.model));
        m = m.filter(el => {
          return el?.type || false;
        });
        m.forEach(el => {
          if (el.type && el.type == "comboboxapi") {
            el.type = "string";
          }
          if (el.type && el.type == "datestring") {
            el.type = "date";
          }
          if (this.config.hideNull && !el?.showNull)
            if (!this.getValue(el) && !this.data[el.name]) {
              el.hide = true;
            }
          if (el?.apiVar) {
            //устанавливаю значение title
            const key = el.apiVar.key;
            if (this.value) {
              const v = el?.json ? this.value?.data?.[key] : this.value[key];
              const name = el.apiVar.apiName.find(el => {
                return el.value == v;
              });
              el.title = name?.title || el.title || null;
            }
          }
          if (el?.dirVar) {
            //устанавливаю значение title
            const key = el.dirVar.key;
            if (this.value) {
              const v = el?.json ? this.value?.data?.[key] : this.value[key];
              const name = el.dirVar.dirName.find(el => {
                return el.value == v;
              });
              el.title = name?.title || el.title || null;
            }
          }
        });
      }
      m = m.filter(el => {
        return !el?.hide;
      });
      return m;
    },
  },
  created() {},
  watch: {},
  methods: {
    getValue(el) {
      let name = el.name;
      //let res = Object.assign({}, this.data);
      let res = this.data;
      name.split(".").forEach(n => {
        if (res) {
          res = res?.[n];
        }
      });
      return res;
    },
  },
};
</script>
