var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('dialog-edit',{attrs:{"value":_vm.value,"maxWidth":'100hv'},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Выбор позиций, ожидающих приёмки "+_vm._s(_vm.getAccess("documents.goodsIncome.fullIncomeList") ? "[Полный доступ]" : "[Не полный доступ]")+" ")]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),(_vm.getAccess('documents.goodsIncome.create'))?_c('a-btn-save',{attrs:{"title":'Принять выбранное'},on:{"click":function($event){return _vm.add()}}}):_vm._e(),_c('v-btn',{on:{"click":function($event){return _vm.$emit('input')}}},[_vm._v("Закрыть")])]},proxy:true}])},[_c('v-card-text',{staticClass:"pa-0",staticStyle:{"height":"80vh","overflow":"auto"}},[_c('a-table-f-api-post',{ref:"table",attrs:{"apiPost":{ api: '/report_sql/filters', params: _vm.paramsApi },"limit1":50,"model":_vm.model,"useQuery":false,"defaults":{
          paramName: 'goods.goodsIncome',
          pager: { limit: 50, page: 0 },
          sort: [
            { key: 'is_date', order: 'ASC' },
            { key: 'date_before', order: 'ASC' },
            { key: 'date_doc', order: 'ASC' },
            { key: 'id', order: 'ASC' },
          ],
        }},on:{"click":_vm.clickRow}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }