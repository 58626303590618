<template>
  <div>
    <v-tabs-items v-model="tabProps" class="transparent" touchless>
      <v-tab-item :id="`tabProps-edit`">
        <s-props-edit v-model="dialogProps" :id="idEdit" :object_id="model.id" :type="model.type" :defs="defs" :object_data="objectData" />
      </v-tab-item>
      <v-tab-item :id="`tabProps-view`">
        <div>
          <v-card-title class="pt-0">
            {{ model.title }}
            <v-spacer></v-spacer>
            <div class="text-right">
              <a-btn-add v-if="!readonly" @click="copy()" :title="'Импорт'" :icon="'fas fa-file-import'" color="primary" />
              <v-btn v-if="!readonly" @click="add()" color="primary" class="ml-1"> Добавить </v-btn>
              <v-btn @click="preview()" color="primary" class="ml-1">
                <v-icon>fas fa-eye</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-expansion-panels focusable>
              <v-expansion-panel v-for="group in props" :key="group.id">
                <v-expansion-panel-header> {{ group.name }} </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="prop in getProps(group.id)" :key="prop.id">
                    <s-props-item :prop="prop" @onClick="edit(prop.id)" @onClickAdd="addProp(prop)" :readonly="readonly" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <s-props-copy v-model="showCopyDialog" :id="model.id" :type="model.type" @save="importProps($event)" />
  </div>
</template>

<script>
export default {
  props: {
    value: String | Array,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    objectData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: { type: String, default: "" },
  },
  data() {
    return {
      data: [],
      idEdit: 0,
      props: [],
      defs: {},
      dialogProps: false,
      showCopyDialog: false,
    };
  },
  computed: {
    tabProps() {
      return this.dialogProps ? `tabProps-edit` : `tabProps-view`;
    },
    readonly() {
      let res = this.config?.readonly;
      return res;
    },

    items: {
      get() {
        let d;
        let f = this.model.category || null;
        try {
          d = this.values.filesData.filter(el => {
            if (this.model?.newOnly && el?.type !== "add") return false;
            return f == null || el.category_id == f;
          });
          //d = this.value;
          d.forEach(el => {
            el.type = "old";
          });
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        console.log(v);
        this.$emit("input", v);
      },
    },
  },
  watch: {
    dialogProps(v) {
      if (!v) this.fetchData();
    },
    values(v) {
      if (v) this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    getProps(id) {
      let res = this.data.filter(d => {
        return d.object_props_id == id;
      });

      let list = JSON.parse(res[0]?.options || "[]");
      if (list) {
        res.forEach(el => {
          list = list.filter(l => {
            return l.value !== el.value;
          });
        });
        list.forEach(el => {
          res.push({ object_props_id: id, value: el.value, type: res[0].type, options: res[0].options, data: null });
        });
      }

      return res;
    },
    preview() {
      let l = this.type[0] == "o" ? "o" : "a";
      let url = this.$root.config.siteUrl + `/catalog/${l}-${String(this.model.id).padStart(4, "0")}.html`;
      //let url = this.$root.config.siteUrl + `/catalog/${this.type}/${this.model.id.toString().md5()}.html`;
      return window.open(url, "_blank");
    },
    remove(key) {
      let e = this.items;
      //e.splice(key, 1);
      e[key].type = "del";
      this.items = e;
    },
    add(defs = null) {
      this.idEdit = 0;
      this.defs = defs;
      this.dialogProps = true;
    },
    edit(id) {
      this.idEdit = id;
      this.defs = null;
      this.dialogProps = true;
    },
    addProp(prop) {
      console.log("add ", prop);
      let defs = { object_props_id: prop.object_props_id, value: prop.value, model: prop?.value };
      this.add(defs);
    },
    copy() {
      this.showCopyDialog = true;
    },
    async importProps(e) {
      console.log(e);
      e.forEach(async d => {
        await this.post(d, "mechti/object_props_value");
      });
      setTimeout(() => {
        this.fetchData();
      }, 50);
    },
    save() {},
    async post(data, api) {
      this.loading = true;
      this.data.type = this.type;
      let response = await this.$axios.post(api, data);
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные записаны",
        });
      }
    },
    async upload() {
      this.fileLoading = true;
      this.dialogProps = false;
    },
    async fetchData() {
      let api = "mechti/object_props_value";
      let params = {};
      if (this.model.sort) {
        params.sort = this.model.sort;
      }
      this.loading = true;
      params.filters = { object_id: this.model.id };
      // let response = await this.$axios.get(api, { params });

      let response = await this.$axios.post("/report_sql", { name: "props_getObjectProps", params: { type: this.model.type, id: this.model.id } });

      this.loading = false;
      this.data = response.data.data;
      let props = [];
      this.data.forEach(el => {
        if (
          el.status == 1 &&
          !props.find(p => {
            return p.id == el.object_props_id;
          })
        ) {
          props.push({ id: el.object_props_id, name: el.name });
        }
      });
      this.props = props;
    },
  },
};
</script>
