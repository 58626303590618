<template>
  <div class="py-1 pb-3">
    <div>
      <div v-if="error" class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__message">{{ error }}</div>
      </div>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, readonly, hideDetails: 'auto' }"
              @validate="_validate($event)"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: { type: Object, default: () => {} },
    values: Object,
    opt: { type: String, default: "" },
    error: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto1" };
      },
    },
  },
  data() {
    return {
      //m: this.$store.getters["config/get"].models.adsInfo,

      model: [],
      idEdit: 0,
      suEditShow: false,
      removeDialogShow: false,
      type: null,
      loaded: false,
      options: [],
      watchModel: null,
      fieldData: {},
    };
  },
  computed: {
    m() {
      let mname = this.config.model.model;
      let model = this.$store.getters["config/get"].models?.[mname] || [];
      return model;
      return res;
    },
    canDelete() {
      return this.opt.includes("delete");
    },
    readonly() {
      return this.opt.includes("readonly");
    },
    removeTitle() {
      return this.m?.removeTitle || "Вы действительно хотите удалить это?";
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true; ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {
    value(v) {
      this.inputData(this.value);
    },
  },
  methods: {
    async _validate(e) {
      const err = await this.validate(e);
      if (!err) {
      } else {
      }
      this.$emit("input", this.data);
    },
    afterFetchData(r) {
      // this.calcModel(this.data?.type);

      this.getModel();
      this.loaded = true;
    },
    getModel(t = "default") {
      let config = JSON.parse(JSON.stringify(this.m.config.default || this.config));
      let model = this.calcModelConfig(config);

      this.fillFormFromModel(model);
      this.model = model;
      this.inputData(this.value);
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      let id = data.id;
      if (!this.id && this.m?.routeName) this.$router.push({ name: this.m.routeName, params: { id } });
    },

    inputData(d = {}) {
      if (this.values) {
        this.$set(this.data, "object_data", this.values?.object_data || {});
      }
      if (d)
        for (const name in d) {
          this.$set(this.data.data, name, d[name]);
          this.$set(this.errors, name, null);
        }
    },
    async _remove(api, id) {
      if (!api || !id) return;
      this.$emit("input");
    },
  },
};
</script>
