<template>
  <div class="d-flex" style="position: relative">
    <v-autocomplete
      v-if="model.autocomplete"
      outlined
      :items="[...sortOptions, ...listDisabled]"
      :item-text="textField"
      item-value="value"
      :item-disabled="itemDisabled"
      menu-props="offset-y, auto, overflowY"
      v-model="v"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :clearable="!readonly"
      :readonly="readonly"
      :dense="config.dense || false"
      @input_="$emit('input', $event)"
      @keydown="onKeyDown"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
    ></v-autocomplete>
    <v-select
      v-else
      outlined
      :clearable="!readonly"
      :items="[...sortOptions, ...listDisabled]"
      :item-text="textField"
      item-value="value"
      :item-disabled="itemDisabled"
      v-model="v"
      menu-props="offset-y, auto, overflowY"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :readonly="readonly"
      :dense="config.dense || false"
      @input1="onInput($event)"
      @keydown="onKeyDown"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
      class="list-hide-disabled1"
    >
    </v-select>
    <btn-rating v-if="(model.rating && config.rating) || false" v-model="rating"> </btn-rating>
  </div>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";

/*
<!--       @focus="toggleSelect = true"
      @blur="toggleSelect = false"
      @click="toggleSelect = true"
      :menu-props="{
        value: toggleSelect,
        'offset-y': true,
        closeOnContentClick: true,
      }" -->

*/
export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },

    model: Object,
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {}, disabled: false },
  },
  data() {
    return {
      listDisabled: [],
      cc: 0,
      showAllItem: false,
      title: null,
      preText: null,
      toggleSelect: false,
    };
  },
  computed: {
    v: {
      get() {
        let res = this.value;
        if (this.model.chips) {
          if (res === null || res === undefined) res = [];
          if (this.model.keyInObject) {
            res = (this.value || []).map((v) => v[this.model.keyInObject]);
          }
          if (!Array.isArray(res))
            try {
              res = res.split(",");
            } catch (error) {
              res = [res];
            }
        }
        return res;
      },
      set(v) {
        let res = v;
        if (this.model.keyInObject) {
          res = [
            ...v.map((e) => {
              return { [this.model.keyInObject]: e };
            }),
          ];
        }
        this.onInput(res);
        //this.$emit("input", res);
      },
    },
    textField() {
      return this.model.textField || "text";
    },
    options() {
      const res = this.getOptions(this.model);
      this.listDisabled = [];
      if (!res) return [];
      if (Array.isArray(res)) {
        res.forEach((e) => {
          if (e?.isActive === undefined && typeof e === "object") {
            e.isActive = !this.checkDisabled(e);
          }
        });

        if (res) {
          const resE = res.filter((r) => r.isActive);
          const resD = res.filter((r) => !r.isActive);
          this.listDisabled = [...resD];
          return resE;
        }
      }
      return [];
    },
    sortOptions() {
      let model = this.model;
      let list = JSON.parse(JSON.stringify(this.options));
      if (model?.dirVar) {
        const key = model.dirVar.key;
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];
          const name = model.dirVar.dirName.find((el) => {
            return el.value == v;
          });
          this.title = name?.title || null;
          this.preText = name?.preText || null;
        }
      }
      if (this.value && !list.some((list) => list.value === this.value) && !Array.isArray(this.value)) {
        list = [{ text: this.value, value: this.value }, ...list];
      }
      return list.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    titleValue() {
      return this.title || this.model.title;
    },
    rating: {
      get() {
        let res = this.values.data?.rating?.[this.model.name] || "";
        return res;
      },
      set(v) {
        this.$emit("rating", { name: this.model.name, value: v });
      },
    },
  },
  methods: {
    onKeyDown(e) {
      if (e.key === "*") {
        console.log("*");
        this.showAllItem = !this.showAllItem;
        e.preventDefault();
      }
    },
    itemDisabled(e) {
      if (typeof e === "string") return false;
      return !e.isActive;
    },
    checkDisabled(e) {
      if (this.showAllItem) return false;
      if (e?.isActive === false || e.hidden || e?.disabled === true) return true;
      return false;
    },
    onInput(e) {
      this.$emit("input", e);
      if (this.model.cascade) {
        for (const key in this.model.cascade) {
          const el = this.model.cascade[key];
          if (el.type == "field") {
            let value = this.options.find((f) => f["value"] == e)?.[el.value];
            if (value !== undefined) {
              //this.$emit("cascade", { field: el.value, value });
              this.$emit("cascade", { field: key, value });
            }
          }
          if (el.type == "formula") {
            let func;
            let f = this.options.find((f) => f["value"] == e)?.[el.value];
            if (typeof f == "string") func = eval(f);
            let value = func(this.values);
            if (value !== undefined) {
              this.$emit("cascade", { field: key, value });
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.v-list-item.v-list-item--disabled1 {
  display: none !important;
}
</style>
