<template>
  <static-fullscreen-card :loaded="!loading">
    <template v-slot:title> ({{ data.id }}) {{ data.name }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions>
      <a-btn-menu v-if="getAccess('leads.status')" color="primary" icon="fas fa-filter" :items="typeGroups" @click="onChangeGroup($event)" :disabled="loading" />
      <a-btn-add v-if="data.has_chat" :icon="showChat ? 'far fa-window-close' : 'far fa-comments'" title="" @click="showChat = !showChat" :disabled="loading" />
      <a-btn-add v-if="getAccess('leads.actions.create')" icon="far fa-clock" title="Задача" @click="onCreateTask()" :disabled="loading" />
      <a-btn-add v-if="getAccess('leads.actions.create')" icon="fas fa-tty" title="Действие" @click="onCreateAction()" :disabled="loading" />
      <a-btn-status v-if="getAccess('leads.status')" :items="statusItems" @click="clickStatus($event)" :disabled="loading" />
      <a-btn-edit v-if="editLead" title="Редактирование" @click="onEdit()" />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <portal to="v-main">
      <s-edit-form
        v-model="showEditDialog"
        v-if="showEditDialog"
        :m="configH.m"
        :configForm="{ head: configH.head, title: configH.title, api: configH.api }"
        :id="configH.id"
        :opt="configH.opt"
        :initData="configH.initData || {}"
        :params="configH"
      >
      </s-edit-form>
    </portal>
    <a-loader v-if="loading" />
    <v-dialog v-model="showChat" fullscreen hide-overlay>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="showChat = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Чат</v-toolbar-title>
        </v-toolbar>
        <chat-room v-if="showChat" :id="data.id" />
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">Клиент</div>
          </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true }" @onEdit="onEdited($event)" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">Контакты</div>
          </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" v-if="!loading">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color1="white" show-arrows center-active @change="changeTab(tab)">
              <v-tab v-for="(tab, i) in tabs" :key="i" v-if="showTab(tab.name)" class="mr-3">{{ tab.title }} </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px">
            <v-tab-item v-for="(tab, i) in tabs" :key="i" v-if="showTab(tab.name)" :data-name="`${tab.name} section`">
              <comment-view v-if="tab.name == 'comments'" :id="id || 0" :targetName="'rent'" :needUpdate="needUpdateComments" :height="detailHeight" />
              <TabActions v-if="tab.name == 'actions'" ref="tabActions" :id="id" :height="detailHeight" />
              <TabTasks
                v-if="tab.name == 'tasks'"
                ref="tabTasks"
                :id="id"
                :height="detailHeight"
                @action="onTaskAction($event)"
                @edit="onEditTask($event)"
                @loadData="table_tasks = [...$event]"
              />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <s-edit-form-async ref="editField" />
  </static-fullscreen-card>
</template>

<script>
import _ from "lodash";
import leads from "@/mixins/leads";
import { getAccess, autoHeightBlock, genModel, statusDoc, saveEl } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock, genModel, statusDoc, leads, saveEl],
  components: {
    TabActions: () => import("./tabs/tabLeadActions"),
    TabTasks: () => import("./tabs/tabLeadTasks"),
    chatRoom: () => import("@/views/chat3/ChatRoom"),
  },
  props: {
    idShow: Number,
    statusList: Array,
  },
  data() {
    return {
      id: 0,
      needUpdateComments: 0,
      loading: true,
      addDocModel: null,
      showEditDialog: false,
      configH: null,
      confirmDialog: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.leads,
      tab: 0,
      initEditDialog: 0,
      clientHeight: 0,
      showChat: false,
      statuses_: null,
      tasks_: null,
      table_tasks: [],
    };
  },
  created() {
    this.loading = true;
    this.$root.title = "Лид";
    this.id = this.idShow || Number(this.$route.params.id);
    this.fitchData();
    if (this.$attrs?.tab) {
      this.tab = parseInt(this.$attrs.tab);
    }
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        if (this.configH.type == "lead") this.fitchData();
        if (this.configH.type == "tasks") {
          if (this.$refs.tabTasks) {
            this.$refs.tabTasks[0].$refs.table.updateData();
          }
        }
        if (this.configH.type == "actions") {
          if (this.$refs.tabActions) {
            this.$refs.tabActions[0].$refs.table.updateData();
          }
          if (this.$refs.tabTasks) {
            this.$refs.tabTasks[0].$refs.table.updateData();
          }
          this.fitchData();
        }
        this.configH = null;
      }
    },
  },
  computed: {
    statuses() {
      if (this.idShow) return this.statusList || [];
      return this.statuses_;
    },
    url() {
      return this.m.api;
    },
    api() {
      return this.m.api;
    },
    model: {
      get() {
        let model = this.getModelList(this.m, "form", true);
        return model;
      },
    },
    modelLeft() {
      if (!this.model) return [];
      let res = this.calcModel("viewForm1");
      this.model
        .filter((m) => m.type == "buttonform")
        .forEach((m) => {
          if (this.getObjectPath(this.data, m.name)) res.push(m);
          else if (this.table_tasks && this.table_tasks.find((t) => t.status == 1 && "data." + t?.leads_task_template_data?.forms == m.name)) res.push(m);
        });

      return res;
    },
    modelRight() {
      return this.calcModel("viewForm2");
    },
    tabs() {
      let tabs = [
        { name: "tasks", title: "Задачи", show: this.getAccess("leads.actions.view") },
        { name: "actions", title: "Действия", show: this.getAccess("leads.actions.view") },
      ];
      return tabs;
    },

    editLead() {
      return this.getAccess("leads.edit");
    },
    configHead() {
      return this.m.config.manager || {};
    },
  },
  methods: {
    async onEdited(e) {
      if (!this.id) return;
      let res;

      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        res = d.data.data;
      } else return;

      let data = res.data || {};
      data.id = this.id;
      _.set(data, e.field, e.value);
      console.log("save ", data);
      if (await this.save(this.url, data)) this.data = data;
    },
    async onEditField(e) {
      return this.$refs.editField.show(e);
    },
    async fillNote() {
      let res = await this.onEditField({
        title: "Заполните причину отмены",
        data: { reason: "" },
        model: [{ name: "reason", title: "Причина", type: "text", validator: ["req"] }],
      });
      if (res) {
        return res;
      } else {
      }
      return false;
    },
    async beforeChangeStatus(status) {
      const data = {};
      if (status.value == -1) {
        let res = await this.fillNote();
        if (res) {
          data["notes"] = `${this.data?.notes || ""}
${res.reason}`;
        } else return false;
      }
      //if (status?.type !== undefined) data["type"] = status.type;
      // return false;
      return { data };
    },
    async onChangeGroup(e) {
      console.log(e);
      if (!this.api || !this.data.id) return;
      if (e.data?.status_default === undefined) {
        this.$root.$emit("show-info", { type: "error", text: "Не определен статус по умолчанию" });
        return;
      }

      this.loading = true;
      let data = { id: this.id, type: e.value, status: e.data.status_default };
      let response = await this.$axios.post(this.api, data);
      this.loading = false;
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
    async onTaskAction({ task, type }) {
      if (type == "done") {
        const config = {};
        this.configH = null;
        let actions = Object.assign({}, this.m.actions);
        let tasks = Object.assign({}, this.m.tasks);
        let types = await this.getTasks({ leads_status_group_id: this.data.type }); //берем список задач к воронке и фильтруем их по статусу лида

        actions.form.find((m) => m.name == "type").options = types;
        tasks.form.find((m) => m.name == "type").options = [];
        tasks.form.find((m) => m.name == "type").options = types.filter((e) => (e.data?.leads_status || []).includes(this.data?.status));
        tasks.standart = true;
        actions.form.find((e) => e.name == "tasks_data").model = tasks;
        config.type = "actions";
        config.head = actions.config.done || {};
        config.title = actions.config.done.title;
        config.api = actions.api;
        config.id = null;
        config.opt = this.getAccess("leads.actions.create") && task.status == 1 ? {} : { readonly: true };
        config.initData = { lead_id: this.id, task_id: task.id, type: task.type, status: this.data.status };
        config.m = actions;
        this.configH = config;
        this.showEditDialog = true;
      }
    },
    onEdit() {
      const config = {};
      config.type = "lead";
      this.configH = null;
      config.head = this.m.config.manager || {};
      config.title = this.m.config.manager.title || {};
      config.api = this.url;
      config.id = this.id;
      config.opt = { delete: this.getAccess("leads.delete") };
      config.m = this.m;
      this.configH = config;
      this.showEditDialog = true;
    },
    onCreateTask(e) {
      this.onEditTask();
    },
    async onEditTask(id = null) {
      const config = {};
      config.type = "tasks";
      this.configH = null;
      let tasks = Object.assign({}, this.m.tasks);
      tasks.form.find((m) => m.name == "type").options = [];
      let types = await this.makeTasks({ leads_status_group_id: this.data.type }); //берем список задач к воронке и фильтруем их по статусу лида
      tasks.form.find((m) => m.name == "type").options = types.filter((e) => (e.data?.leads_status || []).includes(this.data?.status));

      config.head = tasks.config.default || {};
      config.title = tasks.config.default.title || {};
      config.api = tasks.api;
      config.id = id;
      config.opt = this.getAccess("leads.actions.create") ? {} : { readonly: true };
      config.func = { readonly: "(d)=>{return d.status!==1}" };
      config.initData = { lead_id: this.id };
      config.m = tasks;
      this.configH = config;
      this.showEditDialog = true;
    },
    async onCreateAction() {
      const config = {};
      config.type = "actions";
      let actions = Object.assign({}, this.m.actions);
      let tasks = Object.assign({}, this.m.tasks);
      this.configH = null;
      let types = await this.makeTasks({ leads_status_group_id: this.data.type }); //берем список задач к воронке и фильтруем их по статусу лида
      actions.form.find((m) => m.name == "type").options = types.filter((e) => (e.data?.leads_status || []).includes(this.data?.status));
      tasks.form.find((m) => m.name == "type").options = [];
      tasks.form.find((m) => m.name == "type").options = types.filter((e) => (e.data?.leads_status || []).includes(this.data?.status));
      tasks.standart = true;
      actions.form.find((e) => e.name == "tasks_data").model = tasks;

      config.head = actions.config.default || {};
      config.api = actions.api;
      config.id = null;
      config.opt = this.getAccess("leads.actions.create") ? {} : { readonly: true };
      config.initData = { lead_id: this.id, status: this.data.status };
      config.m = actions;
      this.configH = config;
      this.showEditDialog = true;
    },
    showTab(n) {
      if (n == "acc") return false;
      return true;
    },

    changeTab(tab) {
      if (!this.idShow) this.$router.push({ query: { tab } });
    },
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.makeStatuses();
      this.loading = false;
    },

    updateData() {
      this.fitchData();
    },
    async makeTasks(f) {
      if (!this.tasks_) this.tasks_ = await this.getTasks(f);
      return this.tasks_;
    },
    async makeStatuses() {
      this.statuses_ = [];

      this.statuses_ = await this.getStatuses({ status: 1 });
      // this.statuses_ = await this.getStatuses({ status: 1, leads_status_group_id: this.data.type });
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
