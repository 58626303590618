<template>
  <dialog-edit v-if="dialog" :value="dialog" @input="dialog = !true" :maxWidth="'100hv'">
    <template v-slot:title> {{ params.title }} </template>
    <v-card-text ref="cardList" style="height: calc(100vh - 160px); overflow: auto" class="pa-0 list-card">
      <a-table-f-api
        v-if="type == 'api'"
        ref="table"
        :api="params.api"
        :model="modelList"
        :useQuery="false"
        :limit="0"
        :defaults="defaults"
        @click="onClickRow($event)"
      />
      <a-table-f-sql v-if="type == 'sql'" ref="table" :query="params.sql" :model="modelList" :useQuery="false" :defaults="defaults" @click="onClickRow" />
    </v-card-text>
    <template v-slot:actions>
      <v-menu v-if="basket && basketData && basketData.length" v-model="basketMenu" :close-on-content-click="false" offset-y max-width="100%" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on">{{ basketButtonText }}</v-btn>
        </template>

        <v-card style="left: 0" class="ma-0">
          <v-card-text v-if="$refs.cardList" :style="`height: ${basketHeight}px; overflow: auto; width:${$refs.cardList.offsetWidth}px`" class="pa-2">
            <a-table-f-data2
              ref="tableData"
              :dataTable="basketData"
              :useQuery="false"
              :model="basketModel"
              :searchable="false"
              :defaults="{
                filters: {},
                sort: { key: 'id', order: 'DESC' },
              }"
              @click="onClickRow($event)"
            >
              <template v-slot:top> </template>
              <template v-slot:item.actions="{ row }">
                <div class="d-flex">
                  <v-btn x-small fab class="ma-0 ml-0" color="red" title="Отменить" @click.stop="delRow(row)">
                    <v-icon small>fas fa-times</v-icon>
                  </v-btn>
                </div>
              </template>
            </a-table-f-data2>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <a-btn-save
              v-if="basket"
              @click="
                add();
                dialog = !true;
              "
              icon="fas fa-check"
              :title="'Применить'"
            />
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-spacer></v-spacer>
      <a-btn-save
        v-if="!basket"
        @click="
          add();
          dialog = !true;
        "
        :title="'Выбрать'"
      />
      <v-btn
        @click="
          dialog = !true;
          $emit('input');
        "
        >Закрыть</v-btn
      >
    </template>
  </dialog-edit>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess],
  props: { basket: { type: Boolean, default: false }, basketData1s: { type: Array, default: () => [] } },
  data() {
    return {
      dialog: false,
      type: null,
      defaultParams: {
        type: "",
        title: "",
        api: "",
        model: [],
        multiple: false,
        sort: { key: "id", order: "DESC" },
        filters: {},
        where: {},
      },
      params: {},
      basketMenu: false,
      basketHeight: 300,
      basketData: null,
    };
  },
  computed: {
    defaults() {
      let res = { sort: this.params?.sort || {}, filters: Object.assign({}, this.params.filters, this.params.where) };
      return res;
    },
    modelList() {
      let res = [...(this.params?.model || [])];
      if (this.params?.multiple && !this.basket) res = [{ name: "checked", type: "checkbox", width: 35 }, ...res];
      return res;
    },
  },
  created() {},
  watch: {},
  methods: {
    init(config) {
      this.type = null;
      this.basketData = null;

      let params = Object.assign({}, this.defaultParams, config);
      if (this.basket) {
        params.isBasket = true;
        console.log("set basket ", config);

        this.setBasket(config?.basket?.data || []);
        this.basketModel = [...(config?.basket?.model || config?.model || []), { name: "actions", type: "action", width: 55 }];
      }
      this.params = params;
      if (config?.api) this.type = "api";
      if (config?.sql) this.type = "sql";
      this.dialog = true;
    },
    setBasket(data, buttonText = "") {
      this.basketData = [...data];
      this.basketButtonText = buttonText || `Корзина (${data.length})`;
      if (this.basketMenu) this.basketMenu = this.basketData.length;
    },

    onClickRow(r) {
      if (this.basketMenu) return;
      if (this.basket) {
        //      let d = this.basketData;
        //     d.push(r.row);
        //   this.basketData = [...d];
        this.$emit("updateBasket", { action: "add", params: this.params, item: r.row });
        //this.$emit("update:basketData", d);
        //console.log(this.data);
      } else {
        this.$refs.table.$refs.base.clickCheckbox(d.event, d.row);
        this.$refs.table.$refs.base.$forceUpdate();
      }
    },
    add() {
      let rows = [];
      if (this.basket) {
        this.$emit("save", { type: this.params.type, rows: this.basketData, params: this.params });
        this.$emit("input");
      } else {
        this.$refs.table.data.filter(el => {
          return el?.checked;
        });
      }
      console.log({ type: this.params.type, rows });
      this.$emit("save", { type: this.params.type, rows, params: this.params });
      this.$emit("input");
    },

    async delRow(row) {
      this.$emit("updateBasket", { action: "del", params: this.params, item: row });
      //   this.basketData = [...d];
    },
  },
};
</script>

<style lang="scss">
.list-card {
  padding: 0;
}
</style>
