<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ readonly ? "Просмотр" : "Редактирование" }}
      </template>
      <a-form-modelCols
        v-if="typeModel == 1"
        class="pa-2"
        v-model="data"
        :model="model"
        :config="{ readonly, dense: true, hideDetails: 'auto' }"
        :errors="errors"
        @selected="onSelect($event)"
        @validate="
          validate($event);
          $emit('onChange', { d: data, e: $event });
        "
        @commit="onFormCommit($event)"
      />
      <v-row v-if="typeModel == 2" class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, readonly }"
              @validate="
                doWatch($event);
                validate($event);
              "
              @fieldData="setFieldData($event)"
            />
          </div>
        </v-col>
      </v-row>
      <slot name="form" :data="data"></slot>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="!readonly" @click="submit()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getAccess, getForm, submitForm, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, getForm, submitForm, genModel],
  props: {
    dataTable: Array,
    id: Number,
    value: Boolean,
    RO: Array,
    type: { type: String, default: "" },
    readonly: { type: Boolean, default: false },
    m: { type: Object, default: () => {} },
    configForm: { type: Object, default: () => {} },
    initData: { type: Object, default: () => {} },
    modelEdit: {
      type: Function,
      default: (m) => {
        return m;
      },
    },
  },
  data() {
    return {
      api: "",
      rowEdit: {},
      rows: [],
      model: [],
      typeModel: 0,
    };
  },
  computed: {
    config() {
      if (this.configForm?.head) return this.configForm.head;
      const type = this.configForm?.type || "default";
      return JSON.parse(JSON.stringify(this.m?.config?.[type] || {}));
    },
    opt() {
      return { type: this.configForm?.opt || "" };
    },
    model1() {
      // let model = JSON.parse(JSON.stringify(this.m.formDetail));
      let model = this.getModelList(this.m, "form" + this.type.upFirst(), true);
      //let model = this.getModelList(this.m?.type, "form", true);
      model.forEach((el) => {
        if (this.RO.includes(el.name) && this.data[el.name] !== null) {
          el.readonly = true;
        }
      });
      return model;
    },
  },
  created() {
    this.updateData(this.id);
    console.log("dialog edit", this.m);
  },
  watch: {
    id(v) {
      if (v) this.row = this.dataTable.find((el) => el.id == v);
    },
    value() {
      if (this.value) {
        if (this.id) this.row = this.dataTable.find((el) => el.id == this.id);
      }
    },
  },
  methods: {
    onSelect(e) {
      //console.log("select", { e });
      this.$emit("onChange", { d: this.data, e: e.name, s: e.value });
    },
    makeChange(e, v) {
      // console.log("make change", { e, v });
      if (e == "data") {
        this.data = v;
      } else {
        this.setData(e, v);
      }
    },
    async onFormCommit(e) {
      if (this.readonly) {
        if (e.el.name == "date_before") {
          if (!this.data.id) return;
          if (!e.event) return;
          this.$emit("refresh");
        }
      }
    },
    async afterFetchData() {
      if (!this.id) {
        for (const name in this.initData) {
          this.$set(this.data, name, this.initData[name]);
        }
      }
      await this.getModel(this.type);
      this.model
        .filter((el) => {
          return el.formula ? true : false;
        })
        .forEach((m) => {
          this.makeFormulaField(m);
        });
      console.log(this.data);
      this.loading = false;
    },
    resetForm() {},
    async fetchData() {
      this.loading = true;
      this.rows = JSON.parse(JSON.stringify(this.dataTable)) || [];
      if (this.id) {
        this.rowEdit = this.rows.find((el) => el.id == this.id);
        for (const name in this.rowEdit) {
          if (name == "data" && this.data?.data && this.rowEdit[name]) {
            for (const nameData in this.rowEdit[name]) {
              this.$set(this.data.data, nameData, this.rowEdit[name][nameData]);
            }
          } else if (name == "data" && typeof this.data.data == "object") {
          } else {
            this.$set(this.data, name, this.rowEdit[name]);
          }
        }
      }

      this.afterFetchData();
    },
    beforeSave() {
      //if (this.data.status === null) this.data.status = 0;
    },
    post() {
      let rows = [];
      this.rows.forEach((r) => {
        if (r.id == this.id) {
          rows.push(this.data);
        } else {
          rows.push(r);
        }
      });
      if (!this.id) {
        rows.push(this.data);
      }
      this.$emit("save", rows);

      this.$emit("input");
    },
    _submit() {
      if (this.readonly) return;
      this.submit();
    },
    doWatch(e) {
      //console.log({ e, wathc: this.m?.config?.watch, value: this.data?.[e] });
      if (e == this.m?.config?.watch) this.getModel(this.data?.[e]);
    },
    getModel(t) {
      if (this.m?.typeModel !== "main") {
        this.typeModel = 1;
        let model = this.getModelList(this.m, "form" + t.upFirst(), true);
        //let model = this.getModelList(this.m?.type, "form", true);
        model.forEach((el) => {
          if (this.RO.includes(el.name) && this.data[el.name] !== null) {
            el.readonly = true;
          }
        });
        this.model = model;
      } else {
        this.typeModel = 2;
        //console.log("get model", t, this.m?.config[t]);
        let config = Object.assign({}, this.config);
        config = JSON.parse(JSON.stringify(this.m?.[this.type]?.config?.default || this.config));
        this.watchModel = config?.watch || null;
        let model = this.calcModelConfig(config);
        if (true)
          model.forEach((el) => {
            if (el.hiddenCreate && !this.data?.id) {
              el.hidden = true;
            }
          });
        if (this.configForm.modelEdit) model = this.configForm.modelEdit(model);
        console.log("result nodel", model);
        this.fillFormFromModel(model);
        this.model = model;
      }
    },
  },
};
</script>
