<template>
  <v-simple-table style="width: 100%" class="mt-1">
    <tbody>
      <tr v-for="(el, key) in items" :key="key">
        <td class="text-left" @mouseover="id = el.upload_id" @mouseleave="id = null">
          <a href="#" @click.prevent="openFile(el)" target="_blank">
            {{ el.file_name || el.file }}
          </a>
          <v-tooltip v-if="id == el.upload_id" top v-model="showInfo">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1">mdi-information-outline </v-icon>
              </v-btn>
            </template>
            <a-form-view v-if="showInfo && id == el.upload_id" :value="dataInfo" :model="modelInfo" :config="{ dense: true }" />
          </v-tooltip>
        </td>

        <td width="10px" style="height: 38px" class="pr-2 pl-0">
          <v-btn :href="$root.config.fileUrl + el.file" target="_blank" class="mx-1" fab dark x-small color="error" title="Скачать">
            <v-icon dark> mdi-download </v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    value: [Array],
    category: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showInfo: false,
      id: null,
      loaded: false,
      dataInfo: {},
      modelInfo: [
        { name: "createdby_id", title: "Загрузил", type: "select", dir: "user" },
        { name: "createdon", title: "Дата", type: "datetime" },
      ],
    };
  },
  watch: {
    showInfo(v) {
      if (v) {
        console.log("show info", this.id);
        if (this.id) this.getInfo(this.id);
      }
    },
  },
  computed: {
    items: {
      get() {
        if (this.value) {
          try {
            let f = this.category;
            return this.value.filter(el => {
              return f == null || el.category_id == f;
            });
          } catch (error) {}
        }
        return [];
      },
    },
  },
  methods: {
    openFile(el) {
      this.openLink(el, 1);
      //window.open(this.$root.config.fileUrl + el.file, "_blank");
      //$root.config.fileUrl + el.file
    },

    openLink(el, show = 0, d = {}) {
      let url = this.$root.config.fileUrl + el.file;
      window.open(url, "_blank");
    },
    async getInfo(id) {
      this.loaded = false;
      this.dataInfo = {};
      let resp;
      resp = await this.$axios.get("uploads/" + id, {});
      this.loaded = true;
      this.dataInfo = resp.data.data;
      //console.log("work", resp, this.dataInfo);

      //return resp.data.data;
    },
  },
};
</script>
