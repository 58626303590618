<template>
  <static-fullscreen-card>
    <template v-slot:title>
      <v-breadcrumbs :items="deep">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-btn :disabled1="parent == item.id" @click="onGoto(item)"> {{ item.text }} </v-btn>
        </template>
      </v-breadcrumbs>
    </template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.folder.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <wiki-folder ref="folder" :api="url" :parent="parent" @onClick="onClick($event)" />
    <portal to="v-main">
      <edit-dialog v-if="showEditDialog" v-model="showEditDialog" :id="idEdit"></edit-dialog>
    </portal>
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./../dialogs/infoViewDialog"),
  },
  data() {
    return {
      deep: [{ id: 0, text: "База знаний" }],
      parent: 0,
      idEdit: 0,
      showEditDialog: false,
      model: this.$store.getters["config/get"].models.info.list,
      dataTable: [],

      url: "/mechti/information_group",
      defaults: {
        sort: [{ key: "id", order: "DESC" }],
      },
    };
  },
  computed: {},
  created() {
    this.$root.title = "Информация";
    // this.getData();
  },

  methods: {
    onGoto(e) {
      if (this.deep.length == 1) return;
      let idx = this.deep.findIndex(d => d.id == e.id);
      if (idx < 0) return;
      let parent = this.deep[idx].id;
      let deep = [...this.deep];
      deep.splice(idx + 1);
      // console.log(idx, deep);
      //return;
      this.deep = deep;
      this.parent = parent;
      this.$refs.folder.updateData(parent);
    },
    onClick(e) {
      console.log(e);
      if (e.is_parent) {
        this.parent = e.id;
        this.$refs.folder.updateData(this.parent);
        this.deep.push({ id: e.id, text: e.name });
      } else {
        this.idEdit = e.id;
        this.showEditDialog = true;
      }
    },

    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
