<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:title>
      <v-row class="ma-1">
        <v-btn-toggle v-model="type" mandatory borderless dense>
          <v-btn :value="0" color="#d0bf9c">
            <span class="hidden-lg-and-down">SQL</span>
            <v-icon :right="$vuetify.breakpoint.lgAndUp"> mdi-database-search</v-icon>
          </v-btn>
          <v-btn :value="1" class="ml-1" color="#d0bf9c">
            <span class="hidden-lg-and-down">API</span>
            <v-icon :right="$vuetify.breakpoint.lgAndUp"> mdi-api </v-icon>
          </v-btn>
        </v-btn-toggle></v-row
      ></template
    >
    <template v-slot:actions>
      <v-btn color="primary" @click="test"><btn-title icon="fas fa-sync-alt">test</btn-title> </v-btn>
      <v-btn
        color="primary"
        @click="
          showT = true;
          $refs.table.updateData();
        "
        ><btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn>
    </template>
    <portal to="v-main">
      <s-document-dialogs-show-data
        v-model="showEditDialog"
        v-if="showEditDialog"
        :api="m_show.api"
        :m="m_show"
        :showData="showData"
        :config="m_show.config.default"
        :title="m_show.title"
        :id="idItemShow"
        opt="readonly"
      />
    </portal>
    <v-tabs-items v-if="showT" v-model="type" class="transparent" touchless>
      <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
        <a-table-f-api
          ref="table"
          :api="m_sql.api"
          :model="m_sql.list"
          :useQuery="false"
          :defaults="{ sort: { key: 'id', order: 'DESC' }, paramName: 'logSql' }"
          @click="onClickRow($event, m_sql)"
        />
      </v-tab-item>
      <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
        <a-table-f-api
          ref="table"
          :api="m_req.api"
          :model="m_req.list"
          :useQuery="false"
          :defaults="{ sort: { key: 'id', order: 'DESC' }, paramName: 'logRequest' }"
          @click="onClickRow($event, m_req)"
        />
      </v-tab-item>
    </v-tabs-items>
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      idItemShow: 0,
      showData: {},
      showT: false,
      type: 0,
      showEditDialog: false,
      title: "",
      //model: this.$store.getters["config/get"].models.info.list,
      m_show: {},
      m_sql: {
        title: "SQL лог",
        accessName: "",
        api: "/log/sql",
        list: [
          { name: "id", title: "#", type: "id", sortable: true, width: 80 },
          { name: "createdon", title: "Дата", type: "datetime", sortable: true, width: 120 },
          { name: "createdby_id", title: "Автор", type: "select", dir: "user", sortable: true, width: 180 },
          { name: "data", title: "Запрос", type: "string", sortable: true, width: 280 },
          { name: "params", title: "Параметры", type: "variant", sortable: true, width: 180 },
          { name: "t", title: "время", type: "number", sortable: true, width: 50 },
          { name: "request_id", title: "request_id", type: "number", sortable: true, width: 180 },
        ],
        form: [
          { name: "id", title: "#", type: "number" },
          { name: "createdon", title: "Дата", type: "datestring" },
          { name: "createdby_id", title: "Автор", type: "select", dir: "user" },
          { name: "data", title: "Запрос", type: "text" },
          { name: "params", title: "Параметры", type: "variant" },
          { name: "request_id", title: "request_id", type: "string" },
        ],
        config: {
          default: {
            fields: "id,createdon,createdby_id,data,params,request_id",
            fieldsRO: "",
            fieldsForm: "id#2,createdon#5,createdby_id#5,data,params,request_id",
          },
        },
      },
      m_req: {
        title: "Request лог",
        accessName: "",
        api: "/log/request",
        list: [
          { name: "id", title: "#", type: "id", sortable: true, width: 80 },
          { name: "createdon", title: "Дата", type: "datetime", sortable: true, width: 120 },
          { name: "createdby_id", title: "Автор", type: "select", dir: "user", sortable: true, width: 180 },
          { name: "level", title: "level", type: "variant", sortable: true, width: 50 },
          { name: "method", title: "method", type: "variant", sortable: true, width: 50 },
          { name: "data", title: "Запрос", type: "string", sortable: true, width: 280 },
          { name: "ip", title: "ip", type: "string", sortable: true, width: 180 },
        ],
        form: [
          { name: "id", title: "#", type: "number" },
          { name: "createdon", title: "Дата", type: "datetime" },
          { name: "createdby_id", title: "Автор", type: "select", dir: "user" },
          { name: "level", title: "level", type: "variant" },
          { name: "method", title: "method", type: "variant" },
          { name: "data", title: "Запрос", type: "text" },
          { name: "ip", title: "ip", type: "string" },
        ],
        config: {
          default: {
            fields: "id,createdon,createdby_id,level,method,data,ip",
            fieldsRO: "",
            fieldsForm: "id#2,createdon#5,createdby_id#5,level#2,method#4,ip#6,data",
          },
        },
      },
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Логи работы с БД";
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        //  this.$refs.table.updateData();
      }
    },
  },
  methods: {
    async test() {
      ///api.gk-me2chti.ru/api/accounting/dir/employee/select
      await this.$axios.get("/mechti/leads/tags/user_id");
      //await this.$axios.post("accounting/records/report-sql", { q: " SELECT count((log_sql_log.id)) AS count_id  FROM `log_sql_log`" });
      //await this.$axios.post("accounting/records/report-sql", { q: " SELECT count(DISTINCT(log_sql_log.id)) AS count_id  FROM `log_sql_log`" });
      // await this.$axios.get("https://api.gk-m2echti.ru/api/mechti/objects/site/agent/763");
      return;
      let params = { pager: { limit: 50, page: 0 }, filters: { "T.status_site": { condition: "&2=", value: "2" }, "T.status_fin": [10, 90] } };
      try {
        await this.$axios.get("/mechti/objects/site", { params });
      } catch (error) {}
      params = { pager: { limit: 50, page: 0 }, filters: { "T.status_site": 2, "T.object_price": [{ condition: ">", value: "100" }] } };

      await this.$axios.get("/mechti/design-projects/site", { params });
    },
    onClickRow(d, m = {}) {
      this.m_show = m;
      this.showData = d.row;
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idItemShow = id;
      this.showEditDialog = true;
    },
  },
};
</script>
