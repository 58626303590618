<template>
  <v-card class="ma-0" :maxHeight="height - 24" :minHeight="minHeight">
    <v-card-title class="secondary white--text py-0" @click="fitchData()">
      Требуется оплата ({{ data.length }})
      <v-spacer />
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-0 pa-1 pt-0">
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <div v-if="data.length > 0">
        <v-card-text :style="`height: ${height + ($vuetify.breakpoint.smAndDown ? -14 : -38)}px; overflow: auto`" class="pa-0">
          <a-table-f-data2
            ref="tableReport"
            :dataTable="dataSort"
            :model="model"
            :defaults="{ sort: { key: 'date_doc', order: 'DESC' } }"
            :searchable="false"
            :footerHide="true"
            @click="onClick($event)"
          >
          </a-table-f-data2>
        </v-card-text>
      </div>
      <div v-else>Список пуст</div>
    </v-card-text>
    <s-quickOpenDoc ref="quickOpenDoc" />

    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </v-card>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {},
  props: {
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      contentHeight: 10,
      showReportDialog: false,
      idReport: 0,
      loading: false,
      data: [],
      showConfig: false,
      timeID: null,

      model: [
        {
          name: "document",
          title: "Документ",
          type: "string",
          width: 120,
          isMenu: true,
          style: "white-space: normal;",
        },
        {
          name: "account",
          title: "Документ",
          type: "select",
          options: [
            { value: "60.1", text: "Заказ товаров" },
            { value: "60.2", text: "Заказ услуг" },
            { value: "cashOut", text: "Расходный ордер" },
          ],
          width: 120,
          hidden: true,
        },
        { name: "doc_id", title: "docID", type: "string", hidden: true },

        {
          name: "date_doc",
          title: "Дата",
          type: "date",
          width: 80,
          sortable: true,
        },
        { name: "status", title: "Действие", type: "string", width: 120 },
        {
          name: "saldo",
          title: "",
          type: "string",
          width: 100,
          sortable: true,
        },
      ],
    };
  },
  /*
              doc_name: el.account,
            doc_id: el.doc_id,
            doc: "",
            status: "Долг",
            value: el.value_cr - el.value_db,*/
  created() {
    this.fitchData();
  },
  computed: {
    dataSort() {
      return this.data.sort((a, b) => {
        return (a.date_doc < b.date_doc) - (a.date_doc > b.date_doc);
      });
    },
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
    dataSort() {
      return this.data.sort((a, b) => {
        return (b.status == 100) - (a.status == 100) || (a.date_start > b.date_start) - (a.date_start < b.date_start);
      });
    },
    filters() {
      const f = this.$store.getters["config/get"].config.taskToday;
      let res = Object.assign(f.work.filters, {
        /*       date_start: {
          condition: "<=",
          value: new Date().date,
        },
 */ date_end: {
          condition: ">=",
          value: new Date().date,
        },
      });
      return res;
    },
  },
  methods: {
    onClick(e) {
      if (e.field?.isMenu) {
        if (["document"].includes(e.field.name)) {
          let menuItems = [
            {
              name: e.field.name,
              title: "Открыть документ",
              action: async () => { 
                this.$refs.quickOpenDoc.openDocument(e.row.base_name, parseInt(e.row.doc_id), e.row);
              },
            },
          ];
          this.showPopupMenu(e.field.name, e.row, e.event, { menuItems });
        } else this.showPopupMenu(e.field.name, e.row, e.event, e);
        return;
      }
    },

    async getBalance() {
      let resp = await this.$axios.post("accounting/records/report-sql", {
        q: `
SELECT tbl.*
,value_cr-value_db AS saldo
,'Не оплачен' AS "status"
,(case account when '60.1' then 'ordersGoods_view' when '60.2' then 'ordersService_view' else '' end) as routeName
,(case account when '60.1' then 'AccountingDocOrderGoodModel' when '60.2' then 'AccountingDocOrderServiceModel' else '' end) as base_name
 FROM (
select account, doc_id, min(date_doc) 'date_doc',sum(value_cr)  'value_cr', sum(value_db) 'value_db'
  ,getSubcontoValue(account,'subconto2',doc_id) AS document
from (
  select id, credit_subconto_value_2 'doc_id', date_doc, credit_account 'account', value 'value_cr', 0 'value_db'
  from accounting_record
  where credit_account like "60%"
  union all
  select id, debit_subconto_value_2 'doc_id', date_doc, debit_account 'account',	0 'value_cr', value 'value_db'
  from accounting_record
  where debit_account like "60%"
)  tt
group by account, doc_id
having sum(value_cr)<>sum(value_db)  ) AS tbl`,
      });
      this.loading = false;
      if (resp.data.status == "ok") {
        this.data = resp.data.data;
      }
    },
    async fitchData() {
      this.loading = true;
      this.getBalance();
      this.afterFitch();
    },
    afterFitch() {
      this.loading = false;
    },
  },
};
</script>
