var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dialog)?_c('dialog-edit',{attrs:{"value":_vm.dialog,"maxWidth":'100hv'},on:{"input":function($event){_vm.dialog = !true}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.params.title)+" ")]},proxy:true},{key:"actions",fn:function(){return [(_vm.basket && _vm.basketData && _vm.basketData.length)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","max-width":"100%","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.basketButtonText))])]}}],null,false,270635561),model:{value:(_vm.basketMenu),callback:function ($$v) {_vm.basketMenu=$$v},expression:"basketMenu"}},[_c('v-card',{staticClass:"ma-0",staticStyle:{"left":"0"}},[(_vm.$refs.cardList)?_c('v-card-text',{staticClass:"pa-2",style:(`height: ${_vm.basketHeight}px; overflow: auto; width:${_vm.$refs.cardList.offsetWidth}px`)},[_c('a-table-f-data2',{ref:"tableData",attrs:{"dataTable":_vm.basketData,"useQuery":false,"model":_vm.basketModel,"searchable":false,"defaults":{
              filters: {},
              sort: { key: 'id', order: 'DESC' },
            }},on:{"click":function($event){return _vm.onClickRow($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.actions",fn:function({ row }){return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"ma-0 ml-0",attrs:{"x-small":"","fab":"","color":"red","title":"Отменить"},on:{"click":function($event){$event.stopPropagation();return _vm.delRow(row)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)],1)]}}],null,false,760907400)})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),(_vm.basket)?_c('a-btn-save',{attrs:{"icon":"fas fa-check","title":'Применить'},on:{"click":function($event){_vm.add();
              _vm.dialog = !true;}}}):_vm._e()],1)],1)],1):_vm._e(),_c('v-spacer'),(!_vm.basket)?_c('a-btn-save',{attrs:{"title":'Выбрать'},on:{"click":function($event){_vm.add();
        _vm.dialog = !true;}}}):_vm._e(),_c('v-btn',{on:{"click":function($event){_vm.dialog = !true;
        _vm.$emit('input');}}},[_vm._v("Закрыть")])]},proxy:true}],null,false,4214246350)},[_c('v-card-text',{ref:"cardList",staticClass:"pa-0 list-card",staticStyle:{"height":"calc(100vh - 160px)","overflow":"auto"}},[(_vm.type == 'api')?_c('a-table-f-api',{ref:"table",attrs:{"api":_vm.params.api,"model":_vm.modelList,"useQuery":false,"limit":0,"defaults":_vm.defaults},on:{"click":function($event){return _vm.onClickRow($event)}}}):_vm._e(),(_vm.type == 'sql')?_c('a-table-f-sql',{ref:"table",attrs:{"query":_vm.params.sql,"model":_vm.modelList,"useQuery":false,"defaults":_vm.defaults},on:{"click":_vm.onClickRow}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }