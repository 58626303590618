/** @format */

const objects = [
  {
    path: '/objects',
    name: 'objects',
    meta: { auth: 1, title: 'Объекты' },
    component: () => {
      return import('@/views/objects.vue');
    },
  },
  {
    path: '/objects-planning',
    name: 'objects-planning',
    meta: { auth: 1, title: 'Объекты-планирование' },
    props: (route) => ({ objectTypeView: 1, ...route.params }),
    component: () => {
      return import('@/views/objects.vue');
    },
  },

  {
    path: '/objects/:id',
    name: 'objects_view',
    meta: { auth: 1, title: 'Объекты' },
    props: (route) => ({ type: route.query.type, tab: route.query.tab, ...route.params }),
    component: () => {
      return import('@/views/views/objectsView.vue');
    },
  },
  {
    path: '/objects/:id/goods',
    name: 'objects_goods',
    meta: { auth: 1, title: 'Объекты' },
    component: () => {
      return import('@/views/views/objectsViewGoods.vue');
    },
  },
  {
    path: '/objects/:id/works',
    name: 'objects_works',
    meta: { auth: 1, title: 'Объекты' },
    component: () => {
      return import('@/views/views/objectsViewWorks.vue');
    },
  },
  {
    path: '/objects-planning/:id',
    name: 'objects-planning_view',
    meta: { auth: 1, title: 'Объекты-планирование' },
    component: () => {
      return import('@/views/views/objectsPlanningView.vue');
    },
  },
  {
    path: '/objects-completed',
    name: 'objectsCompleted',
    meta: { auth: 1, title: 'Завершенные объекты' },
    component: () => {
      return import('@/views/objectsCompleted.vue');
    },
  },
  {
    path: '/objects-completed/:id',
    name: 'objectsCompleted_view',
    meta: { auth: 1, title: 'Завершенный объект' },
    props: (route) => ({ type: route.query.type, tab: route.query.tab, ...route.params }),

    component: () => {
      return import('@/views/views/objectsView.vue');
    },
  },
  {
    path: '/lampObjects',
    name: 'objectsLamp',
    meta: { auth: 1, title: 'Рекламные объекты', objectType: 'lampObjects' },
    component: () => {
      return import('@/views/objectsAgent.vue');
    },
  },
  {
    path: '/lampObjects/:id',
    name: 'objectsLamp_view',
    meta: { auth: 1, title: 'Рекламный объект', objectType: 'lampObjects' },
    component: () => {
      return import('@/views/views/objectsAgentView.vue');
    },
  },
  {
    path: '/agentObjects',
    name: 'objectsAgent',
    meta: { auth: 1, title: 'Агентские объекты', objectType: 'agentObjects' },
    component: () => {
      return import('@/views/objectsAgent.vue');
    },
  },
  {
    path: '/agentObjects/:id',
    name: 'objectsAgent_view',
    meta: { auth: 1, title: 'Агентский объект', objectType: 'agentObjects' },
    component: () => {
      return import('@/views/views/objectsAgentView.vue');
    },
  },
  {
    path: '/partnerObjects',
    name: 'objectsPartner',
    meta: { auth: 1, title: 'Партнерские объекты', objectType: 'partnerObjects' },
    component: () => {
      return import('@/views/objectsAgent.vue');
    },
  },
  {
    path: '/partnerObjects/:id',
    name: 'objectsPartner_view',
    meta: { auth: 1, title: 'Партнерский объект', objectType: 'partnerObjects' },
    component: () => {
      return import('@/views/views/objectsAgentView.vue');
    },
  },
  {
    path: '/projects',
    name: 'projects',
    meta: { auth: 1, title: 'Проекты' },
    component: () => {
      return import('@/views/projects.vue');
    },
  },
  {
    path: '/projects/:id',
    name: 'projects_view',
    meta: { auth: 1, title: 'Проекты' },
    component: () => {
      return import('@/views/views/projectsView.vue');
    },
  },
  {
    path: '/sitePublication',
    name: 'sitePublication',
    meta: { auth: 1, title: 'На сайте' },
    component: () => {
      return import('@/views/sitePublication.vue');
    },
  },
  {
    path: '/sales',
    name: 'sales',
    meta: { auth: 1, title: 'Продажи' },
    component: () => {
      return import('@/views/sales.vue');
    },
  },
  {
    path: '/sales/:type/:id',
    name: 'sales_view',
    meta: { auth: 1, title: 'Продажи', type: 'objects', goUp: { name: 'sales' } },
    component: () => {
      return import('@/views/views/salesView.vue');
    },
  },
  {
    path: '/objectClose',
    name: 'objectClose',
    meta: { auth: 1, title: 'Закрытие объекта' },
    component: () => {
      return import('@/views/objectClose.vue');
    },
  },
  {
    path: '/objectClose/:id',
    name: 'objectClose_view',
    meta: { auth: 1, title: 'Закрытие объекта' },
    component: () => {
      return import('@/views/views/objectCloseView.vue');
    },
  },
];
export default objects;
