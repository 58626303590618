/** @format */

import validate from './validate';

export default {
  mixins: [validate],
  data() {
    return {
      saving: false,
    };
  },
  created() {
    window.addEventListener('keydown', this.pressButtonEvent);
  },
  destroyed() {
    window.removeEventListener('keydown', this.pressButtonEvent);
  },
  methods: {
    pressButtonEvent(e) {
      if (this.submitKey)
        if (e.keyCode == 83 && e.ctrlKey) {
          e.preventDefault();
          this.submit();
          return false;
        }
    },
    async submit(silent = false, fields = '') {
      await this.beforeSave();
      if (this.saving) return;
      this.saving = true;
      console.log('save', this.data);
      let data = {};
      //если есть установка что передаем не все поля в POST запрос
      if (fields) {
        const f = fields.split(',');
        for (let i = 0; i < f.length; i++) {
          const field = f[i];
          data[field] = this.data?.[field] || null;
        }
      } else data = this.data;
      if (await this.validateAll(this.data, silent)) {
        await this.post(data, this.api);
        this.saving = false;
        return true;
      }
      this.saving = false;
      return false;
    },
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        let response = await this.$axios.post(api, data);
        this.loading = false;
        let status = response.data.status == 'ok';
        if (response.data.data) {
          Object.assign(this.data, response.data.data);
          if (!silent)
            this.$root.$emit('show-info', {
              text: 'Данные записаны',
            });
        }
        this.afterSave(data, status);
      } catch (error) {
        this.$root.$emit('show-info', {
          type: 'error',
          text: 'Ошибка при сохранении',
        });
        this.loading = false;
        return;
      }
    },
    afterSave(data, status) {},
    async beforeSave() {},
  },
};
