/** @format */
import modelOptions from './modelOptions';
import getAccess from './getAccess';
import dataUtils from './dataUtils';

export default {
  mixins: [modelOptions, getAccess, dataUtils],
  methods: {
    doWatch(e) {},
    getModelByName(n) {
      return this.model.find((m) => m.name == n) || {};
    },
    generateModel(type, viewName) {
      let r = [];
      let view = this.getConfigView(viewName);
      if (!view.model) return console.log('not find', viewName);
      let dataModel = this.getConfigModel(view.model);
      let dataView = view[type].data;
      for (let i = 0; i < dataView.length; i++) {
        let el = dataView[i];
        let a = { ...el, ...dataModel[el.name] };
        r.push(a);
      }
      return r;
    },
    getConfigModel(name) {
      return this.$root.config.meta.models[name].data;
    },
    getConfigView(name) {
      return this.$root.config.meta.views[name];
    },
    checkModelAccess(el, def = true) {
      const role = this.$root.profile.role;
      let res = def;
      if (el?.access) {
        let access = el.access;
        let role = this.$root.profile.role;
        if (access?.role.length) {
          res = access.role.includes(role) || false;
          if (!res) {
            res = access.role.includes(-1) && !access.role.includes(-role) && !(access?.user && access?.user.includes(-user));
          }
        }
      }
      return res;
    },
    getModelList(m, list = 'list', filter = false) {
      if (!m) return [];
      if (m[list + `_role${this.$root.profile.role}`]) list = list + `_role${this.$root.profile.role}`;
      const model = JSON.parse(JSON.stringify(m[list])) || [];
      //const model = [...m[list]];
      model.forEach((el) => {
        if (!el.hasOwnProperty('style')) {
          el.style = 'white-space: normal;';
        }
        if (el?.isDisableFunc) {
          if (typeof el.isDisableFunc === 'string') {
            el.isDisableFunc = eval(el.isDisableFunc);
          }
        }
        if (!this.checkModelAccess(el)) el.hidden = true;
        /*  if (el?.access) {
          let access = el.access;
          let role = this.$root.profile.role;
          let res;

          if (access?.role.length) {
            res = access.role.includes(role) || false;
            if (!res) {
              res = access.role.includes(-1) && !access.role.includes(-role) && !(access?.user && access?.user.includes(-user));
            }
          }
          if (!res) el.hidden = true;
        } */
        if (el.options) el.options = this.getOptions(el);
        if (el?.sourceList) {
          let mm;
          if (typeof el.sourceList == 'string' && el.sourceList.includes('config.')) {
            mm = this.getObjectPath(this.$store.getters['config/get'] || {}, el.sourceList);
          } else mm = this.m?.[el.sourceList] || m?.[el.sourceList];
          let opts = [];
          if (filter) {
            opts = mm
              ? mm.filter((l) => {
                  return !l?.hidden;
                })
              : [];
          } else {
            opts = mm;
          }
          el.options = [...(opts || [])];
        }
      });
      return model;
    },
    getObjectPath__(o, path) {
      const p = path.split('.');
      let tmp;
      for (let i = 0; i < p.length; i++) {
        const el = p[i];
        if (i === 0) {
          tmp = JSON.parse(JSON.stringify(o || {}));
        }
        tmp = tmp?.[el];
      }
      return tmp;
    },
    calcModel(type, template = 'form', config = null) {
      const mt = Object.assign({}, this.m);
      let modelT = this.getModelList(mt, template, true);
      let source = mt;

      source = this.getObjectPath(source, type) || source;

      //console.log('source', source, modelT);
      const fields = (source?.fields || '').split(',');
      let fieldsRO = (source?.fieldsRO || '').split(',');
      if (this.data?.status == 2) fieldsRO = fields;
      const fieldsForm = (source?.fieldsForm || '').split(',');
      let model = [];
      for (const f of fields) {
        let mm = modelT.find((m) => {
          return m.name == f;
        });
        if (mm && this.checkModelAccess(mm)) model.push(mm);
      }
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
        if (el?.roleShow) {
          //!el.roleShow.includes(this.$root.profile.role)
          el.hidden = !this.checkAccess({ role: el.roleShow }, null, this.$root.profile.role, this.$root.profile.id, true);
          // console.log(el.name, el.hidden, el.roleShow);
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split('#');
        mForm.push({ name: f[0], size: f[1] || 12 });
      });
      this[type] = mForm;
      return model;
    },
    calcModelConfig(config_) {
      let config = Object.assign({}, config_);
      this['modelForm'] = null;
      const mt = Object.assign({}, this.m);
      let modelT = this.getModelList(mt, 'form', true);
      config.fields = config?.fields || modelT.map((m) => m.name).join(',');
      config.fieldsRO = config?.fieldsRO || '';
      config.fieldsForm = config?.fieldsForm || modelT.map((m) => m.name + '#' + (m?.size || 12)).join(',');
      let fields = (config.fields.replace(' ', '').replace(/\s/g, '') || '').split(',');
      let fieldsRO = (config.fieldsRO.replace(' ', '').replace(/\s/g, '') || '').split(',');
      if (this.$root.profile.role == 1000) {
        if (this.$route.query.root) fieldsRO = '';
      }
      let fieldsForm = (config.fieldsForm.replace(' ', '').replace(/\s/g, '') || '').split(',');
      let model = [];
      for (const f of fields) {
        let m = modelT.find((m) => {
          return m.name == f;
        });
        if (m) model.push(m);
      }
      if (config?.fieldsReplace) {
        Object.keys(config.fieldsReplace).forEach((f) => {
          let idx = model.findIndex((el) => {
            return el.name == f;
          });
          if (idx !== -1) model[idx] = config.fieldsReplace[f];
        });
      }
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split('#');
        mForm.push({ name: f[0], size: f[1] || 12 });
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
        if (el?.roleShow && !el.roleShow.includes(this.$root.profile.role)) {
          el.hidden = true;
        }
        if (!this.id && el?.hiddenCreate) {
          el.hidden = true;
        }
        el.size = mForm.find((f) => f.name == el.name)?.size || 12;
      });

      this['modelForm'] = mForm;
      return model;
    },
  },
};
