<template>
  <static-fullscreen-card>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <template v-slot:title> ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} </template>
    <template v-slot:actions>
      <a-btn-add v-if="false" @click="copyLink" icon="fas fa-link" title="Ссылка" />

      <v-btn v-if="data.id" :href="getPreview()" target="_blank" class="mx-1" dark color="info" title="Печать">
        <v-icon dark>fas fa-print </v-icon>
      </v-btn>
      <v-btn v-if="data.type == 1" @click="preview()" color="primary" class="ml-1">
        <v-icon>fas fa-eye</v-icon>
      </v-btn>
      <a-btn-status
        v-if="getAccess('documents.estimate.status', { users: [data.createdby_id] })"
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled="canStatus"
      />
      <a-btn-edit v-if="accessEdit" @click="showEditDialog = true" :disabled1="!canEdit" />
      <a-btn-delete v-if="id && getAccess('documents.estimate.delete', { users: [data.createdby_id] })" :disabled="!canDelete" @click="removeDialogShow = true" />
    </template>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true, hideNull: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" :style1="{ height: blockHeight + 'px' }" @click="getBalance()" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2 pt-1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3" :href="`#tab-${tab.name}`" :disabled="tab.disabled">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <v-card-text v-if="['rooms', 'works', 'goods'].includes(t.name)" :style="styleDetailCard" class="pa-0">
                <a-table-f-data2
                  v-if="data.data_table && tab == `tab-${t.name}`"
                  :ref="'table-' + t.name"
                  :dataTable="data.data_table[t.detailIndex]"
                  :useQuery="false"
                  :model="getDetailsModel(t.name)"
                  :searchable="false"
                  :defaults="{
                    filters: {},
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'documentEstimate-' + t.name,
                  }"
                  @click="onClickRow($event, t)"
                >
                  <template v-slot:item.actions="{ row }">
                    <div class="d-flex" v-if="accessEdit">
                      <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delRow(row.id, t)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-slot:top>
                    <v-speed-dial
                      v-if="accessEdit && (['goods', 'works'].includes(t.name) || (t.name == 'rooms' && data.type == 2))"
                      v-model="fab"
                      open-on-hover
                      left
                      absolute
                      style="bottom: 15px"
                    >
                      <template v-slot:activator>
                        <v-btn v-model="fab" color="blue darken-2" dark fab small>
                          <v-icon v-if="fab"> mdi-close </v-icon>
                          <v-icon v-else> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                      <v-btn fab dark small color="green" @click="onAddDetail(t)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn fab dark small color="indigo" @click="onAddListDetail(t)">
                        <v-icon>mdi-playlist-plus</v-icon>
                      </v-btn>
                    </v-speed-dial>
                    <v-fab-transition v-else-if="accessEdit">
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="onAddDetail(t)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-data2>
              </v-card-text>
              <s-tabs-status-history v-if="t.name == 'statusHistory'" :data="data.status_history" :m="m" :height="detailHeight" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <a-list-custom ref="list" @save="onSelectFromList($event)" basket @updateBasket="updateBasket" />
      <s-document-dialogs-detail-edit
        ref="detailEdit"
        v-if="showDialogEditDetail"
        v-model="showDialogEditDetail"
        :id="idEdit"
        :initData="initDetail"
        :dataTable="data.data_table[detailIndex]"
        :readonly="data.status == 2"
        :RO="fieldsRO"
        :m="configH.m"
        :type="detailName"
        :configForm="{ head: configH.head, title: configH.title, api: configH.api, opt: configH.opt, modelEdit: configH.modelEdit }"
        @onChange="onChangeDetail($event)"
        @save="editDetailAction($event)"
        @refresh="fitchData()"
      />
      <s-document-head v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="m.title" :id="id" :trigger="onChangeHead">
      </s-document-head>
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <a-loader v-if="loading" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
    <change-value-dialog ref="changeValueDialog" />
    <s-editor-editor
      v-if="getAccess('suData')"
      ref="suEdit"
      v-model="suEditShow"
      :config="{ table: 'accounting_doc_estimate', filter: { id } }"
      @show="suEditShow = true"
      :activate="(id || 0) > 0"
    />
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel, statusDoc, saveEl } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel, statusDoc, saveEl],
  props: {
    idShow: Number,
  },
  components: {},
  data() {
    return {
      id: 0,
      idEdit: 0,
      idDeleting: 0,
      fieldsRO: [],
      fab: false,
      suEditShow: false,
      showEditDialog: false,
      showDialogEditDetail: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      showAddDocDialog: false,
      addDocModel: null,
      loading: false,
      loaded: false,
      title: "",
      data: {},
      initDetail: null,
      configH: null,
      m: this.$store.getters["config/get"].models.documentEstimate,
      modelRooms: this.$store.getters["config/get"].models.documentEstimate.listRooms,
      modelGoods: this.$store.getters["config/get"].models.documentEstimate.listGoods,
      modelWorks: this.$store.getters["config/get"].models.documentEstimate.listWorks,
      tab: 0,

      basket: {},
    };
  },
  created() {
    this.loading = true;

    this.$root.title = this.m.title;
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    tabs() {
      console.log(this.data.data?.ap_rooms, (this.data.data_table?.[0] || []).length);
      let d = this.data.data?.ap_rooms != (this.data.data_table?.[0] || []).length;
      return [
        { name: "rooms", title: `Комнаты (${(this.data.data_table?.[0] || []).length})`, show: true, detailIndex: 0, disabled: false },
        { name: "goods", title: "Товары" + ` (${(this.data.data_table?.[1] || []).length})`, show: true, detailIndex: 1, field: "good_id", disabled: d },
        { name: "works", title: "Работы" + ` (${(this.data.data_table?.[2] || []).length})`, show: true, detailIndex: 2, field: "service_id", disabled: d },
        { name: "comments", title: "Комментарии", show: this.getAccess(`documents.estimate.comments`, null, true), disabled: false },
        { name: "statusHistory", title: "История статусов", show: this.getAccess("documents.estimate.statusHistory"), disabled: false },
      ];
    },
    statuses() {
      return this.m.statuses;
    },
    accDocName() {
      return this.m.accDocName;
    },
    api() {
      return this.m.api;
    },
    url() {
      return this.m.api;
    },

    accDocName() {
      return this.m.accDocName;
    },
    accessEdit() {
      return this.getAccess("documents.estimate.edit", { users: [this.data.createdby_id] });
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },

    canEdit() {
      if (this.data.data_table && this.data.data_table.length) return false;
      return true;
    },
    canDelete() {
      if (this.data.data_table && this.data.data_table.length) return false;
      return true;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      let g =
        this.data.data_table?.[1].reduce((a, b) => {
          return a + b.value;
        }, 0) || 0;
      let s =
        this.data.data_table?.[2].reduce((a, b) => {
          return a + b.value;
        }, 0) || 0;

      model = [
        ...model,
        { name: "goods", title: "Сумма товаров", type: "number", value: g },
        { name: "service", title: "Сумма работ", type: "number", value: s },
        { name: "total", title: "Сумма общая", type: "number", value: s + g },
      ];
      return model;
    },
    configHead() {
      return this.m.config.default || {};
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    showDialogEditDetail(v) {
      if (!v) {
        this.fitchData();
      }
    },
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  methods: {
    async onChangeHead(e) {
      let { field, data, value } = e;
      console.log("trigger", e);
      if (field == "object_id" && data?.[field]) {
        let res = await this.$axios("mechti/objects/" + data[field]);
        if (res.data.data) {
          data.object_square = res.data.data.ap_area;
          data.data.ap_level = res.data.data.ap_level;
        }
      }
      if (field == "data.levels") {
        data.data.ap_level = (data.data?.levels || []).length;
      }
      if (field == "data.rooms") {
        data.data.ap_rooms = (data.data?.rooms || []).length;
      }
    },
    async updateBasket({ action, params, item }, save = true) {
      let basket = this.data.data_cache || {};
      let d = basket?.[params.type.name] || [];
      const room = params?.room;

      if (action == "add") {
        const idx = d.findIndex(a => a.id == item.id && (a?.room_id || 0) == (room?.id || 0));
        console.log("find index", idx, d, item.id, room?.id);
        if (idx >= 0) {
          d[idx].amount++;
        } else {
          item.amount = 1;
          item.room_id = room?.id || 0;
          item.room_name = room?.name;
          item.room_class = room?.room_class;
          console.log("add item", item);
          d = [...d, item];
        }
      }
      if (action == "del") {
        const idx = d.findIndex(a => a.id == item.id && (a?.room_id || 0) == (item?.room_id || 0));
        console.log("find index", idx, d, item.id, room?.id);
        console.log("del item", idx);
        if (idx != -1) d.splice(idx, 1);
      }

      basket[params.type.name] = [...d];

      let data = { id: this.id, data_cache: basket };
      if (save) {
        if (!data.id) return;
        const response = await this.save(this.api, data);
        if (response) {
          console.log("save complete", response.data.data.data_cache);
          this.data.data_cache = response.data.data.data_cache;
          const b = this.data.data_cache?.[params.type.name];
          const t = `Корзина (${
            b.reduce((a, b) => {
              return a + b.amount;
            }, 0) || 0
          })`;
          this.$refs.list.setBasket(b, t);
        } else {
          this.data.data_cache = basket;
        }
      }
    },
    getPreview() {
      return this.$root.config.baseUrl + this.api + `/${this.id}/print?token=` + localStorage.getItem("user-token");
    },
    onChangeDetail({ d, e, s }) {
      let n = this.tabs[this.detailIndex].name;
      //console.log("onChangeDetail", { d, e, s });
      let v;
      switch (e) {
        case "goods_class":
          if (true) {
            //ставим цену
            let c = d.goods_class;
            let p = d?.data?.["price_" + c] || 0;
            this.$refs.detailEdit.makeChange("price", p);
          }
          break;
        case "works_class":
          if (true) {
            //ставим цену
            let c = d.works_class;
            let p = d?.data?.["price_" + c] || 0;
            this.$refs.detailEdit.makeChange("price", p);
          }
          break;
        case "room_id":
          const room = this.data.data_table[0].find(r => r.id == d[e]);
          if (n == "goods" || n == "works") {
            if (n == "works") {
              //ставим площадь
              let type = d.data?.measure_type || 1; // получаем единицу расчета 1 - пл.комнаты; 2-стен;
              ///console.log("type square", type, d);
              let square;
              if (type == 1) square = room?.id ? room?.room_square : this.data?.object_square;
              if (type == 2) square = room?.id ? (room?.wall_height || 0) * (room?.wall_length || 0) : 0;
              square = square || 1;
              this.$refs.detailEdit.makeChange("data.square", square);
            }
            let c = room?.id ? room.room_class : this.data.object_class;
            //ставим класс
            this.$refs.detailEdit.makeChange(n + "_class", c);
            this.onChangeDetail({ e: n + "_class", d });
          }
          break;
        case "good_id":
          v = JSON.parse(JSON.stringify(s || null));
          //console.log(e, d, v);
          if (!v) break;
          d.data.price_business = v?.price_business;
          d.data.price_comfort = v?.price_comfort;
          d.data.price_premium = v?.price_premium;
          d.category_id = v?.category_id;
          this.$refs.detailEdit.makeChange("data", d);
          this.onChangeDetail({ e: n + "_class", d });
          break;
        case "service_id":
          v = JSON.parse(JSON.stringify(s || null));
          // console.log(e, d, v);
          if (!v) break;
          d.data.price_business = v?.price_business;
          d.data.price_comfort = v?.price_comfort;
          d.data.price_premium = v?.price_premium;
          d.data.measure_type = v?.data?.measure_type;
          this.$refs.detailEdit.makeChange("data", d);
          this.onChangeDetail({ e: "room_id", d });
          break;
        default:
          break;
      }
    },
    preview() {
      let url = this.$root.config.siteUrl + `/offer/${this.data.estimate_token}.html`;
      //let url = this.$root.config.siteUrl + `/catalog/${this.type}/${this.model.id.toString().md5()}.html`;
      return window.open(url, "_blank");
    },
    copyLink() {},
    async editDetailAction(r) {
      //console.log("editDetailAction", r);
      if (!r) r = this.data.data_table[this.detailIndex];
      const api = this.url;
      const data_table = [];
      data_table[this.detailIndex] = r;
      const data = { id: this.id, data_table };
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные сохранены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка изменения данных",
        });
      }
      this.fitchData();
    },

    async importRooms() {
      if (!this.data.object_id) return;
      const rest = await this.$axios.get("/mechti/objects/" + this.data.object_id);
      if (rest.data && rest.data.data) {
        let d = rest.data.data;
        if (d.data.rooms) {
          const data_table = [];
          const rooms = d.data.rooms;
          rooms.forEach(r => {
            if (!r.data) r.data = {};
            r.data.level = r.level;
            r.data.room_number = r.room_number;
          });
          data_table[0] = [...this.data.data_table[0], ...rooms];
          console.log(d.data.rooms, rooms);
          const data = { id: this.id, data_table };
          const response = await this.save(this.api, data);
          if (response) {
            console.log("save complete", response.data.data.data_table);
            this.data.data_table = response.data.data.data_table;
          }
        }
      }
    },
    afterSave(data) {
      //      this.data.data_table = response.data.data.data_table;
    },
    async onAddListDetail(d) {
      let params;
      if (d.name == "rooms") {
        this.importRooms();
        return;
      }
      if (!this.data.data_cache) this.data.data_cache = {};
      if (d.name == "goods") {
        let ok = false;
        ok = await this.$refs.changeValueDialog.show({
          title: "Выбор",
          message: [`Укажите комнату для добавления товаров`],
          okButton: "Подтвердить",
          cancelButton: "Отмена",
          model: [
            {
              name: "room_id",
              title: "Комната",
              type: "selectroom",
              api: {
                type: "post",
                api: "/report_sql",
                data: {
                  name: "getEstimateRoomList",
                  params: { parent_id: this.id, id: -1 },
                },
              },
              validator: ["req1"],
            },
          ],
        });
        if (!ok || !ok?.status) return;
        const room = ok?.selected?.value;
        let list = this.data.data_table[d.detailIndex]
          .map(o => {
            if (room?.id == o.room_id || !room?.id) return o.good_id;
          })
          .filter(l => l);
        console.log({ room, list });
        let model = [
          { name: "id", title: "#", type: "id", sortable: true, width: 50 },
          { name: "name", title: "Название", type: "string", style: "white-space: normal;", sortable: true, width: 200 },
          { name: "category_id", title: "Категория", type: "select", dir: "category", dirFilter: { type: 2 }, sortable: true, width: 200 },
          {
            name: "rooms_types",
            title: "Тип комнаты",
            type: "select",
            options: "room_type",
            filterJson: { type: "json_contains" },
            chips: true,
            sortable: true,
            width: 200,
          },
          { name: "price_comfort", title: "Цена (комфорт)", type: "number", sortable: true, width: 100 },
          { name: "price_business", title: "Цена (бизнес)", type: "number", sortable: true, width: 100 },
          { name: "price_premium", title: "Цена (премиум)", type: "number", sortable: true, width: 100 },
        ];
        params = {
          basket: {
            data: this.data.data_cache?.[d.name] || [],
            model: [
              { name: "name", title: "Название", type: "string", style: "white-space: normal;", sortable: true, width: 200 },
              { name: "category_id", title: "Категория", type: "select", dir: "category", dirFilter: { type: 2 }, sortable: true, width: 200 },
              { name: "price_comfort", title: "комфорт", type: "number", sortable: true, width: 80 },
              { name: "price_business", title: "бизнес", type: "number", sortable: true, width: 80 },
              { name: "price_premium", title: "премиум", type: "number", sortable: true, width: 80 },
              { name: "amount", title: "Кол-во", type: "number", sortable: true, width: 100 },
              { name: "room_name", title: "Комната", type: "string", style: "white-space: normal;" },
            ],
          },
          room,
          type: d,
          title: "Выбор товаров" + (room?.id ? ` для комнаты [${room.name}]` : ""),
          api: "/mechti/goods_type",
          multiple: true,
          model,
          sort: { key: "name", order: "ASC" },
          filters: { status: 1, rooms_types: room?.room_type ? { type: "json_contains", value: room.room_type } : {} },
          where: list.length ? { id: { condition: "not in", value: list } } : {},
        };
        console.log("params ", d.name, params, this.data.data_cache);
        //, rooms_types: { type:"JSON_CONTAINS" condition:'$' "JSON_CONTAINS", value: 10 }
      }
      if (d.name == "works") {
        let ok = false;
        ok = await this.$refs.changeValueDialog.show({
          title: "Выбор",
          message: [`Укажите комнату для добавления товаров`],
          okButton: "Подтвердить",
          cancelButton: "Отмена",
          model: [
            {
              name: "room_id",
              title: "Комната",
              type: "selectroom",
              api: {
                type: "post",
                api: "/report_sql",
                data: {
                  name: "getEstimateRoomList",
                  params: { parent_id: this.id, id: -1 },
                },
              },
            },
          ],
        });
        if (!ok || !ok?.status) return;
        const room = ok?.selected?.value;
        let list = this.data.data_table[d.detailIndex].map(o => {
          return o.service_id;
        });
        params = {
          basket: {
            data: this.data.data_cache?.[d.name] || [],
            model: [
              { name: "category_id", title: "Категория", type: "select", dir: "category", sortable: true, width: 200 },
              { name: "name", title: "Название", type: "string", style: "white-space: normal;", sortable: true, width: 200 },
              { name: "price_comfort", title: "комфорт", type: "number", sortable: true, width: 80 },
              { name: "price_business", title: "бизнес", type: "number", sortable: true, width: 80 },
              { name: "price_premium", title: "премиум", type: "number", sortable: true, width: 80 },
              { name: "amount", title: "Кол-во", type: "number", sortable: true, width: 50 },
              { name: "room_name", title: "Комната", type: "string", style: "white-space: normal;" },
            ],
          },
          room,
          type: d,
          title: "Выбор работ ",
          api: "accounting/dir/service",
          multiple: true,
          model: [
            { name: "id", title: "#", type: "id", sortable: true, width: 50 },
            { name: "category_id", title: "Категория", type: "select", dir: "category", dirFilter: { value: [27, 28, 29] }, width: 120 },
            { name: "name", title: "Наименование", type: "string", sortable: true, width: 300, style: "white-space: normal;", align: "left" },
            { name: "price_comfort", title: "Цена (комфорт)", type: "number", sortable: true, width: 80 },
            { name: "price_business", title: "Цена (бизнес)", type: "number", sortable: true, width: 80 },
            { name: "price_premium", title: "Цена (премиум)", type: "number", sortable: true, width: 80 },
            { name: "description", title: "Описание", type: "text", width: 300, formula: `((d) => {return d?.data?.description ? d.data.description : "";})` },
            { name: "status", title: "Статус", type: "select", options: "status_active", sortable: true, width: 300, hidden: true },
          ],
          sort: { key: "name", order: "ASC" },
          filters: { category_id: [28, 27, 29], status: 1 },
          where: list.length ? { id: { condition: "not in", value: list } } : {},
        };
        //, id: { condition: "in", value: [-1, ...list] }
      }
      if (!params) return;
      // console.log(this.$refs.list);
      this.$refs.list.init(params);
    },
    async onSelectFromList({ type, rows, params }) {
      if (!type) return;
      let data_table = [];
      let data = [];

      rows.forEach(r => {
        let d = { price_comfort: r?.price_comfort, price_business: r?.price_business, price_premium: r?.price_premium };
        let room_class = (params?.isBasket ? r.room_class : params?.room?.room_class) || this.data.object_class;
        let el = {
          [type.field]: r.id,
          [type.name + "_class"]: room_class,
          room_id: params?.isBasket ? r.room_id || null : params?.room?.id,
          amount: r?.amount || 1,
          data: d,
          price: d["price_" + room_class],
        };
        if (type.name == "goods" || type.name == "works") el.value = el.amount * el.price;
        if (r?.category_id) el.category_id = r?.category_id;
        data.push(el);
        this.updateBasket({ action: "del", params, item: r }, false);
      });

      data_table[type.detailIndex] = [...this.data.data_table[type.detailIndex], ...data];

      if (data_table[type.detailIndex].length) {
        const data = { id: this.id, data_table, data_cache: this.data.data_cache };
        let response = await this.$axios.post(this.api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные сохранены",
          });
          this.data.data_table = response.data.data.data_table;
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка",
          });
        }
      }
    },
    getDetailsModel(n) {
      //return this.m["list" + n.upFirst()];
      return this.m[n]["list"];
    },

    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close", this.data);
      else this.$router.push({ name: "estimate" });
    },
    afterFetchData(r) {
      if (this.id) {
        //   this.calcModel();
      }
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },

    async delRow(id, d) {
      if (this.idDeleting) return;
      this.idDeleting = id;

      if (d.detailIndex === 0) {
        if (this.data.data_table[1].find(d => d.room_id == id) || this.data.data_table[2].find(d => d.room_id == id)) {
          //console.log(id);
          this.$root.$emit("show-info", {
            type: "error",
            text: "К комнате привязаны товары или работы. Удаление невозможно",
          });
          this.idDeleting = 0;
          return;
        }
      }
      const api = this.url;
      const data_table = [];
      data_table[d.detailIndex] = this.data.data_table[d.detailIndex].filter(el => el.id !== id);
      const data = { id: this.id, data_table };
      try {
        let response = await this.$axios.post(api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные удалены",
          });
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка удаления ",
          });
        }
      } catch (error) {}
      this.idDeleting = 0;
      this.fitchData();
    },
    makeConfig(d, id = 0) {
      try {
        const config = {};
        config.head = this.m[d.name]?.config?.default || {};
        config.id = id;
        config.api = null;
        config.opt = "";
        config.m = this.m[d.name];
        if (d.name === "rooms") {
          const level = this.data?.data?.ap_level || 1;
          console.log("level", level);
          const list = [];
          for (let i = 0; i < level; i++) {
            list.push({ value: i + 1, text: i + 1 });
          }
          config.modelEdit = model => {
            try {
              model.find(m => m.name == "data.level").options = list;
            } catch (error) {
              console.log("error");
            }
            return model;
          };
        }
        //console.log("make config ", config, this.data);
        return config;
      } catch (error) {}
      return null;
    },

    onAddDetail(d) {
      this.idEdit = 0;
      this.detailIndex = d.detailIndex;
      this.detailName = d.name;
      this.fieldsRO = [];
      this.initDetail = null;
      if (d.name === "goods" || d.name === "works") {
        this.initDetail = { parent_id: this.id };
      }
      //console.log({ d, initData: this.initDetail });
      this.configH = this.makeConfig(d);
      this.showDialogEditDetail = true;
    },
    onClickRow(e, d = {}) {
      this.configH = null;
      if (e.field?.isMenu) {
        let name = e.field?.alias || e.field.name;
        let id = e.row?.[e.field.name];
        if (id) this.showPopupMenu(name, e.row, e.event, { task: { id: e.row[e.field.name] } });
      } else if (e.field.name == "actions") {
      } else {
        if (["rooms", "works", "goods"].includes(d.name)) {
          this.idEdit = e.row.id;
          this.detailIndex = d.detailIndex;
          this.detailName = d.name;
          this.fieldsRO = [];
          this.configH = this.makeConfig(d, e.row.id);

          this.showDialogEditDetail = true;
          return;
        }
      }
    },
  },
};
</script>
