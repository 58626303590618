<template>
  <li draggable="false" @dragstart="dragstart($event)" @dragover.prevent @drop="dragdrop($event)" class="v-tree-item-li">
    <span v-if="isTableView" @click="openTable = true" style="padding-right: 5px">
      <i class="fas fa-table"></i>
    </span>
    <div :class="{ folder: isFolder }" class="v-tree-item" style="">
      <span v-if="isFolder" @click="toggle" style="padding-right: 5px">
        <template v-if="open">
          <i class="fas fa-folder-minus"></i>
        </template>
        <template v-else>
          <i class="fas fa-folder-plus"></i>
        </template>
      </span>

      <span v-else>
        <i class="fas fa-angle-right"></i>
      </span>
      <span class="v-tree-item-caption">
        {{ showType }}
        <template v-if="isFolder">{{ index }}</template>
        <template v-else>{{ index }} :</template>
      </span>
      <span title="remove" @click="onClickRemove" v-if="isFolder && canEdit" style="margin-left: 5px">
        <i style="color: red" class="fas fa-times"></i>
      </span>
      <span v-if="isFolder" title="rename" @click="onClickRename" style="margin-left: 5px">
        <i style="color: blue" class="fas fa-pencil-alt"></i>
      </span>
      <span v-if="isFolder" title="code" @click="onClickCode" style="margin-left: 5px">
        <i style="color: blue" class="fas fa-code"></i>
      </span>
      <span v-if="isFolder && typeof index == 'string' && (index.includes('form') || index.includes('list'))" title="form" @click="onClickForm" style="margin-left: 5px">
        <i style="color: blue" class="fas fa-th-list"></i>
      </span>
    </div>
    <div v-if="isFolder">
      <div>
        <ul v-show="open">
          <tree-item
            @remove="onRemove($event)"
            @change="onChange($event, index)"
            @rename="onRename($event, index)"
            @code="onCode($event, index)"
            @copy="onCopy($event, index)"
            class="item"
            v-for="(el, index) in data"
            :key="index"
            :index="index"
            :el="el"
            :canEdit="canEdit"
          />
          <tree-add-item v-if="canEdit" :el="data" @change="onChangeAddItem"></tree-add-item>
        </ul>
      </div>
    </div>
    <div v-else style="display: inline">
      <span v-if="isEdited">
        <span v-if="typeof data === 'string'">
          <input ref="inputEl" @change="onChangeInput" @blur="onBlurInput" v-model="data" style="width: 100%" />
        </span>
        <span v-else-if="typeof data === 'number' || data === null">
          <input ref="inputEl" @change="onChangeInput" @blur="onBlurInput" v-model="data" type="number" />
        </span>
        <span v-else-if="typeof data === 'boolean'">
          <select ref="inputEl" @change="onChangeInput" @blur="onBlurInput" v-model="data">
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </span>
        <span v-else-if="typeof data === 'function'">
          <textarea ref="inputEl" @change="onChangeInput" @blur="onBlurInput" v-model="data" style="width: 100%" />
        </span>
        <span v-else> {{ typeof data }}</span>
      </span>
      <span v-else>
        {{ showType }}
        <span class="tree-value">{{ data }}</span>
        <span v-if="canEdit" title="edit" @click="onClickEdit" style="padding-left: 5px">
          <i class="fas fa-pencil-alt"></i>
        </span>
      </span>
      <span v-if="canEdit" title="remove 2" @click="onClickRemove" style="margin-left: 5px">
        <i style="color: red" class="fas fa-times"></i>
      </span>
    </div>
    <tree-table :el="el" v-if="openTable" v-model="openTable" @change="onChangeTable"></tree-table>
  </li>
</template>

<script>
import treeAddItem from "@/views/tree/treeAddItem";
import treeTable from "@/views/tree/treeTable";

export default {
  name: "tree-item",
  components: {
    treeAddItem,
    treeTable,
  },
  props: {
    el: [Array, Object, String, Number, Boolean, Function],
    index: [Array, Object, String, Number],
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      open: false,
      isEdited: false,
      value: "",
      addType: 0,
      addTypes: ["String", "Number", "Array", "Object", "Function"],
      drag_src_el: null,
      openTable: false,
    };
  },
  computed: {
    showType() {
      let v = this.data;
      if (v === null) return "[N]";
      if (typeof v === "string") return "[S]";
      if (typeof v === "number") return "[N]";
      if (typeof v === "boolean") return "[B]";
      if (typeof v === "function") return "[F]";
      if (Array.isArray(v)) return "[A]";
      return "[O]";
    },
    data: {
      get: function () {
        if (!Array.isArray(this.el) && typeof this.el === "object" && this.el !== null) {
          const ordered = (Object.keys(this.el) || {}).sort().reduce((obj, key) => {
            obj[key] = this.el[key];
            return obj;
          }, {});
          return ordered;
        }
        return this.el;
      },
      set: function (v) {
        this.value = v;
      },
    },
    isFolder: function () {
      if (this.el === null) return false;
      return Array.isArray(this.el) || typeof this.el === "object";
    },
    isTableView: function () {
      return Array.isArray(this.el);
    },
  },
  methods: {
    onClickRemove: function (e) {
      if (confirm("Вы уверены, что хотите удалить? 2")) {
        this.$emit("remove", this.index);
      }
    },
    onClickRename: function (e) {
      this.$emit("rename", this.index);
    },
    onClickCode: function (e) {
      this.$emit("code", this.index);
    },
    onClickForm: function (e) {
      this.$emit("form", this.index);
    },
    onRemove: function (e) {
      let t = this.el;
      if (Array.isArray(t)) {
        t.splice(e, 1);
      } else if (typeof t === "object") {
        delete t[e];
      }
      this.$emit("change", t);
      this.$forceUpdate();
    },
    onChange: function (e, index) {
      let t = this.el;
      t[index] = e;
      this.$emit("change", t);
      this.$forceUpdate();
    },
    onCopy(e) {
      console.log("onCopy1:", e);
      //this.data[e.i] = e.v;
    },
    onRename(e) {
      console.log("onRename1:", e);
      let res = prompt("Введите новое название");
      if (res) {
        console.log("do rename");
        let t = this.el;
        t[res] = t[e];
        this.$emit("change", t);
        this.$forceUpdate();

        //this.data[e.i] = e.v;
      }
    },

    async onCode(e) {
      this.$emit("code", this.index + "." + e);
    },
    onChangeAddItem: function (e) {
      let el = this.data;
      if (Array.isArray(this.data)) {
        el.push(e.v);
      } else {
        el[e.i] = e.v;
      }
      this.$emit("change", el);
      this.$forceUpdate();
    },
    onChangeInput: function (e) {
      // this.onBlurInput(e);
    },
    onChangeTable: function (e) {
      let el = this.data;

      console.log("onChangeTable", { e, el });
      this.$emit("change", e);
    },
    onBlurInput: function (e) {
      this.isEdited = false;
      if (typeof this.el === "number" || this.el === null) {
        this.value = Number(this.value);
        if (typeof this.value === "number") {
          this.$emit("change", this.value);
        }
      }
      if (typeof this.el === "string") {
        this.value = String(this.value);
        if (this.value.length > 0) {
          this.$emit("change", this.value);
        }
      }
      if (typeof this.el === "boolean") {
        this.value == "true" || this.value === true ? (this.value = true) : (this.value = false);
        this.$emit("change", this.value);
      }
      this.$forceUpdate();
    },
    onClickEdit: function () {
      this.isEdited = true;
      this.$nextTick(() => this.$refs.inputEl.focus());
    },
    toggle: function () {
      if (this.isFolder) {
        this.open = !this.open;
      } else {
        // this.$root.$emit('reload-catalog')
      }
    },
    dragstart: function (e) {
      // if (this.$root._drag_src_el == null) {
      //   this.$root._drag_src_el = { el: this.el, i: this.index }
      //   e.srcElement.set;
      //   e.dataTransfer.effectAllowed = "move";
      //   console.log("dragstart", this.$root._drag_src_el );
      // }
    },
    dragdrop: function (e) {
      // this.$root._drag_src_el = null
      // console.log("dragdrop", e);
      // if (e.stopPropagation) {
      //   e.stopPropagation();
      // }
      // let array = this.value;
      // if (this.drag_src_el != i) {
      // let data = array[this.drag_src_el];
      // array.splice(this.drag_src_el, 1);
      // array.splice(i, 0, data);
      // self.drag_src_el = null;
      // }
      // this.$emit("input", array);
    },
  },
};
</script>

<style lang="scss">
.v-tree-item {
  display: inline;
}
.v-tree-item-li {
}
.v-tree-item-caption {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
}
</style>
