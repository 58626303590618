<template>
  <div>
    <a-form-modelCols
      v-model="data"
      class="pb-1 mx-1 pt-1"
      :model="modelL"
      :values="data"
      :errors="errors"
      :config="{ dense: true, readonly, hideDetails: true }"
      @validate="onInput($event)"
    />
  </div>
</template>

<script>
import { getForm, getAccess, libChecks, submitForm, dataUtils } from "@/components/mixings";
export default {
  mixins: [getForm, libChecks, getAccess, submitForm, dataUtils],
  props: {
    value: Object,
    model: Object,
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
    object: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    messages() {
      return (this.config?.opt?.messages || []).map(m => {
        return { text: `#${m.id} ${m.content}`, value: m.id };
      });
    },
    modelL() {
      let model = [];
      let m = {};

      if (typeof this.model.model === "string") {
        m = this.getObjectPath(this.$store.getters["config/get"].models, this.model.model);
        model = this.getFormModel(m.config.default.fieldsForm.split(","), m.form);
      } else if (Array.isArray(this.model.model)) {
        model = this.model.model;
      }

      // console.log("array model", m, this.model);

      //console.log("array model2", model, m.config.default.fieldsForm, m.form);
      return model;
    },
  },
  created() {
    this.updateData();
  },
  watch: {
    value(v) {
      this.updateData();
    },
  },
  methods: {
    fillForm() {
      if (this.modelL) this.fillFormFromModel(this.modelL);
    },
    async fetchData() {
      this.loading = true;
      let data = this.value || {};
      if (!data) this.isChanged = true;
      for (const name in data) {
        console.log("set ", name, data[name]);
        this.$set(this.data, name, data[name]);
        this.$set(this.errors, name, null);
      }
      this.loading = false;

      this.afterFetchData(data);
    },
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        this.loading = false;
        this.$emit("commit", data);
        this.afterSave(data);
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
        this.loading = false;
        return;
      }
    },

    onInput(e) {
      this.$emit("change");
      this.$emit("input", this.data);
      this.submit(true);
    },
  },
};
</script>
