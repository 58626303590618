<template>
  <static-fullscreen-card @sendHeight="sendHeight($event)">
    <context-menu ref="headerContextMenu" :items="[{ name: 'createActWork', title: `Создать 'Акт выполненных работ'` }]" @clickData="onContextMenuClick($event)" />
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <template v-slot:title>({{ data.id }}) {{ data.name }}</template>

    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" v-if="$root.profile.id == 13 && false" @click="showWorkTemplate = true">
        <btn-title icon="fas fa-list">Шаблон</btn-title>
      </v-btn>

      <v-btn color="primary" @click="moveSchedule()" :loading="isMakeTasks" v-if="!gantView && getAccess('object.taskMake')">
        <v-icon>mdi-timer-settings-outline</v-icon>
      </v-btn>
      <v-btn color="primary" @click="makeTasks()" :loading="isMakeTasks" :disabled="isMakeTasks || !needMakeTask" v-if="!gantView && getAccess('object.taskMake')">
        <span class="hidden-md-and-down">Утвердить работы</span>
        <v-icon>fas fa-tasks</v-icon>
      </v-btn>
      <v-btn color="primary" @click="gantView = !gantView" class="px-2">
        <v-icon v-if="gantView">fas fa-list</v-icon>
        <v-icon v-else>fas fa-project-diagram</v-icon>
        <btn-title class="hidden-md-and-down">{{ gantView ? "Таблица" : "Диаграмма" }}</btn-title>
      </v-btn>
      <a-btn-refresh @click="$refs.table.updateData()" v-if="!gantView" />
      <a-btn-add @click="createNew()" v-if="!gantView && editObject" />
    </template>
    <portal to="v-main">
      <s-edit-form-async ref="editField" />
      <edit-dialog v-model="showEditDialog" :id="idEdit" :object_="data" v-if="editObject" :lastRow="lastData" />
      <select-dialog v-model="showCashSelectDialog" v-if="showCashSelectDialog" :operations="[47, 65]" @select="onSelectOperation($event)" />
      <CashDialog ref="cashDialog" @updateData="$refs.table.reloadData()" />
      <s-quickOpenDoc ref="quickOpenDoc" />
      <ActWorkView v-if="editObject && showActWorkDialog && idActWork && false" :idShow="idActWork" @close="itemShowClose($event)" />
      <ActWorkDialog
        v-if="editObject && !idActWork"
        ref="actWork"
        v-model="showActWorkDialog"
        :id="idActWork"
        @refresh="$refs.table.updateData(idActWork)"
        @input="showActWorkDialog = false"
        @create="idActWork = $event"
      />
      <FurnitureReportDialog
        v-if="editObject"
        ref="furnitureReport"
        v-model="showFurnitureReportDialog"
        :id="idFurnitureReport"
        @refresh="$refs.table.updateData(idFurnitureReport)"
        @input="showFurnitureReportDialog = false"
        @create="idFurnitureReport = $event"
      />
    </portal>
    <a-table-f-api
      v-if="!gantView && !loading"
      ref="table"
      :api="url"
      :model="model"
      :useQuery="false"
      :limit="0"
      :defaults="defaults"
      @rowContextMenu="onRowContextMenu($event)"
      @lastRow="setLastRow($event)"
      @click="onClickRow($event)"
      @loadData="onLoadData($event)"
      @dataUpdated="dataMdf()"
    >
      <template v-slot:item.actions="{ row }">
        <div class="d-flex">
          <a-btn-status
            :key="row.id"
            :items="getStatuses(row)"
            @click="clickStatus($event, row)"
            :disabled="false"
            :fab="true"
            :status="row.status"
            :data="row"
          ></a-btn-status>
          <v-btn
            v-if="row.act_work_id"
            x-small
            fab
            class="ma-0 ml-1"
            color="green"
            title="Акт"
            @click.stop="
              $refs.quickOpenDoc.openDocument('AccountingDocActWork', row.act_work_id, row);
              idActWork = row.act_work_id;
              showActWorkDialog1 = true;
            "
          >
            <v-icon small>fas fa-file-invoice</v-icon>
          </v-btn>
          <v-btn
            v-if="row.furniture_report_id"
            x-small
            fab
            class="ma-0 ml-1"
            color="green"
            title="Отчет по мебели"
            @click.stop="
              idFurnitureReport = row.furniture_report_id;
              showFurnitureReportDialog = true;
            "
          >
            <v-icon small>fas fa-camera</v-icon>
          </v-btn>
          <v-btn
            v-if="row.act_work_id && row.cashInfo && row.cashInfo.cashValue < row.work_cost && row.cashInfo.status == 2"
            x-small
            fab
            class="ma-0 ml-1"
            color="green"
            title="Оплата"
            @click.stop="
              dataClick = row;
              showCashSelectDialog = true;
            "
          >
            <v-icon small>fas fa-dollar-sign</v-icon>
          </v-btn>
        </div>
      </template>
    </a-table-f-api>
    <div v-else-if="!loading">
      <a-gantt-elastic :url="url" :object_id="id" :config="{ height: gantHeight, taskList: switchGant }" />
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </static-fullscreen-card>
</template>

<script>
import cash from "@/components/s/document/cash";
import { getAccess, validate, genModel, modelOptions, saveEl } from "@/components/mixings";
export default {
  mixins: [getAccess, validate, genModel, modelOptions, saveEl, cash],
  components: {
    editDialog: () => import("@/views/dialogs/workDialog"),
    ActWorkDialog: () => import("@/views/documents/dialogs/actWorkDialog"),
    ActWorkView: () => import("@/views/documents/views/actWorkView"),

    FurnitureReportDialog: () => import("@/views/dialogs/objectFurnitureReportDialog"),
    CashDialog: () => import("@/views/documents/dialogs/cashDialog"),
    SelectDialog: () => import("@/views/documents/dialogs/cashOperationSelectDialog"),

    // workTemplate: () => import("@/views/dialogs/workTemplateDialog"),
  },
  data() {
    return {
      idEdit: 0,
      idActWork: 0,
      idFurnitureReport: 0,
      showEditDialog: false,
      showWorkTemplate: false,
      showActWorkDialog: false,
      showFurnitureReportDialog: false,
      showCashSelectDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.works,
      data: {},
      dataClick: {},
      gantView: false,
      switchGant: true,
      gantHeight: 0,
      isMakeTasks: false,
      needMakeTask: false,
      url: "/mechti/construction-schedule",

      balance: {
        debit: 0,
        credit: 0,
      },
      lastData: {},
      loading: false,
      cashInfo: {},
      statuses_: {},
      editField: null,
    };
  },
  created() {
    //  console.log("this.$options.components", this.$options);

    this.$root.title = "График работ";
    this.fitchData();
  },
  computed: {
    defaults() {
      let res = {
        sort: [
          { key: "date_start", order: "ASC" },
          { key: "id", order: "ASC" },
        ],
        filters: { object_id: this.id },
        paramName: "work",
      };
      return res;
    },
    statuses() {
      //return this.$root.taskStatuses
      let res = this.$store.getters["config/get"].models.tasks.statuses || [];
      res = res.filter((e) => e.type == "work");
      res = [
        { value: -2, text: "Отменено", next: [], action: "Отменить работу", field: "status" },
        { value: -1, text: "Черновик", next: [], action: "", field: "status" },
        ...res,
      ];
      return res;
    },
    model: {
      get() {
        let model = this.getModelList(this.m, "list", true);
        model.find((m) => m.name == "status").options = this.statuses;
        return model;
      },
    },
    modelForm: {
      get() {
        let model = this.getModelList(this.m, "form", true);
        if (this.data.work_days) model.find((el) => el.name == "date_end").readonly = true;
        return model;
      },
    },
    id() {
      return Number(this.$route.params.id);
    },
    editObject() {
      return this.getAccess("object.workEdit", {
        access: this.data.object_access || [],
      });
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    getStatuses(r) {
      //return this.statuses;
      if (Object.keys(this.statuses_).includes(r.id)) {
        return this.statuses_[r.id];
      } else {
        const service = (this.$root.dirs["service"] || []).find((s) => s.value == r.service_id);
        let res = JSON.parse(JSON.stringify(this.statuses));
        if (service && service.data?.needCompleteDoc === 0) {
          // console.log("needCompleteDoc", r.id, service);
          res.find((s) => s.value == 101).next = [-2, 102];
        }
        this.statuses_[r.id] = [...res];
      }
      return this.statuses_[r.id];
    },
    async onEditField(e) {
      return this.$refs.editField.show(e);
    },
    async moveSchedule() {
      const list = ["Дизайнер", "Стройка", "Снабжение", "Мебель", "Комплектация"];
      let res = await this.onEditField({
        title: "Сдвиг графика работ",
        data: { department: "" },
        model: [
          // { name: "department", title: "Отдел", type: "select", options: list, validator: ["req"], size: 8 },
          { name: "days", title: "Дней", type: "number", validator: ["req"], size: 12 },
        ],
      });
      if (res) {
        console.log("yes", res);
        try {
          let data = { days: res.days, object_id: this.id };
          console.log(data);
          let resp = await this.$axios.post("mechti/construction-schedule/move", data);
          this.$refs.table.updateData();
        } catch (error) {
          this.$root.$emit("show-info", { type: "error", text: "Произошла ошибка. Получение работ на объекте" });
          console.error(error);
        }
        //mechti/construction-schedule/move
      } else {
        console.log("no", res);
      }
      return false;
    },
    onSelectOperation(e) {
      if (!e.value) return;
      this.$refs.cashDialog.openDialog(e.id, 0, {
        id: this.dataClick.act_work_id,
        value: this.dataClick.work_cost,
        vendor_id: this.dataClick.cashInfo.vendor_id,
        operation_name: e.text,
      });
      this.dataClick = {};
    },
    async dataMdf() {
      let name = "table";
      let data = this.$refs[name].data;
      if (!data) return;
      for (let i = 0; i < data.length; i++) {
        let d = data[i];
        let cash = await this.isNeedCash(d);
        this.$refs[name].data[i].cashInfo = cash;
        this.$refs[name].$forceUpdate();
      }
    },
    async isNeedCash(r) {
      let id = r.act_work_id;
      if (!id) return null;
      //api: '/report_sql', data: { name: 'getBuyDoc', params: { type: 1 } } },
      let resp = await this.$axios.post("/report_sql", { name: "getCashActWork", params: { id } });
      if (resp.data.status == "ok") {
        return resp.data.data[0];
      }
      return null;
    },
    async clickStatus(status, row) {
      this.newStatus = null;
      let ok = false;

      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите поменять статус на [${status.text}]?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      if (status.func) {
        this[status.func](status, row);
      } else {
        this.changeStatus(status, row);
      }
    },

    async changeStatus(status, row) {
      if (await this.validateData(row, this.modelForm)) {
        let data = { id: row.id, status: status.value };
        if (status.value == 101) data.performer_id = this.$root.profile.id;
        let response = await this.save(this.url, data);
        if (response && response.data.data) {
          this.$refs.table.updateData();
          this.$root.$emit("show-info", {
            text: "Статус изменен",
          });
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Статус не изменен",
          });
        }
      } else {
        this.$root.$emit("show-info", {
          text: `${row.id} Не верно заполнен график`,
          type: "error",
        });
      }
    },
    async statusCreateFurnitureReport(status, row) {
      let d = row;
      if (await this.validateData(d, this.modelForm)) {
        this.idFurnitureReport = 0;
        this.showFurnitureReportDialog = true;
        let u_id = d.performer_id || d.createdby_id;
        let data = {
          object_id: d.object_id,
          construction_schedule_id: d.id,
          user_id: u_id,
          createdby_id: u_id,
          description: d.name,
          value: d.work_cost,
          parent_name: "ConstructionScheduleModel",
          parent_id: d.id,
        };
        this.$nextTick(() => {
          this.$refs.furnitureReport.inputData(data);
        });
      } else {
        this.$root.$emit("show-info", {
          text: `${d.id} Не верно заполнен график`,
          type: "error",
        });
      }
    },
    statusCreateActWork(status, row) {
      this.doCreateActWork(row);
    },
    onContextMenuClick(e) {
      console.log("menuData", e);
      this.doCreateActWork(e.data);
    },
    async doCreateActWork(d) {
      if (await this.validateData(d, this.modelForm)) {
        this.idActWork = 0;
        this.showActWorkDialog = true;
        let u_id = d.performer_id || d.createdby_id;
        let data = {
          object_id: d.object_id,
          service_id: d.service_id,
          construction_schedule_id: d.id,
          //          user_id: d.createdby_id,
          user_id: u_id,
          createdby_id: u_id,
          operation_type: 46,
          work_name: d.name,
          value: d.work_cost,
          parent_name: "ConstructionScheduleModel",
          parent_id: d.id,
        };
        console.log("doCreateActWork");
        this.$nextTick(() => {
          console.log(this.$refs.actWork);
          this.$refs.actWork.inputData(data);
        });
      } else {
        this.$root.$emit("show-info", {
          text: `${d.id} Не верно заполнен график`,
          type: "error",
        });
      }
    },
    getStatusItems(s) {
      let items = [];
      let res = [];
      let current = {};
      let idx = this.$root.taskStatuses.findIndex((el) => {
        return el.value == s;
      });
      let statuses = this.getOptions(this.model.find((m) => m.name == "status"));
      idx = statuses.findIndex((el) => el.value == s);
      if (idx != -1) current = JSON.parse(JSON.stringify(statuses[idx]));

      if (current?.["next"]) {
        let arr = current["next"];
        res = statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    onRowContextMenu(e) {
      if (e.row.status == 101) this.$refs.headerContextMenu.showMenu(e.event, e.row);
    },
    sendHeight(e) {
      this.gantHeight = e;
    },
    async fitchData() {
      this.loading = true;

      const d = await this.$axios.get("/mechti/objects" + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.loading = false;
    },
    onClickRow(e) {
      if (this.$root.profile.role <= 20) return;
      if (e.field?.isMenu) {
        let name = e.field?.alias || e.field.name;
        let id = e.row?.[e.field.name];
        if (id) this.showPopupMenu(name, e.row, e.event, { task: { id: e.row[e.field.name] } });
      } else if (e.field.name == "actions") {
      } else {
        this.showEditDialogFun(e.row.id);
      }
    },
    createNew() {
      console.log("click createNew");
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    setLastRow(v) {
      this.lastData = v;
    },
    onLoadData(e) {
      this.needMakeTask =
        e.filter((el) => {
          return el.status == -1;
        }).length > 0;
    },
    async makeTasks() {
      const myError = (message) => {
        return { message, type: 1 };
      };
      let ok = false;
      let rows = [];
      for (let row of this.$refs.table.data.filter((el) => {
        return el?.checked;
      })) {
        rows.push(row.id);
      }
      if (!rows.length) {
        this.$root.$emit("show-info", {
          text: "Ничего не выбранно!",
          type: "error",
        });
        return false;
      }
      ok = await this.$refs.confirmDialog.show({
        title: "Отправка работ для выполнения",
        message: [
          `Вы действительно хотите отправить эти позиции графика?
Изменения в них будут невозможны.`,
        ],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      if (this.$root.profile.id !== 13) {
        //this.$root.$emit("show-info", { text: "Нет прав.", type: "error" });
        //return;
      }
      this.isMakeTasks = true;
      const d = await this.$axios.get("/mechti/construction-schedule", {
        params: { filters: { status: "-1", object_id: this.id } },
      });
      let response;
      try {
        let newR = d.data.data.filter((el) => {
          return rows.includes(el.id);
        });
        if (!newR.length) {
          this.$root.$emit("show-info", {
            text: "Не выбранно ни одной подходящей записи",
            type: "error",
          });
          return false;
        }
        for (let el of newR) {
          if (await this.validateData(el, this.modelForm)) {
          } else {
            this.$root.$emit("show-info", {
              text: `${el.id} Не верно заполнен график`,
              type: "error",
            });

            continue;
          }
          let data = { id: el.id, status: 100 };
          response = null;
          response = await this.save(this.url, data);
          if (response && response.data.data && response?.data?.status == "ok") {
          } else {
            throw myError("Ошибка утверждения график");
          }
        }
        this.$root.$emit("show-info", {
          text: "Записи утверждены.",
        });
      } catch (error) {
        this.$root.$emit("show-info", {
          text: +error.type ? error.message : "Возникла ошибка....",
          type: "error",
        });
        console.log("ERROR : ", error);
        this.isMakeTasks = !true;
      }
      this.$refs.table.updateData();
      this.isMakeTasks = false;
    },
  },
};
</script>
