<template>
  <div>
    <v-card-title class="py-0">
      {{ id ? "Редактирование" : "Создание" }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-actions class="pt-0">
      <a-btn-delete v-if="id" @click="removeDialogShow = true" />
      <v-spacer></v-spacer>

      <a-btn-save color="primary" @click="_submit()" :disabled="!(data.object_props_id || false)" />
      <v-btn @click="$emit('input')">Закрыть</v-btn>
    </v-card-actions>
    <v-card-text class="pt-8" :style="{ height: $root.windowSize.innerHeight - 250 + 'px' }" style="overflow-y: auto; overflow-x: hidden">
      <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true, hideDetails: false, readonly }" class="mb-3 ewfet" />
      <a-loader v-if="loading" />
      <v-row v-if="propSelected">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], modelProp)"
              :errors="errors"
              :config="{ dense: true, readonly }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>
      <a-form-model
        v-if="typeData"
        v-model="data"
        :model="modelData.filter(el => el.typeData == typeData)"
        :errors="errors"
        :config="{ dense: true, hideDetails: false, model: modelData.filter(el => el.typeData == typeData)[0] }"
        class="mb-3"
      />
    </v-card-text>

    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    object_id: Number,
    object_data: Object,
    defs: Object,
    id: Number,
    type: String,
  },
  data() {
    return {
      title: "",
      loading: false,
      loaded: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить этот свойство?",
      api: "mechti/object_props_value",
      model: [
        {
          name: "object_props_id",
          title: "Тип",
          type: "select",
          api: "mechti/object_props",
          dir: "objectProps",
          itemField: { text: "name", value: "id" },
          autocomplete: true,
        },
      ],
      editProps: { object_props_id: null, value: "", data: null },
      props_: [],
      typeData: null,

      modelProp: null,
      modelForm: null,
      mixName: "",
      propSelected: null,
      config: {
        1: {
          title: "Текст описания",
          typeData: 1,
          form: [
            { name: "object_id", validator: ["req", "min"], min: 0, default: this.object_id },
            { name: "object_type", validator: ["req"], hidden: true },
            { name: "value", title: "Вид описания", type: "select", validator: ["req"] },
            { name: "data", hidden: true },
          ],
          fields: "object_id,value,data,object_type",
          fieldsRO: "",
          fieldsForm: "value,data",
        },
        2: {
          title: "Картинки",
          typeData: 2,
          form: [
            { name: "object_id", validator: ["req", "min"], min: 0, default: this.object_id },
            { name: "object_type", validator: ["req"], hidden: true },
            { name: "value", title: "тип фотографий", type: "select", validator: ["req"] },
            { name: "data", hidden: true },
          ],
          fields: "object_id,value,data,object_type",
          fieldsRO: "",
          fieldsForm: "value,data",
        },
        3: {
          title: "Список",
          typeData: 2,
          form: [
            { name: "object_id", validator: ["req", "min"], min: 0, default: this.object_id },
            { name: "object_type", validator: ["req"], hidden: true },
            { name: "value", title: "Тип списка", type: "select", validator: ["req"] },
            { name: "data", hidden: true },
          ],
          fields: "object_id,value,data,object_type",
          fieldsRO: "",
          fieldsForm: "value,data",
        },
        4: {
          title: "Настройка блока",
          form: [
            { name: "object_id", validator: ["req", "min"], min: 0, default: this.object_id },
            { name: "object_type", validator: ["req"], hidden: true },
            { name: "value", title: "Свойство", type: "select", validator: ["req"] },
            { name: "data", hidden: true },
          ],
          fields: "object_id,value,data,object_type",
          fieldsRO: "",
          fieldsForm: "value,data",
        },
        5: {
          title: "Значение",
          typeData: 5,
          form: [
            { name: "object_id", validator: ["req", "min"], min: 0, default: this.object_id },
            { name: "object_type", validator: ["req"], hidden: true },
            { name: "value", title: "Вид описания", type: "select", validator: ["req"] },
            { name: "data", hidden: true },
          ],
          fields: "object_id,value,data,object_type",
          fieldsRO: "",
          fieldsForm: "value,data",
        },
        6: {
          title: "Значение",
          typeData: 6,
          form: [
            { name: "object_id", validator: ["req", "min"], min: 0, default: this.object_id },
            { name: "object_type", validator: ["req"], hidden: true },
            { name: "value", title: "Вид", type: "string", validator: ["req"], default: "adsInfo" },
            { name: "data", hidden: true, default: {} },
          ],
          fields: "object_id,value,data,object_type",
          fieldsRO: "",
          fieldsForm: "data",
        },
        7: {
          title: "Значение",
          typeData: 7,
          form: [
            { name: "object_id", validator: ["req", "min"], min: 0, default: this.object_id },
            { name: "object_type", validator: ["req"], hidden: true },
            { name: "value", title: "Вид", type: "string", validator: ["req"], default: "siteInfo" },
            { name: "data", hidden: true, default: {} },
          ],
          fields: "object_id,value,data,object_type",
          fieldsRO: "",
          fieldsForm: "data",
        },
      },
    };
  },
  computed: {
    modelData() {
      return [
        { name: "data", title: "Текст описания", type: "text", validator: ["req"], typeData: 1 },
        { name: "data", title: "Фотографии", type: "imagesdrag", validator: ["req"], typeData: 2 },
        { name: "data", title: "Элементы списка", type: "options", validator: ["req"], typeData: 3 },
        { name: "data", title: "Значение", type: "number", validator: ["req"], typeData: 5 },
        { name: "data", title: "Данные для рекламы", type: "adsInfo", validator: ["req"], typeData: 6, model: "adsInfo" },
        { name: "data", title: "Форма для заполнения", type: "adsInfo", validator: ["req"], typeData: 7, model: "siteInfo" },
        { name: "data", title: "Форма для заполнения", type: "base-object", validator: ["req"], typeData: -7, model: "siteInfo" },
      ];
    },
    readonly() {
      if (this.id) return true;
      if (this.defs) return true;
      return false;
    },

    props() {
      if (!this.props_) this.getProps();
      return this.props_;
    },
  },
  watch: {
    propSelected(v) {
      this.mixName = "";
      this.mixName = (this.propSelected?.data || {})?.value || "";
    },
    "data.object_props_id"(v, old) {
      if (this.data?.value && this.loaded && !v) this.data.value = null;
      this.calcModel(v);
    },
    "data.value"(v, old) {
      if (this.data?.data && this.loaded && old) {
        this.data.data = null;
      }

      if (!this.propSelected) {
        return;
      }
      if (this.propSelected.type !== 4) return; //если свойство не смешанное
      //если смешанное
      let type = null;
      let opts = this.modelProp.find(m => m.name == "value")?.options; //ищем список свойств
      if (opts) {
        type = opts.find(o => o.value == v)?.type || null; //определяем тип выбранного свойства
      }
      this.setTypeData(type);
    },
    value(v) {
      this.propSelected = null;
      if (v) {
        this._reset();
      }
    },
  },
  created() {
    this._reset();
  },
  methods: {
    async _submit() {
      if ([6, 7].includes(this.typeData)) {
        const mname = this.modelData.find(el => el.typeData == this.typeData).model;
        const model = this.$store.getters["config/get"].models?.[mname]?.form || [];
        if (!(await this.validateData(this.data, model, true))) {
          return;
        }
      }
      this.submit();
    },
    async _reset() {
      this.loaded = false;
      //  this.modelProp = null;
      await this.getProps();
      await this.updateData(this.id);
      this.loaded = true;
    },
    async getProps() {
      let api = "mechti/object_props";
      let params = { filters: { status: 1 } };
      this.loading = true;
      let response = await this.$axios.get(api, { params });
      this.loading = false;
      this.props_ = response.data.data;
      this.data["object_data"] = this.object_data;
    },
    setTypeData(type) {
      if (type !== 4) {
        this.typeData = type;
        return;
      }

      this.typeData = null;
    },

    save() {},
    async upload() {
      this.fileLoading = true;
      this.dialogProps = false;
    },
    getValuesList(id) {
      let res =
        this.props.find(p => {
          return p.id == id;
        })?.options || [];

      return res;
    },
    calcModel(t) {
      //переназначить функцию, потому что немного отличается от того что в библиотеке genModel
      this.propSelected = null;
      if (!t) {
        this.setTypeData();

        return;
      }
      let prop = this.props.find(el => {
        return el.id == t;
      });

      if (!prop?.type) return;

      const config = this.config[prop.type];
      let model = JSON.parse(JSON.stringify(config.form));
      model.forEach(el => {
        if (el.name == "value") el.options = this.getValuesList(t);
      });
      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      if (this.data.status == 2) fieldsRO = fields;
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter(el => {
        return fields.includes(el.name);
      });
      model.forEach(el => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach(field => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });

      this.modelForm = mForm;
      this.propSelected = prop;
      this.setTypeData(prop.type);
      this.fillFormFromModel(model);
      if (t == this.config?.type) {
        for (const name in this.config) {
          this.$set(this.data, name, this.config[name]);
        }
      }
      this.modelProp = model;
      return model;
    },
    afterSave() {
      this.$emit("input");
    },
    afterFetchData() {
      if (!this.id) {
        this.data.object_id = this.object_id;
        this.data.object_type = this.type;
        this.data["object_data"] = this.object_data;
        if (this.defs) {
          this.$nextTick(() => {
            this.data.object_props_id = this.defs.object_props_id;
            this.data.value = this.defs.value;
          });
          this.$nextTick(() => {});
        }
      } else {
        this.data["object_data"] = this.object_data;
      }
      this.calcModel(this.data.object_props_id);
    },
    async beforeSave() {
      this.data.object_type = this.type;
      return true;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
  },
};
</script>
