<template>
  <div>
    <Widget
      ref="widget"
      :height="height"
      :model="model"
      :defaults="{ paramName: 'dashBoard.actWork', sort: { key: 'id', order: 'DESC' } }"
      :api="{ name: 'widget_getActWork', params: { role: $root.profile.role, user_id: $root.profile.id } }"
      @click="onClick($event)"
      :isLoading="loading"
      @loading="loading = $event"
      @rowContextMenu="onRowContextMenu($event)"
    >
      <template v-slot:title>
        Акты выполненных работ
        <v-icon small @click="hideViewed = !hideViewed">{{ hideViewed ? "fas fa-eye-slash" : "fas fa-eye" }}</v-icon>
      </template>
    </Widget>

    <s-quickOpenDoc ref="quickOpenDoc" @onClose="itemShowClose" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </div>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {
    Widget: () => import("./widgets/apiTable"),
  },
  props: {
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      idShow: 0,
      contentHeight: 10,
      hideViewed: true,
      loading: false,
      data: [],
      showConfig: false,
      timeID: null,
      array_object: [],
      model: this.$store.getters["config/get"].models.dashBoard.list_actWork,
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
  },
  watch: {},
  methods: {
    onClick(e) {
      if (e.field?.isMenu) {
        if (["document"].includes(e.field.name)) {
          let menuItems = [
            {
              name: e.field.name,
              title: "Открыть документ",
              action: async () => {
                this.$refs.quickOpenDoc.openDocument(e.row.base_name, parseInt(e.row.doc_id), e.row);
              },
            },
          ];
          this.showPopupMenu(e.field.name, e.row, e.event, { menuItems });
        } else this.showPopupMenu(e.field.name, e.row, e.event, e);
        return;
      }
      this.idShow = e.row.id;
      this.$refs.quickOpenDoc.openDocument("AccountingDocActWork", parseInt(e.row.id), e.row);
    },
    itemShowClose() {
      this.loading = true;
      let id = this.idShow;
      this.idShow = 0;
      if (id) this.$refs.widget.updateRowData(id);
      this.loading = !true;
    },

    async fitchData() {
      this.loading = true;
      this.afterFitch();
      return;
    },
    afterFitch() {
      this.loading = false;
    },
  },
};
</script>
