<template>
  <div>
    <ckeditor :editor="editor" v-model="data" :config="eo"></ckeditor>
    <div v-if="$root.profile.role === 10001">
      {{ data }}
    </div>
  </div>
</template>

<script>
//import { ImageToolbar } from "@ckeditor/ckeditor5-image";
import ClassicEditor from "@/assets/ckeditor"; // Путь к вашему собранному редактору
export default {
  props: {
    value: [String, Number],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      eo: {
        toolbar: {
          //plugins: [(editor) => SimpleUploadAdapter(editor, this.$axios)],
          shouldNotGroupWhenFull: true,
          items: [
            "undo",
            "redo",
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "imageUpload",
            "imageStyle:wrapText",
            "imageStyle:breakText",
            "resizeImage",
            "blockQuote",
            "insertTable",
            "sourceEditing",
            "alignment",
            "fontColor",
            "fontSize",
            "highlight",
            "showBlocks",
          ],
        },
        language: "ru",
        image: {
          toolbar: [],
        },
        htmlSupport: {
          allow: [
            {
              name: "div",
              attributes: true,
              classes: true,
              styles: true,
            },
            {
              name: "video",
              attributes: true,
              classes: true,
              styles: true,
            },
          ],
        },
        // Отключаем авто-добавление пробелов в пустых div'ах
        extraAllowedContent: "div(*);",
        uploadAdapter: {
          url: this.$root.config.baseUrl + "image",
          axiosInstance: this.$axios,
        },
      },
    };
    //"ts-loader": "^9.5.1",
  },
  computed: {
    data: {
      get() {
        return this.value || "";
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {},
};
</script>

<style>
.ck-editor__editable {
  min-height: 300px;
}

.ck-source-editing-area {
  display: flex;
}
</style>
